import Add from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Button, UseReferenceManyFieldControllerParams, useRecordContext, useRedirect } from 'react-admin';

const useStyles = makeStyles(() => ({
  button: {},
}));

const AddRelatedEntityRelationButton: React.FC<
  UseReferenceManyFieldControllerParams & { current: string; referenced: string }
> = ({ current, reference, referenced, target, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const record = useRecordContext();
  return (
    <Button
      label="ra.action.create"
      className={classes.button}
      onClick={() => {
        redirect(
          `/${referenced}/create?reference=${reference}&referenceInitialField=${target}&referenceInitialValue=${record.id}&redirectTo=/${current}/${record.id}/relations`
        );
      }}
    >
      <Add />
    </Button>
  );
};

export default AddRelatedEntityRelationButton;
