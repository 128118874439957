import { useContext } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  required,
  useEditController,
  useRedirect,
  useTranslate,
} from 'react-admin';

import FeelInput from '../../../Components/FeelInput';
import HasuraContext from '../../../DataProviders/HasuraContext';
import { Locale, locales } from '../../../messages';
import { IMPLICIT_COLUMNS } from '../../../util/constants';

const EntitySettingsEditToolbar = () => {
  const redirect = useRedirect();
  const { record } = useEditController();
  return (
    <Toolbar>
      <SaveButton type="button" />
      <Button
        sx={{
          marginLeft: 1,
        }}
        type="button"
        label="vasara.action.exitForm"
        onClick={() => {
          redirect('list', record.id);
        }}
      />
    </Toolbar>
  );
};

const EntitySettingsEdit = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { record } = useEditController();
  const { fields } = useContext(HasuraContext);
  const validateRequired = [required()];
  const feelVariables = (!!record?.id ? Array.from((fields.get(record.id) || new Map()).keys()) : [])
    .filter((key: string) => !IMPLICIT_COLUMNS.includes(key))
    .map((key: string) => {
      return { name: key };
    });
  return (
    <Edit
      title={translate('vasara.page.entitySettingsEdit', { resource: record?.id })}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          redirect('list', record.id);
        },
      }}
      transform={(data: any) => {
        // Cleanup implicitly added keys
        for (const column of data?.columns || []) {
          for (const key of Object.keys(column)) {
            if (key.startsWith('label.')) {
              delete column[key];
            }
            if (key.startsWith('conditional.')) {
              delete column[key];
            }
          }
        }
        return data;
      }}
    >
      <SimpleForm toolbar={<EntitySettingsEditToolbar />}>
        <ArrayInput source="columns" label="vasara.form.columns">
          <SimpleFormIterator
            sx={{
              /* eslint-disable-next-line no-useless-computed-key */
              ['& > ul > li > section.RaSimpleFormIterator-form']: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                [`& > .MuiFormControl-root`]: {
                  marginRight: 2,
                },
              },
              /* eslint-disable-next-line no-useless-computed-key */
              ['& > ul > .RaSimpleFormIterator-line']: {
                paddingLeft: 2,
              },
              /* eslint-disable-next-line no-useless-computed-key */
              ['& > ul > .RaSimpleFormIterator-line:nth-of-type(even)']: {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <TextInput source="path" label="vasara.column.JSONPath" helperText={false} validate={validateRequired} />
            {locales.map((locale: Locale) => (
              <TextInput
                key={locale}
                source={`label.${locale}`}
                label={translate('vasara.column.localizedLabel', { locale })}
                helperText={false}
                validate={validateRequired}
              />
            ))}
            <BooleanInput source="filter" label="vasara.column.filterable" defaultValue={false} helperText={false} />
            <BooleanInput source="sortable" label="vasara.column.sortable" defaultValue={false} helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="buttons" label="vasara.form.buttons">
          <SimpleFormIterator
            sx={{
              /* eslint-disable-next-line no-useless-computed-key*/
              ['& > ul > li > section.RaSimpleFormIterator-form']: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                [`& > .MuiFormControl-root`]: {
                  marginRight: 2,
                },
              },
              /* eslint-disable-next-line no-useless-computed-key*/
              ['& > ul > .RaSimpleFormIterator-line']: {
                paddingLeft: 2,
              },
              /* eslint-disable-next-line no-useless-computed-key*/
              ['& > ul > .RaSimpleFormIterator-line:nth-of-type(even)']: {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            {locales.map((locale: Locale) => (
              <TextInput
                key={locale}
                source={`label.${locale}`}
                label={translate('vasara.column.localizedName', { locale })}
                helperText={false}
                validate={validateRequired}
              />
            ))}

            <TextInput
              id="message"
              label="vasara.form.messageName"
              source="message"
              helperText={false}
              fullWidth={true}
              validate={validateRequired}
            />

            <ArrayInput source={'variables'} label="vasara.form.messageVariables">
              <SimpleFormIterator
                sx={{
                  /* eslint-disable-next-line no-useless-computed-key*/
                  ['& > ul > li > section.RaSimpleFormIterator-form']: {
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    [`& > .MuiFormControl-root`]: {
                      marginRight: 2,
                    },
                  },
                  /* eslint-disable-next-line no-useless-computed-key*/
                  ['& > ul > .RaSimpleFormIterator-line:nth-of-type(even)']: {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                <TextInput source="id" label="vasara.form.variable" helperText={false} validate={validateRequired} />
                <FeelInput
                  label="vasara.form.expression"
                  source="expression"
                  validate={validateRequired}
                  helperText={false}
                  variables={feelVariables}
                />
              </SimpleFormIterator>
            </ArrayInput>

            <FeelInput
              id="conditional.hide"
              label={translate('vasara.column.hideExpression')}
              source="conditional.hide"
              defaultValue=""
              fullWidth={true}
              helperText={translate('vasara.form.helperText.hideExpression')}
              variables={feelVariables}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="editable" label="vasara.column.editable" defaultValue={false} helperText={false} />
      </SimpleForm>
    </Edit>
  );
};

export default EntitySettingsEdit;
