import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { ResourceProps } from 'react-admin';

import EntityCommentCreate from './EntityCommentCreate';
import EntityCommentEdit from './EntityCommentEdit';
import EntityCommentShow from './EntityCommentShow';

const resource: Omit<ResourceProps, 'name'> = {
  create: EntityCommentCreate,
  edit: EntityCommentEdit,
  show: EntityCommentShow,
  icon: ChatBubbleIcon,
  options: { hideFromMenu: true },
};

export default resource;
