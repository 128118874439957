import './App.css';

import { IntrospectionEnumType, IntrospectionField, IntrospectionObjectType } from 'graphql';
import React, { Component, useEffect, useState } from 'react';
import { Admin, CustomRoutes, Loading, Resource } from 'react-admin';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';

import authProvider from './Auth/authProvider';
import { buildMainDataProvider } from './DataProviders';
import buildActionsProvider from './DataProviders/Actions';
import buildCamundaProvider from './DataProviders/Camunda';
import buildCustomProvider from './DataProviders/Custom';
import HasuraContext from './DataProviders/HasuraContext';
import buildHasuraProviderWithIntrospection from './DataProviders/HasuraProvider';
import { GuestApp } from './GuestApp';
import { GuestForm } from './GuestForm';
import Dashboard from './Layout/Dashboard';
import CustomLayout from './Layout/Layout';
import LoginPage from './Layout/LoginPage';
import CustomNotification from './Layout/Notification';
import messages, { DEFAULT_LOCALE, resolveLocale } from './messages';
import i18nProvider from './messages';
import DecisionDefinition from './Resources/Camunda/DecisionDefinition';
import ProcessDefinition from './Resources/Camunda/ProcessDefinition';
import ProcessInstance from './Resources/Camunda/ProcessInstance';
import UserTask from './Resources/Camunda/UserTask';
import Entity from './Resources/Entity';
import EntityEdit from './Resources/Entity/EntityEdit';
import EntityComment from './Resources/EntityComment';
import EntitySettings from './Resources/Vasara/EntitySettings';
import UserTaskForm from './Resources/Vasara/UserTaskForm';
import { IAppProps, IAppState } from './types';
import { isAggregateResourceName, isManuallyAdded, labelFromSchema, toLabel } from './util/helpers';
import { theme } from './util/theme';

const AppImpl: React.FC<IAppState> = props => {
  const [schemata, setSchemata] = useState<Map<string, IntrospectionObjectType>>(new Map());
  const [enums, setEnums] = useState<Map<string, IntrospectionEnumType>>(new Map());
  const [vocabularies, setVocabularies] = useState<Map<string, IntrospectionObjectType>>(new Map());
  const [resources, setResources] = useState<string[]>([]);
  const [fields, setFields] = useState<Map<string, Map<string, IntrospectionField>>>(new Map());

  const { mainDataProvider, hasuraIntrospectionResults, introspectionQueryResponse } = props;

  useEffect(() => {
    if (hasuraIntrospectionResults) {
      const schemata = new Map();
      const enums = new Map();
      const vocabularies = new Map();
      const resources = [];
      const fields = new Map();
      for (const type of hasuraIntrospectionResults.types) {
        if (type.kind === 'OBJECT') {
          schemata.set(type.name, type as IntrospectionObjectType);
        } else if (type.kind === 'ENUM') {
          enums.set(type.name, type as IntrospectionEnumType);
        }
      }
      for (const resource of hasuraIntrospectionResults.resources) {
        const name = (resource as any).type.name;
        if (!isAggregateResourceName(name) && !isManuallyAdded(name)) {
          resources.push(name);
          fields.set(
            name,
            new Map((schemata.get(name) as IntrospectionObjectType).fields.map(field => [field.name, field]))
          );
        }
        if (!isAggregateResourceName(name) && name.startsWith('vocabulary_')) {
          vocabularies.set(name, schemata.get(name) as IntrospectionObjectType);
        }
      }
      setSchemata(schemata);
      setEnums(enums);
      setResources(resources);
      setFields(fields);
      setVocabularies(vocabularies);
    }
  }, [hasuraIntrospectionResults]);

  if (!(mainDataProvider && hasuraIntrospectionResults && introspectionQueryResponse)) {
    return <Loading loadingPrimary={''} loadingSecondary={'Loading...'} />;
  }

  return (
    <HasuraContext.Provider
      value={{
        introspectionQueryResponse: introspectionQueryResponse,
        introspection: hasuraIntrospectionResults,
        schemata: schemata,
        fields: fields,
        vocabularies: vocabularies,
        enums: enums,
      }}
    >
      <Helmet
        htmlAttributes={{ lang: resolveLocale(DEFAULT_LOCALE) }}
        title={`Vasara — ${i18nProvider.translate('vasara.ui.subtitle')}`}
      />
      <Admin
        theme={theme}
        authProvider={authProvider}
        loginPage={LoginPage}
        i18nProvider={messages}
        layout={CustomLayout}
        notification={CustomNotification}
        dashboard={Dashboard}
        disableTelemetry
        dataProvider={mainDataProvider}
      >
        {/* fixed resources; the first resource is also the default page */}
        <Resource {...UserTask} />
        <Resource {...ProcessDefinition} />
        <Resource {...DecisionDefinition} />
        <Resource {...ProcessInstance} />
        <Resource {...UserTaskForm} />
        <Resource {...EntitySettings} />
        {/* dynamic resources */}
        {resources
          .filter((name: string) => !name.endsWith('_comment'))
          .map((name: string) => {
            const schema = schemata.get(name);
            return (
              <Resource
                key={name}
                name={name}
                {...Entity}
                options={{
                  label: schema ? labelFromSchema(schema) : toLabel(name),
                  hideFromMenu: !!name.match(/^_/),
                }}
              >
                <Route path=":id/relations" element={<EntityEdit editRelations={true} />} />
              </Resource>
            );
          })}
        {resources
          .filter((name: string) => name.endsWith('_comment'))
          .map((name: string) => {
            return <Resource key={name} name={name} {...EntityComment} />;
          })}
        {/* meta resources */}
        <Resource name="camunda_HistoricActivityInstance" options={{ hideFromMenu: true }} />
        <Resource name="camunda_HistoricProcessInstance" options={{ hideFromMenu: true }} />
        <Resource
          {...ProcessDefinition}
          name="camunda_ProcessDefinition_ListItem"
          options={{ ...ProcessDefinition.options, hideFromMenu: true }}
        />
        <Resource name="camunda_ProcessDefinition_UserTask" options={{ hideFromMenu: true }} />
        <Resource name="camunda_ProcessInstance_UserTask" options={{ hideFromMenu: true }} />
        <Resource
          {...UserTask}
          name="camunda_UserTask_ListItem"
          options={{ ...UserTask.options, hideFromMenu: true }}
        />
        <Resource name="camunda_UserTask_Comment" options={{ hideFromMenu: true }} />
        <Resource name="camunda_User" options={{ hideFromMenu: true }} />
        <Resource name="camunda_Group" options={{ hideFromMenu: true }} />
        <Resource name="xlsx_import" options={{ hideFromMenu: true }} />
        <Resource name="xlsx_export" options={{ hideFromMenu: true }} />
        <Resource name="vault_transit_encrypt" options={{ hideFromMenu: true }} />
        <Resource name="vault_transit_decrypt" options={{ hideFromMenu: true }} />
        <Resource name="camunda_guest_ProcessDefinition" options={{ hideFromMenu: true }} />
        <Resource name="camunda_insert_guest_ProcessInstance" options={{ hideFromMenu: true }} />
        <Resource name="AvailableResources" options={{ hideFromMenu: true }} />
        <CustomRoutes>
          <Route path="/guest" element={<GuestForm />} />,
          <Route path="/guest/" element={<GuestForm />} />,
          <Route path="/guest/:needle" element={<GuestForm />} />,
        </CustomRoutes>
      </Admin>
    </HasuraContext.Provider>
  );
};

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isGuest: false,
      introspectionQueryResponse: undefined,
      camundaDataProvider: undefined,
      hasuraDataProvider: undefined,
      actionsDataProvider: undefined,
      customDataProvider: undefined,
      hasuraIntrospectionResults: undefined,
    };
  }

  async componentDidMount() {
    let isGuest = false;
    try {
      await authProvider.checkAuth({});
    } catch {
      isGuest = window.location.hash.startsWith('#/guest');
      if (!isGuest) {
        return await authProvider.login({});
      }
    }
    const camundaDataProvider = await buildCamundaProvider();
    const actionsDataProvider = await buildActionsProvider();
    const customDataProvider = await buildCustomProvider();
    const {
      introspectionQueryResponse,
      hasuraDataProvider,
      hasuraIntrospectionResults,
    } = await buildHasuraProviderWithIntrospection();
    const mainDataProvider = buildMainDataProvider({
      camundaDataProvider,
      hasuraDataProvider,
      actionsDataProvider,
      customDataProvider,
    });
    return this.setState({
      ...this.state,
      isGuest,
      introspectionQueryResponse,
      mainDataProvider,
      camundaDataProvider,
      hasuraDataProvider,
      actionsDataProvider,
      customDataProvider,
      hasuraIntrospectionResults,
    });
  }

  render() {
    return this.state.isGuest ? <GuestApp {...this.state} /> : <AppImpl {...this.state} />;
  }
}

export default App;
