import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopy from '@mui/icons-material/FileCopy';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useFormContext } from 'react-hook-form';

import { Choice } from '../types';

interface FieldsetFieldProps {
  expanded: string;
  setExpanded: (fieldName: string) => void;
  inputName: string;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  sourceChoices?: Choice[];
}

const useAccordionStyles = makeStyles({
  root: {
    borderRadius: '4px !important',
    marginBottom: '4px',
  },
});

const useSummaryStyles = makeStyles({
  root: {
    '&.MuiButtonBase-root': {
      backgroundColor: 'inherit',
      borderRadius: '4px !important',
      filter: 'drop-shadow(1px 1px 1px grey)',
    },
    borderRadius: '4px !important',
  },
  focused: {
    '&.MuiButtonBase-root': {
      filter: 'none',
    },
  },
  content: {
    margin: '4px 0 !important',
  },
});

const getFieldStyle = (snapshot: DraggableStateSnapshot, draggableStyles: any) => ({
  userSelect: 'none',
  background:
    !snapshot.isDragging && !snapshot.draggingOver
      ? '#fdfcfc'
      : snapshot.isDragging && snapshot.draggingOver
      ? 'lightgreen'
      : 'red',
  ...draggableStyles,
});

const FieldsetField: React.FC<FieldsetFieldProps> = props => {
  const [locale] = useLocaleState();
  const form = useFormContext();
  const fieldValue = form.watch(props.inputName);
  const accordionStyles = useAccordionStyles();
  const summaryStyles = useSummaryStyles();
  const translate = useTranslate();
  const { formState } = useFormContext();

  return (
    <>
      <Accordion
        expanded={props.expanded === props.inputName}
        ref={props.provided.innerRef}
        {...props.provided.draggableProps}
        onChange={() => {
          if (!props.expanded || Object.keys(formState.errors).length === 0) {
            props.setExpanded(props.expanded !== props.inputName ? props.inputName : '');
          }
        }}
        style={getFieldStyle(props.snapshot, props.provided.draggableProps.style)}
        classes={{ ...accordionStyles }}
      >
        <AccordionSummary
          classes={{ ...summaryStyles }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${props.inputName}-content`}
        >
          <Typography>
            <IconButton
              {...props.provided.dragHandleProps}
              disableRipple={false}
              aria-label={translate('vasara.action.move')}
              title={translate('vasara.action.move')}
              onFocus={event => event.stopPropagation()}
              onMouseDown={event => event.stopPropagation()}
              onClick={event => event.stopPropagation()}
            >
              <DragIndicatorIcon />
            </IconButton>
            <strong>
              {props.snapshot.isDragging && !props.snapshot.draggingOver ? (
                <span style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                  <DeleteIcon />
                  &nbsp;
                  {translate(`vasara.form.${fieldValue.type}`)}
                </span>
              ) : (
                translate(`vasara.form.${fieldValue.type}`)
              )}
            </strong>
            : {fieldValue.label?.[locale] ?? ''} {fieldValue.required && '*'}
          </Typography>
          <Box flexGrow="1" textAlign="end" style={{ zIndex: 1 }}>
            <CopyToClipboard text={JSON.stringify(fieldValue)}>
              <IconButton
                title={translate('vasara.action.copy')}
                aria-label={translate('vasara.action.copy')}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <FileCopy />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </AccordionSummary>
        <AccordionDetails id={`${props.inputName}-content`} style={{ padding: '1rem', display: 'block' }}>
          {props.expanded === props.inputName ? props.children : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FieldsetField;
