import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useRecordContext,
  useReferenceManyFieldController,
} from 'react-admin';

// Activities tab of EntityShow in its own component
// because it needs a hook that depends on the existence of the record,
// which is not available when EntityShow is first created
const EntityActivityList: React.FC<{}> = () => {
  // here record is known to be defined
  // because we return early from EntityShow if it isn't
  const record = useRecordContext();
  const activitiesController = useReferenceManyFieldController({
    record,
    resource: 'camunda_HistoricProcessInstance',
    reference: 'camunda_HistoricActivityInstance',
    source: 'business_key',
    target: 'businessKey',
  });
  return (
    <>
      <ListContextProvider
        value={{
          ...activitiesController,
          data: (activitiesController.data ?? []).filter(record => record.activityName),
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="activityName" label="vasara.column.userTask" />
          <TextField source="assignee.name" label="vasara.column.author" />
          <TextField source="endTime" label="vasara.column.endTime" />
          <TextField source="processDefinition.name" label="vasara.column.processDefinitionName" />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

export default EntityActivityList;
