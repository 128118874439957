import { makeStyles } from '@mui/styles';
import { IntrospectionField, IntrospectionObjectType, IntrospectionOutputTypeRef } from 'graphql';
import get from 'lodash/get';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DataProvider,
  FormDataConsumer,
  Labeled,
  SelectField as RASelectField,
  SelectInput as RASelectInput,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useDataProvider,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { withSecret } from '../../Components/VaultTextInput';
import VaultTextReadOnlyInputPlain, { withReadOnlySecret } from '../../Components/VaultTextReadOnlyInputPlain';
import { getUserTaskForm } from '../../DataProviders/Camunda/helpers';
import UserTaskEditContext from '../../DataProviders/Camunda/UserTaskEditContext';
import HasuraContext from '../../DataProviders/HasuraContext';
import { unary } from '../../util/feel';
import {
  getFieldTypeName,
  getReferenceResourceName,
  getRelationFromFkField,
  isEnumField,
  isFkField,
  labelFromField,
  labelFromSchema,
} from '../../util/helpers';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { Choice } from '../types';

// very large number for autocomplete-type selects so the list shows everything
const UNLIMITED_PER_PAGE = 10000;

const SelectField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();
  const form = useFormContext();
  const inputValue = form.watch(props.inputName);
  const fullWidth = inputValue?.fullWidth ?? true;

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={fullWidth}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={fullWidth}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          // source options from given list (mutually exclusive with other source options)
          const vocabulary = get(formData, `${props.inputName}.vocabulary`);
          const varSource = get(formData, `${props.inputName}.variableSource`);
          return !vocabulary && !varSource ? (
            <ArrayInput source={`${props.inputName}.options`} label="vasara.form.options">
              <SimpleFormIterator className="sparse">
                <TextInput source={`id`} label="vasara.form.value" helperText={false} />
                <TextInput source={`name.${locale}`} label="vasara.form.label" helperText={false} />
              </SimpleFormIterator>
            </ArrayInput>
          ) : null;
        }}
      </FormDataConsumer>

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          // source options from vocabulary (mutually exclusive with other source options)
          const options = get(formData, `${props.inputName}.options`) || [];
          const varSource = get(formData, `${props.inputName}.variableSource`);
          return options.length === 0 && !varSource ? (
            <AutocompleteInput
              id={`${props.inputName}-vocabulary`}
              label="vasara.form.vocabulary"
              source={`${props.inputName}.vocabulary`}
              choices={props.vocabularyChoices}
              fullWidth={true}
              helperText={false}
            />
          ) : null;
        }}
      </FormDataConsumer>

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          // source options from process variable
          const options = get(formData, `${props.inputName}.options`) || [];
          const vocabulary = get(formData, `${props.inputName}.vocabulary`);
          const choices = props.readonlySourceChoices
            // only list process variables
            .filter((src: Choice) => src.id.startsWith('context'))
            // remove the "context." prefix
            .map(
              (src: Choice): Choice => {
                return { id: src.id.substring(8), name: src.name };
              }
            );
          return options.length === 0 && !vocabulary ? (
            <AutocompleteInput
              id={`${props.inputName}-variableSource`}
              label="vasara.form.variableSource"
              source={`${props.inputName}.variableSource`}
              choices={choices}
              fullWidth={true}
              helperText="vasara.form.helperText.variableSource"
            />
          ) : null;
        }}
      </FormDataConsumer>

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    helperText="vasara.form.helperText.options"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <AutocompleteArrayInput
              id={`${props.inputName}-sources`}
              label="vasara.form.sources"
              helperText="vasara.form.helperText.options"
              source={`${props.inputName}.sources`}
              choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
              validate={validateRequired}
              fullWidth={true}
            />
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          const vocabulary = get(formData, `${props.inputName}.vocabulary`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
              />

              {!vocabulary ? (
                <BooleanInput
                  id={`${props.inputName}-confidential`}
                  label="vasara.form.confidential"
                  source={`${props.inputName}.confidential`}
                  defaultValue={false}
                  className={classes.floatLeft}
                />
              ) : null}

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <RASelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

const SecretSelectField = withReadOnlySecret(RASelectField);
const SecretRadioButtonGroupInput = withSecret(RadioButtonGroupInput);
const SecretAutocompleteInput = withSecret(AutocompleteInput);

const SimpleSelectInput: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const context = useContext(UserTaskEditContext);
  const taskForm = getUserTaskForm(context.userTask);
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const [fixedOptionsIds, setFixedOptionsIds] = useState<any[]>([]);
  const [fixedOptions, setFixedOptions] = useState<any[]>([]);

  let options = fixedOptions;

  useEffect(() => {
    if (fixedOptions.length === 0) {
      // Resolve options from field configuratiojn
      let candidates = (schema.options || []).map((option: any) => {
        return {
          id: option.id,
          name: option.name[locale],
        };
      });

      // Resolve options from user task form
      if (!candidates || candidates.length === 0) {
        for (let field of taskForm) {
          const id = `context.${field.id}`;
          if (field.values && schema.sources.indexOf(id) > -1) {
            candidates = field.values;
            break;
          }
        }
      }

      setFixedOptions(candidates);
      setFixedOptionsIds(candidates.map((option: any) => option.id));
    }
  }, [fixedOptions.length, locale, schema.options, schema.sources, taskForm]);

  const validate =
    schema.required && fixedOptionsIds.length > 0
      ? [required(), oneOfRequired(fixedOptionsIds)]
      : schema.required
      ? [required()]
      : [];

  // Resolve options from schema
  const { schemata, fields: fieldsByName, enums } = useContext(HasuraContext);

  if (!options || options.length === 0) {
    for (const source of schema?.sources || []) {
      const parts = source.split('.');
      if (fieldsByName.has(parts[0])) {
        const fields = fieldsByName.get(parts[0]) as Map<string, IntrospectionField>;
        const field = fields.get(parts[1]);
        if (field && isEnumField(field)) {
          const type_ = enums.get(getFieldTypeName((field.type as unknown) as IntrospectionOutputTypeRef));
          if (type_) {
            options = type_.enumValues.map(value => {
              return {
                id: value.name,
                name: value.description,
              };
            });
          }
        } else if (field && isFkField(field)) {
          const relation = getRelationFromFkField(field);

          if (fields.has(relation)) {
            const relationField = fields.get(relation) as IntrospectionField;
            const referencedResourceName = getReferenceResourceName(relationField);

            if (referencedResourceName === 'camunda_User') {
              return schema.readonly ? (
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData }: any) => (
                    <Labeled label={label} className={classes.fullWidth} style={style}>
                      <ReferenceField
                        record={formData}
                        label={labelFromField(field)}
                        source={schema.id}
                        reference={referencedResourceName}
                        link={false}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    </Labeled>
                  )}
                </FormDataConsumer>
              ) : (
                <ReferenceInput
                  label={labelFromField(field)}
                  source={schema.id}
                  reference={referencedResourceName}
                  fullWidth={true}
                  clearAlwaysVisible={true}
                  resettable={true}
                  allowEmpty={true}
                  perPage={UNLIMITED_PER_PAGE}
                >
                  <AutocompleteInput
                    filterToQuery={(q: string) => {
                      if (!q) {
                        return {};
                      }
                      return { q };
                    }}
                    optionText={'name'}
                    style={style}
                    disabled={!!schema.disabled}
                  />
                </ReferenceInput>
              );
            }

            const referencedResourceSchema = schemata.get(referencedResourceName) as IntrospectionObjectType;

            if (referencedResourceSchema) {
              return schema.readonly ? (
                <FormDataConsumer subscription={{ values: true }}>
                  {({ formData }: any) => {
                    return (
                      <Labeled label={label} className={classes.fullWidth} style={style}>
                        <ReferenceField
                          record={formData}
                          label={labelFromField(field, labelFromSchema(referencedResourceSchema))}
                          source={schema.id}
                          reference={referencedResourceName}
                          link="show"
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </Labeled>
                    );
                  }}
                </FormDataConsumer>
              ) : (
                <ReferenceInput
                  label={labelFromField(field, labelFromSchema(referencedResourceSchema))}
                  source={schema.id}
                  reference={referencedResourceName}
                  allowEmpty={true}
                  fullWidth={true}
                  perPage={UNLIMITED_PER_PAGE}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteInput
                    filterToQuery={(q: string) => {
                      if (!q) {
                        return {};
                      }
                      return {
                        name: {
                          format: 'hasura-raw-query',
                          value: {
                            _ilike: `%${q}%`,
                          },
                        },
                      };
                    }}
                    optionText={'name'}
                    style={style}
                    disabled={!!schema.disabled}
                  />
                </ReferenceInput>
              );
            }
          }
        }
        if (options.length > 0) {
          break;
        }
      }
      if (options.length > 0) {
        break;
      }
    }
  }

  if (schema.readonly) {
    if (options.length > 0) {
      return (
        <Labeled label={label} className={classes.fullWidth} style={style}>
          {schema.confidential ? (
            <SecretSelectField label={label} source={schema.id} choices={options} fullWidth={true} />
          ) : (
            <RASelectField label={label} source={schema.id} choices={options} fullWidth={true} />
          )}
        </Labeled>
      );
    }

    return (
      <Labeled label={label} className={classes.fullWidth} style={style}>
        {schema.confidential ? (
          <VaultTextReadOnlyInputPlain label={label} source={schema.id} fullWidth={true} />
        ) : (
          <TextField label={label} source={schema.id} fullWidth={true} />
        )}
      </Labeled>
    );
  }

  if (options.length < 6) {
    if (schema.confidential) {
      return (
        <SecretRadioButtonGroupInput
          label={label}
          helperText={(schema.helperText?.[locale] ?? '') || ''}
          source={schema.id}
          choices={options}
          row={false}
          validate={validate}
          style={style}
          disabled={!!schema.disabled}
        />
      );
    }
    return (
      <RadioButtonGroupInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        source={schema.id}
        choices={options}
        row={false}
        validate={validate}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }

  if (schema.confidential) {
    return (
      <SecretAutocompleteInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        source={schema.id}
        choices={options}
        validate={validate}
        fullWidth={true}
        clearAlwaysVisible={true}
        resettable={true}
        allowEmpty={true}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }

  return (
    <AutocompleteInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      source={schema.id}
      choices={options}
      validate={validate}
      fullWidth={true}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

const vocabularyOneOfRequired = (dataProvider: DataProvider, vocabulary: string) => {
  let isFetching = false; // Flag to track if a fetch is already in progress

  return async (value: any) => {
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      // Check if a fetch is already in progress
      if (isFetching) {
        return;
      }

      isFetching = true; // Set the flag to indicate that a fetch is in progress

      try {
        const data = await dataProvider.getOne(vocabulary, {
          id: value,
        });
        if (data === null) {
          return 'ra.validation.required';
        }
      } catch (e) {
        return 'ra.validation.required';
      } finally {
        isFetching = false; // Reset the flag after the fetch is completed
      }
    }
    return undefined;
  };
};

const VocabularySelectInput: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const options: any[] = [];
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';
  const dataProvider = useDataProvider();
  const validate = schema.required ? [required(), vocabularyOneOfRequired(dataProvider, schema.vocabulary)] : [];

  if (schema.readonly) {
    const fieldValue = form.getValues(schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }
    if (options.length > 0) {
      return (
        <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
          <RASelectField label={label} source={schema.id} choices={options} fullWidth={fullWidth} />
        </Labeled>
      );
    }
    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <ReferenceField label={label} source={schema.id} reference={schema.vocabulary} link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
    );
  }

  return (
    <ReferenceInput
      label={label}
      source={`${schema.id}`}
      perPage={UNLIMITED_PER_PAGE}
      sort={{ field: 'name', order: 'ASC' }}
      reference={schema.vocabulary}
      fullWidth={fullWidth}
      clearAlwaysVisible={true}
      resettable={true}
      allowEmpty={true}
    >
      <AutocompleteInput
        validate={validate}
        filterToQuery={(q: string) => {
          if (!q) {
            return {};
          }
          if (schema.vocabulary.startsWith('camunda_')) {
            return { q };
          }
          return {
            name: {
              format: 'hasura-raw-query',
              value: {
                _ilike: `%${q}%`,
              },
            },
          };
        }}
        label={label}
        optionText={'name'}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        style={style}
        disabled={!!schema.disabled}
      />
    </ReferenceInput>
  );
};

const oneOfRequired = (values: any[]) => async (value: any) => {
  return values.includes(value) ? undefined : 'ra.validation.required';
};

/** A select input that takes its values from a process variable.
 * The variable's content must be a JSON list of objects with { id, name }.
 */
const VariableSourceSelectInput: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const formData = form.getValues();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';
  const [options, setOptions] = useState<any[]>([]);
  const [optionsIds, setOptionsIds] = useState<any[]>([]);
  const [optionsError, setOptionsError] = useState<string>('');

  useEffect(() => {
    if (options.length === 0) {
      const optionSource = formData.variables.find(
        (procVar: { key: string; valueType: string; value: any }) => procVar.key === schema.variableSource
      );
      if (!optionSource?.value || !optionSource?.valueType) {
        setOptionsError(`Error: process variable "${schema.variableSource}" not found`);
        return;
      }
      if (optionSource.valueType !== 'JSON') {
        setOptionsError(`Error: process variable "${schema.variableSource}" type incorrect`);
        return;
      }
      const value = JSON.parse(optionSource.value);
      if (!!value && Array.isArray(value)) {
        setOptionsIds(
          value
            .filter((option: any) => typeof option?.id !== 'undefined')
            .map((option: any) => option.id)
            .reduce((acc: any, cur: any) => acc.concat([cur, `${cur}`]), [])
        );
        setOptions(value);
      } else {
        setOptionsError(`Error: process variable "${schema.variableSource}" format incorrect`);
        return;
      }
    }
  }, [formData.variables, options.length, schema.variableSource]);

  if (!!optionsError) {
    return <>{optionsError}</>;
  }

  const validate = schema.required ? [required(), oneOfRequired(optionsIds)] : [];

  // input elements copied from SimpleSelectInput.
  // not all of them tested, but should work the same
  if (schema.readonly) {
    if (options.length > 0) {
      return (
        <Labeled label={label} className={classes.fullWidth} style={style}>
          {schema.confidential ? (
            <SecretSelectField record={formData} label={label} source={schema.id} choices={options} fullWidth={true} />
          ) : (
            <RASelectField record={formData} label={label} source={schema.id} choices={options} fullWidth={true} />
          )}
        </Labeled>
      );
    }
    return (
      <Labeled label={label} className={classes.fullWidth} style={style}>
        {schema.confidential ? (
          <VaultTextReadOnlyInputPlain record={formData} label={label} source={schema.id} fullWidth={true} />
        ) : (
          <TextField record={formData} label={label} source={schema.id} fullWidth={true} />
        )}
      </Labeled>
    );
  }
  if (options.length < 6) {
    if (schema.confidential) {
      return (
        <SecretRadioButtonGroupInput
          label={label}
          helperText={(schema.helperText?.[locale] ?? '') || ''}
          source={schema.id}
          choices={options}
          row={false}
          validate={validate}
          style={style}
          disabled={!!schema.disabled}
        />
      );
    } else {
      return (
        <RadioButtonGroupInput
          label={label}
          helperText={(schema.helperText?.[locale] ?? '') || ''}
          source={schema.id}
          choices={options}
          row={false}
          validate={validate}
          style={style}
          disabled={!!schema.disabled}
        />
      );
    }
  }
  if (schema.confidential) {
    return (
      <SecretAutocompleteInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        source={schema.id}
        choices={options}
        validate={validate}
        fullWidth={true}
        clearAlwaysVisible={true}
        resettable={true}
        allowEmpty={true}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  } else {
    return (
      <AutocompleteInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        source={schema.id}
        choices={options}
        validate={validate}
        fullWidth={true}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }
};

const SelectInputImpl: React.FC<FieldComponentProps> = props => {
  const form = useFormContext();
  const schema = { ...form.getValues(props.schemaField), ...(props.schemaOverride || {}) };
  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  if (dependencyName) {
    return (
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const context: Record<string, any> = Object.fromEntries(
            variables.map((variable: any) => {
              return get(formData, variable.source) !== undefined
                ? [variable.id, get(formData, variable.source)]
                : [variable.id, get(formData, `${schema.id}:${variable.source}`)];
            })
          );

          if (
            dependencyValue === undefined ||
            (!condition && dependencyValue) ||
            (condition && unary(condition, dependencyValue, context))
          ) {
            if (schema?.vocabulary) {
              return <VocabularySelectInput {...props} />;
            }
            if (schema?.variableSource) {
              return <VariableSourceSelectInput {...props} />;
            }
            return <SimpleSelectInput {...props} />;
          }

          return null;
        }}
      </FormDataConsumer>
    );
  }
  if (schema?.vocabulary) {
    return <VocabularySelectInput {...props} />;
  }
  if (schema?.variableSource) {
    return <VariableSourceSelectInput {...props} />;
  }
  return <SimpleSelectInput {...props} />;
};

export const SelectInput = React.memo(SelectInputImpl);
export default React.memo(SelectField);
