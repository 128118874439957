import { RecordContext, useRecordContext } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { FileField } from 'react-admin';

import { byteaToFile } from '../util/helpers';

const ByteaFileField: React.FC<{ source: string }> = ({ source }) => {
  const record = useRecordContext();
  const [file, setFile] = useState<any>(undefined);
  useEffect(() => {
    if (!source) return;
    if (file === undefined && !!record[source] && typeof record[source] === 'string' && !!record?.metadata[source]) {
      const contentType: string = record?.metadata[source]['type'];
      const filename: string = record?.metadata[source]['name'] || 'Download';
      try {
        setFile(byteaToFile(record[source], filename, contentType));
      } catch (e) {
        console.error(e);
      }
    }
  }, [file, source, record]);

  if (!file) {
    return null;
  }
  return (
    <RecordContext.Provider value={file}>
      <FileField source="src" title="title" target="_blank" download={file.title} />
    </RecordContext.Provider>
  );
};

export default ByteaFileField;
