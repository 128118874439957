import gql from 'graphql-tag';
import { GetListParams, GetManyReferenceParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const GRAPHQL_PROCESS_DEFINITION_LIST_ITEM = `
  id
  key
  name
  description
  suspended
  version
  versionTag
  startableInTasklist
`;

const ProcessDefinitionListItemQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_LIST:
    case RaFetchType.GET_MANY:
      const getListParams = params as GetListParams;
      const getListKey = getListParams.filter.key;
      return {
        query: gql`
          query MyQuery(
            $key: String
            $latest: Boolean
            $startableInTasklist: Boolean
            $firstResult: Int
            $maxResults: Int
          ) {
            camunda_ProcessDefinitions(
              key: $key
              latest: $latest
              startableInTasklist: $startableInTasklist
              firstResult: $firstResult
              maxResults: $maxResults
            ) {
              data {
                ${GRAPHQL_PROCESS_DEFINITION_LIST_ITEM}
              }
              total
            }
          }
        `,
        variables: {
          key: getListKey,
          latest: getListParams.filter.latest,
          startableInTasklist: getListParams.filter.startableInTasklist,
          firstResult: getListKey ? null : (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
          maxResults: getListKey ? null : getListParams.pagination.perPage,
        },
        parseResponse: (response: any) => {
          return getListKey
            ? {
                data: response.data.camunda_ProcessDefinitions.data.sort((a: any, b: any) => b.version - a.version),
                total: response.data.camunda_ProcessDefinitions.data.total,
              }
            : response.data.camunda_ProcessDefinitions;
        },
      };
    case RaFetchType.GET_MANY_REFERENCE:
      const getManyReferenceParams = params as GetManyReferenceParams;
      const getManyKey = (getManyReferenceParams.id as string).split(':')[0];
      return {
        query: gql`
          query MyQuery($key: String, $firstResult: Int, $maxResults: Int) {
            camunda_ProcessDefinitions(key: $key, firstResult: $firstResult, maxResults: $maxResults) {
              data {
                ${GRAPHQL_PROCESS_DEFINITION_LIST_ITEM}
              }
              total
            }
          }
        `,
        variables: {
          key: getManyKey,
          firstResult: getManyKey
            ? null
            : (getManyReferenceParams.pagination.page - 1) * getManyReferenceParams.pagination.perPage,
          maxResults: getManyKey ? null : getManyReferenceParams.pagination.perPage,
        },
        parseResponse: (response: any) => {
          return getManyReferenceParams.id
            ? {
                data: response.data.camunda_ProcessDefinitions.data
                  .sort((a: any, b: any) => b.version - a.version)
                  .slice(
                    (getManyReferenceParams.pagination.page - 1) * getManyReferenceParams.pagination.perPage,
                    getManyReferenceParams.pagination.page * getManyReferenceParams.pagination.perPage
                  ),
                total: response.data.camunda_ProcessDefinitions.total,
              }
            : response.data.camunda_ProcessDefinitions.data;
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default ProcessDefinitionListItemQuery;
