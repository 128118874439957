import { makeStyles } from '@mui/styles';
import { IntrospectionEnumValue, IntrospectionField, IntrospectionObjectType } from 'graphql';
import { parse } from 'query-string';
import React, { useContext } from 'react';
import {
  AutocompleteInput,
  Create,
  FileField,
  FileInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  ToolbarProps,
  TopToolbar,
  useResourceContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import BackButton from '../../Components/BackButton';
import CancelButton from '../../Components/CancelButton';
import HasuraContext from '../../DataProviders/HasuraContext';
import { SCALARINPUTS_MAP } from '../../util/constants';
import {
  base64ToBytea,
  convertFileToBase64,
  getFieldTypeName,
  getReferenceResourceName,
  getSourceResourceField,
  isAggregateField,
  isComputedField,
  isEnumField,
  isFkField,
  isImplicitField,
  isM2MField,
  isM2OField,
  isO2MField,
  isScalarField,
  labelFromField,
  labelFromSchema,
  orderFromField,
} from '../../util/helpers';

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
});

const transform = async (data: any, defaultValue: any) => {
  data = {
    ...data,
    ...defaultValue,
  };
  const promises: Promise<any>[] = [];
  for (const name in data) {
    if (data.hasOwnProperty(name) && data[name] && data[name].rawFile) {
      if (!data['metadata']) {
        data['metadata'] = {};
      }
      data['metadata'][name] = {
        name: data[name].rawFile.name,
        type: data[name].rawFile.type,
        size: data[name].rawFile.size,
      };
      promises.push(
        new Promise(success => {
          convertFileToBase64(data[name]).then((a: any) => {
            data[name] = base64ToBytea(a);
            success(data);
          });
        })
      );
    }
    if (promises.length > 0) {
      return new Promise(success => {
        Promise.all(promises).then(() => {
          success(data);
        });
      });
    } else {
      return data;
    }
  }
};

const CustomToolbar: React.FC<ToolbarProps> = props => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton className={classes.mr} />
      <CancelButton />
    </Toolbar>
  );
};

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const EntityCreate: React.FC<{}> = () => {
  const { schemata, enums, fields: fieldsByName } = useContext(HasuraContext);
  const resourceName = useResourceContext();
  const schema = schemata.get(resourceName) as IntrospectionObjectType;
  const label = labelFromSchema(schema);
  const fields = fieldsByName.get(resourceName) as Map<string, IntrospectionField>;
  const sorted: IntrospectionField[] = schema.fields
    .map(field => [
      orderFromField(
        field,
        fields.has(`${field.name}_id`)
          ? orderFromField(fields.get(`${field.name}_id`) as IntrospectionField)
          : undefined
      ),
      field.name,
    ])
    .sort()
    .map(pair => fields.get(pair[1]) as IntrospectionField);

  const location = useLocation();
  const { initialField, initialValue, redirectTo } = parse(location.search);
  const defaultValue: any = {};
  if (initialField && initialValue) {
    defaultValue[initialField as string] = initialValue;
  }

  return schema ? (
    <Create
      redirect={(redirectTo as string) || 'show'}
      actions={<CustomActions />}
      title={label}
      transform={async (data: any) => await transform(data, defaultValue)}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        {sorted.map((field: IntrospectionField, i: number) => {
          if (isImplicitField(field) || isFkField(field) || isAggregateField(field) || isComputedField(field)) {
            return null;
          } else if (isScalarField(field)) {
            const typeName = getFieldTypeName(field.type);
            const InputComponent = SCALARINPUTS_MAP[typeName] || TextField;
            if (InputComponent === FileInput) {
              return (
                <FileInput key={i} source={field.name} label={labelFromField(field)}>
                  <FileField source="src" title="title" />
                </FileInput>
              );
            } else {
              return <InputComponent key={i} source={field.name} label={labelFromField(field)} fullWidth={true} />;
            }
          } else if (isEnumField(field)) {
            const type_ = enums.get(getFieldTypeName(field.type));
            if (type_) {
              const choices = type_.enumValues.map((value: IntrospectionEnumValue) => {
                return {
                  id: value.name,
                  name: value.description,
                };
              });
              return <SelectInput key={i} source={field.name} choices={choices} />;
            } else {
              return null;
            }
          } else if (isM2MField(field)) {
            return null;
          } else if (isM2OField(field)) {
            const referencedResourceName = getReferenceResourceName(field);
            const referencedResourceSchema = schemata.get(referencedResourceName) as IntrospectionObjectType;
            const referenceField = getSourceResourceField(field, resourceName, schemata);

            if (referencedResourceName === 'camunda_User' && fields.has(`${field.name}_id`)) {
              return (
                <ReferenceInput
                  key={i}
                  label={labelFromField(fields.get(`${field.name}_id`) as IntrospectionField)}
                  source={`${field.name}_id`}
                  reference={referencedResourceName}
                  allowEmpty={true}
                  fullWidth={true}
                >
                  <AutocompleteInput
                    filterToQuery={(q: string) => {
                      if (!q) {
                        return {};
                      }
                      return { q };
                    }}
                    optionText={'name'}
                  />
                </ReferenceInput>
              );
            } else if (!referenceField || referenceField.name === initialField) {
              return null;
            }

            console.log(`M2O ${field.name}: ${schema.name}.${referenceField.name} => ${referencedResourceName}.id`);

            return (
              <ReferenceInput
                key={i}
                label={labelFromField(referenceField, labelFromSchema(referencedResourceSchema))}
                source={referenceField.name}
                reference={referencedResourceName}
                allowEmpty={true}
                fullWidth={true}
              >
                <AutocompleteInput
                  filterToQuery={(q: string) => {
                    if (!q) {
                      return {};
                    }
                    return {
                      name: {
                        format: 'hasura-raw-query',
                        value: {
                          _ilike: `%${q}%`,
                        },
                      },
                    };
                  }}
                  optionText={'name'}
                />
              </ReferenceInput>
            );
          } else if (isO2MField(field)) {
            return null;
          } else {
            return null;
          }
        })}
      </SimpleForm>
    </Create>
  ) : null;
};

export default EntityCreate;
