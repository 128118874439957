import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { evaluate } from 'feelin';
import { IntrospectionEnumValue, IntrospectionField, IntrospectionObjectType } from 'graphql';
import { parse } from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useLocaleState,
  useRecordContext,
  useRedirect,
  useRefresh,
  useShowController,
} from 'react-admin';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import BackButton from '../../Components/BackButton';
import VaultTextField from '../../Components/VaultTextField';
import VaultTextFieldPlain from '../../Components/VaultTextFieldPlain';
import HasuraContext from '../../DataProviders/HasuraContext';
import { Locale } from '../../messages';
import { RelationsViewer, useElements } from '../../RelationsViewer';
import { SCALARCOMPONENTS_MAP } from '../../util/constants';
import {
  getFieldTypeName,
  getReferenceResourceName,
  getSourceResourceField,
  isAggregateField,
  isComputedField,
  isEnumField,
  isFkField,
  isImplicitField,
  isM2MField,
  isM2OField,
  isO2MField,
  isScalarField,
  labelFromField,
  labelFromSchema,
  orderFromField,
} from '../../util/helpers';
import AddEntityCommentButton from './Components/AddEntityCommentButton';
import EditEntityCommentButton from './Components/EditEntityCommentButton';
import EditRelationsButton from './Components/EditRelationsButton';
import EntityActivityList from './Components/EntityActivityList';
import MessageButton, { EntitySettingsMessageButton } from './Components/EntityMessageButton';
import EntityProcessList from './Components/EntityProcessList';

const useStyles = makeStyles({
  multiline: {
    whiteSpace: 'pre-line',
  },
  fullWidth: {
    '& > .MuiFormControl-root': {
      display: 'flex',
      width: '100%',
    },
    '& > .MuiAccordion-root': {
      width: '100%',
    },
    display: 'flex',
    marginBottom: '8px',
  },
  mr: {
    marginRight: '1em',
  },
  tabSpinner: {
    '& .MuiTab-wrapper > *:first-child': {
      width: '30px !important',
      height: '30px !important',
      position: 'absolute',
    },
  },
});

const CustomActions: React.FC<{} & { editable: boolean }> = ({ editable }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <TopToolbar>
      <BackButton
        className={classes.mr}
        onClick={() => {
          const userTaskId = localStorage.getItem('user_task_id');
          if (userTaskId) {
            delete localStorage['user_task_id'];
            redirect(`/UserTask/${userTaskId}/edit`);
            refresh();
          } else {
            navigate(-1);
          }
        }}
      />
      {editable && !location.pathname.endsWith('/relations') && <EditButton record={record} />}
      {location.pathname.endsWith('/relations') && <EditRelationsButton record={record} />}
    </TopToolbar>
  );
};

const EntityShow: React.FC<{}> = () => {
  const { schemata, enums, fields: fieldsByName, introspection } = useContext(HasuraContext);
  const { resource, record } = useShowController();
  const [locale] = useLocaleState();
  const routeParams = useParams();
  const location = useLocation();
  const elements = useElements(introspection, resource, routeParams.id ?? '');
  const schema = schemata.get(resource) as IntrospectionObjectType;
  const label = labelFromSchema(schema);
  const fields = fieldsByName.get(resource) as Map<string, IntrospectionField>;
  const sorted: IntrospectionField[] = schema.fields
    .map(field => [
      orderFromField(
        field,
        fields.has(`${field.name}_id`)
          ? orderFromField(fields.get(`${field.name}_id`) as IntrospectionField)
          : undefined
      ),
      field.name,
    ])
    .sort()
    .map(pair => fields.get(pair[1]) as IntrospectionField);

  const comments = schemata.get(resource + '_comment');
  const relations = sorted.some(field => {
    if (isM2MField(field)) {
      return true;
    } else if (isM2OField(field)) {
      return true;
    } else if (isO2MField(field)) {
      const referencingResourceName = getReferenceResourceName(field);
      return !referencingResourceName.startsWith('vasara_') && !referencingResourceName.endsWith('_comment');
    } else {
      return false;
    }
  });
  const { user_task_id } = parse(location.search);
  if (user_task_id) {
    localStorage.setItem('user_task_id', user_task_id as string);
  }

  const dataProvider = useDataProvider();
  const [editable, setEditable] = useState(false);
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    if (!!resource) {
      (async () => {
        const results = await dataProvider.getList('vasara_entity_settings', {
          filter: { id: resource },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
        });
        for (const result of results?.data || []) {
          setEditable(!!result?.editable);
          setButtons(result?.buttons || []);
          break;
        }
      })();
    }
  }, [dataProvider, resource]);

  const classes = useStyles();

  if (!schema || !record) {
    return null;
  }
  return (
    <Show actions={<CustomActions editable={editable} />} title={label}>
      <TabbedShowLayout>
        <Tab label="vasara.form.record">
          <Box sx={{ marginTop: 1 }}>
            {buttons
              .filter(({ conditional }: any) => (!conditional?.hide ? true : !evaluate(conditional.hide, record)))
              .map((button: EntitySettingsMessageButton, idx) => (
                <MessageButton
                  sx={{
                    marginRight: 2,
                    marginBottom: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                  key={`${idx}`}
                  label={button.label?.[(locale as any) as Locale] || 'n/a'}
                  messageName={button.message}
                  processVariables={button.variables}
                />
              ))}
          </Box>
          {sorted.map((field: IntrospectionField, i: number) => {
            if (isImplicitField(field) || isFkField(field) || isAggregateField(field) || isComputedField(field)) {
              return null;
            } else if (isScalarField(field)) {
              const typeName = getFieldTypeName(field.type);
              const FieldComponent = SCALARCOMPONENTS_MAP[typeName] || TextField;
              if (FieldComponent === DateField) {
                return <DateField key={i} source={field.name} locales="fi-FI" label={labelFromField(field)} />;
              } else if (FieldComponent === NumberField) {
                return <NumberField key={i} source={field.name} locales={['fi']} label={labelFromField(field)} />;
              } else {
                return (
                  <FieldComponent
                    key={`${i}-${field.name}`}
                    source={field.name}
                    className={
                      [VaultTextField, VaultTextFieldPlain, TextField].includes(FieldComponent)
                        ? classes.multiline
                        : undefined
                    }
                    label={labelFromField(field)}
                  />
                );
              }
            } else if (isEnumField(field)) {
              const type_ = enums.get(getFieldTypeName(field.type));
              if (type_) {
                const choices = type_.enumValues.map((value: IntrospectionEnumValue) => {
                  return {
                    id: value.name,
                    name: value.description,
                  };
                });
                return <SelectField key={i} source={field.name} choices={choices} label={labelFromField(field)} />;
              }
              return null;
            } else if (isM2MField(field)) {
              return null;
            } else if (isM2OField(field)) {
              const referencedResourceName = getReferenceResourceName(field);
              const referenceField = getSourceResourceField(field, resource, schemata);
              const referencedResourceSchema = schemata.get(referencedResourceName) as IntrospectionObjectType;

              if (referencedResourceName === 'camunda_User' && fields.has(`${field.name}_id`)) {
                return (
                  <ReferenceField
                    key={i}
                    label={labelFromField(fields.get(`${field.name}_id`) as IntrospectionField)}
                    source={`${field.name}_id`}
                    reference={referencedResourceName}
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                );
              } else if (!referenceField) {
                return null;
              }

              console.log(`M2O ${field.name}: ${schema.name}.${referenceField.name} => ${referencedResourceName}.id`);

              return (
                <ReferenceField
                  key={i}
                  label={labelFromField(referenceField, labelFromSchema(referencedResourceSchema))}
                  source={referenceField.name}
                  reference={referencedResourceName}
                  link={referencedResourceName.startsWith('vocabulary_') ? false : 'show'}
                >
                  <TextField source="name" />
                </ReferenceField>
              );
            } else if (isO2MField(field)) {
              return null;
            } else {
              return null;
            }
          })}
          <Box /> {/* just for space */}
        </Tab>
        {relations ? (
          <Tab
            className={classes.tabSpinner}
            disabled={!elements.length}
            icon={!elements.length ? <CircularProgress /> : <></>}
            label="vasara.form.relations"
            path="relations"
          >
            {!!elements.length && (
              <div style={{ height: '800px' }}>
                <ReactFlowProvider>
                  <RelationsViewer initialElements={elements} rootType={resource} />
                </ReactFlowProvider>
              </div>
            )}
          </Tab>
        ) : null}
        {comments ? (
          <Tab label="vasara.form.comments" path="comments">
            <ReferenceManyField
              label={false}
              reference={resource + '_comment'}
              target="entity_id"
              sort={{ field: 'updated_at', order: 'DESC' }}
            >
              <Datagrid>
                <TextField source="author.name" label="vasara.column.author" />
                {/*<RichTextField source="subject" label="vasara.column.subject" />*/}
                <RichTextField source="body" label="vasara.column.body" />
                <DateField source="updated_at" label="vasara.column.modified" locales="fi-FI" showTime={true} />
                <EditEntityCommentButton />
              </Datagrid>
            </ReferenceManyField>
            <AddEntityCommentButton />
          </Tab>
        ) : null}
        <Tab label="vasara.form.processes" path="processes">
          <Box
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <Box>
              {buttons
                .filter(({ conditional }: any) => (!conditional?.hide ? true : !evaluate(conditional.hide, record)))
                .map((button: EntitySettingsMessageButton, idx) => (
                  <MessageButton
                    sx={{
                      marginRight: 2,
                      marginBottom: 2,
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    key={`${idx}`}
                    label={button.label?.[(locale as any) as Locale] || 'n/a'}
                    messageName={button.message}
                    processVariables={button.variables}
                  />
                ))}
            </Box>
            <EntityProcessList />
          </Box>
        </Tab>
        {record.business_key ? (
          <Tab label="vasara.form.activities" path="activities">
            <EntityActivityList />
          </Tab>
        ) : null}
      </TabbedShowLayout>
    </Show>
  );
};

export default EntityShow;
