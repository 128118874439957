import { BooleanField, DateField, NumberField, NumberInput } from 'react-admin';

import ByteaFileField from '../Components/ByteaFileField';
import ByteaFileInput from '../Components/ByteaFileInput';
import DateInput from '../Components/DateInput';
import JSONBField from '../Components/JSONBField';
import JSONBInput from '../Components/JSONBInput';
import VaultTextField from '../Components/VaultTextField';
import VaultTextInput from '../Components/VaultTextInput';
import YesNoInput from '../Components/YesNoInput';
import { IndexableObject } from '../FormBuilder/types';

// Default items per page
export const PAGE_SIZE = 25;

export const SCALAR = 'SCALAR';
export const ENUM = 'ENUM';

export const RE_AGGREGATE = /_aggregate$/gi;
export const RE_CAMUNDA = /^camunda_/gi;
export const RE_READONLY = /^(readonly|datahub)_/gi;
export const RE_VASARA = /^vasara_/gi;
export const RE_REFERENCE_ATTR = /_(arr|obj)_rel_insert_input/gi;

// Types of scalars
export const SCALAR_TYPES = {
  BOOLEAN: 'Boolean',
  BYTEA: 'bytea',
  DATE: 'date',
  FLOAT8: 'float8',
  NUMERIC: 'numeric',
  INT: 'Int',
  STRING: 'String',
  TIMESTAMP: 'timestamp',
  TIMESTAMPTZ: 'timestamptz',
  JSONB: 'jsonb',
};

// Map SCALAR-types to correct RA Field
export const SCALARCOMPONENTS_MAP: IndexableObject = {
  [SCALAR_TYPES.BOOLEAN]: BooleanField,
  [SCALAR_TYPES.BYTEA]: ByteaFileField,
  [SCALAR_TYPES.DATE]: DateField,
  [SCALAR_TYPES.FLOAT8]: NumberField,
  [SCALAR_TYPES.INT]: NumberField,
  [SCALAR_TYPES.NUMERIC]: NumberField,
  [SCALAR_TYPES.STRING]: VaultTextField,
  [SCALAR_TYPES.TIMESTAMP]: DateField,
  [SCALAR_TYPES.TIMESTAMPTZ]: DateField,
  [SCALAR_TYPES.JSONB]: JSONBField,
};

export const SCALARINPUTS_MAP: IndexableObject = {
  [SCALAR_TYPES.BOOLEAN]: YesNoInput,
  [SCALAR_TYPES.BYTEA]: ByteaFileInput,
  [SCALAR_TYPES.DATE]: DateInput,
  [SCALAR_TYPES.FLOAT8]: NumberInput,
  [SCALAR_TYPES.INT]: NumberInput,
  [SCALAR_TYPES.NUMERIC]: NumberInput,
  [SCALAR_TYPES.STRING]: VaultTextInput,
  [SCALAR_TYPES.TIMESTAMP]: DateInput,
  [SCALAR_TYPES.JSONB]: JSONBInput,
};

// The main "identifier" columns used to display item in list
export const MAIN_IDENTIFIER_COLUMNS = [
  'name',
  'title',
  'description',
  'summary',
  'comment',
  'message',
  'updated_at',
  'author_id',
];

// Table columns which won't be displayed in UI (ID is usually something we don't want the user to have access to)
export const IMPLICIT_COLUMNS = [
  'id',
  'metadata',
  'business_key',
  'author_id',
  'editor_id',
  'created_at',
  'updated_at',
];

export const FK_FIELD_SUFFIXES = ['_id'];
export const ONE_TO_MANY_SUFFIX = '_obj_rel_insert_input';
export const MANY_TO_ONE_SUFFIX = '_arr_rel_insert_input';

// Resources which should be hidden from main menu
export const HIDE_FROM_MAIN_MENU = [
  /^camunda_/gi,
  /^datahub_/gi,
  /^export/gi,
  /^vasara_/gi,
  /^vocabulary_/gi,
  /_comment$/gi,
];

// Resources which are added manually (to use different component than EntityList & EntityShow)
export const MANUALLY_ADDED_RESOURCES = [/^camunda_/gi, /^datahub_/gi, /^vasara_/gi];

// Resources which are linked
export const OPPOSITE_SPECIFIER_MAPPING: Record<string, string> = {
  child: 'parent',
  parent: 'child',
  primary: 'secondary',
  secondary: 'primary',
  provider: 'consumer',
  consumer: 'provider',
  master: 'minion',
  minion: 'master',
  superior: 'subordinate',
  subordinate: 'superior',
};
