import React, { useEffect, useState } from 'react';
import ReactFlow, { Controls, Elements, useStoreState, useZoomPanHelper } from 'react-flow-renderer';

import FloatingConnectionLine from './edge/FloatingConnectionLine';
import FloatingEdge from './edge/FloatingEdge';
import { createGraphLayout } from './layout';
import EntityNode from './node/EntityNode';

const nodeTypes = {
  entityNode: EntityNode,
};

const edgeTypes = {
  floatingEdge: FloatingEdge,
};

interface ViewerProps {
  initialElements: Elements;
  rootType: string;
}

const RelationsViewer: React.FC<ViewerProps> = ({ initialElements, rootType }) => {
  const [elements, setElements] = useState(initialElements);
  const [loading, isLoading] = useState(true);
  const nodes = useStoreState(store => store.nodes);
  const edges = useStoreState(store => store.edges);
  const { fitView } = useZoomPanHelper();

  useEffect(() => {
    (async function () {
      if (loading && nodes.length) {
        await createGraphLayout([...nodes, ...edges], rootType)
          .then((els: Elements) => {
            isLoading(false);
            setElements(els);
            fitView();
          })
          .catch(err => console.error(err));
      }
    })();
  }, [nodes, edges, fitView, loading, rootType, elements]);

  return (
    <ReactFlow
      edgeTypes={edgeTypes}
      elements={elements}
      connectionLineComponent={FloatingConnectionLine}
      nodesConnectable={false}
      nodeTypes={nodeTypes}
      minZoom={0.1}
      selectNodesOnDrag={true}
    >
      <Controls />
    </ReactFlow>
  );
};

export default RelationsViewer;
