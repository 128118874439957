import { ApolloQueryResult } from 'apollo-client';
import { IntrospectionEnumType, IntrospectionField, IntrospectionObjectType } from 'graphql';
import { IntrospectionQuery, IntrospectionSchema, IntrospectionType } from 'graphql/utilities/getIntrospectionQuery';

export enum RaFetchType {
  GET_LIST = 'GET_LIST',
  GET_ONE = 'GET_ONE',
  GET_MANY = 'GET_MANY',
  GET_MANY_REFERENCE = 'GET_MANY_REFERENCE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_MANY = 'UPDATE_MANY',
  DELETE = 'DELETE',
  DELETE_MANY = 'DELETE_MANY',
}

export type HasuraTypeKind =
  | 'SCALAR'
  | 'OBJECT'
  | 'INTERFACE'
  | 'UNION'
  | 'ENUM'
  | 'INPUT_OBJECT'
  | 'LIST'
  | 'NON_NULL';

export interface HasuraSchema extends IntrospectionSchema {
  readonly resources: IntrospectionType[];
  readonly schema: IntrospectionSchema;
}

export interface HasuraContext {
  readonly introspectionQueryResponse: ApolloQueryResult<IntrospectionQuery>;
  readonly introspection: HasuraSchema;
  readonly schemata: Map<string, IntrospectionObjectType>;
  readonly fields: Map<string, Map<string, IntrospectionField>>;
  readonly vocabularies: Map<string, IntrospectionObjectType>;
  readonly enums: Map<string, IntrospectionEnumType>;
}
