import _ from 'lodash';

import { isInputObjectType, isScalarType, isSystemType } from '../introspection/';
import { SimplifiedIntrospectionWithIds, SimplifiedTypeWithIDs } from '../introspection/types';

export function isNode(type: SimplifiedTypeWithIDs) {
  return !(isScalarType(type) || isInputObjectType(type) || isSystemType(type) || type.isRelayType);
}

export function getDefaultRoot(schema: SimplifiedIntrospectionWithIds) {
  return schema.queryType.name;
}

export function getTypeGraph(schema: SimplifiedIntrospectionWithIds | null, rootId: string, hideRoot: boolean) {
  if (schema === null) return null;

  return buildGraph(rootId);

  function getEdgeTargets(type: SimplifiedTypeWithIDs) {
    return _([..._.values(type.fields), ...(type.derivedTypes || []), ...(type.possibleTypes || [])])
      .map('type')
      .filter(isNode)
      .map('id')
      .value();
  }

  function buildGraph(rootId: string) {
    var typeIds = [rootId];
    var nodes = [];
    var types = _.keyBy(schema?.types, 'id');

    for (var i = 0; i < typeIds.length; ++i) {
      var id = typeIds[i];
      if (typeIds.indexOf(id) < i) continue;

      var type = types[id];

      nodes.push(type);
      typeIds.push(...getEdgeTargets(type));
    }
    return {
      rootId,
      nodes: hideRoot ? _.omit(_.keyBy(nodes, 'id'), [rootId]) : _.keyBy(nodes, 'id'),
    };
  }
}
