import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import React from 'react';
import { Button, useLogin } from 'react-admin';

const LoginPage = ({ theme }: any) => {
  const login = useLogin();
  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          height: '100vh',
          width: '100wh',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onClick={() => login({})} label={'Login'} />
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
