import { TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import VaultTextFieldPlain from './VaultTextFieldPlain';

export const withReadOnlySecret = (WrappedComponent: any) => ({ ...props }) => {
  const form = useFormContext();
  const value = props.source ? form.watch(props.source) : '';
  if (value.startsWith('vault:')) {
    return <VaultTextFieldPlain {...props} />;
  } else {
    return <WrappedComponent {...props} />;
  }
};

const VaultTextInput = withReadOnlySecret(TextInput);

export default VaultTextInput;
