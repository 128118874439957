import 'dmn-js/dist/assets/dmn-font/css/dmn.css';
import 'dmn-js/dist/assets/dmn-js-decision-table.css';
import 'dmn-js/dist/assets/dmn-js-drd.css';
import 'dmn-js/dist/assets/dmn-js-shared.css';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Settings from './Settings';
import { getEnv } from './util/env';

(() => {
  // Support embed mode with very specific configuration.
  // If app is loaded with /#/?id_token=...&access_token=...
  // Auth configures implicit session and here isEmbedded flag
  // is set hide app bar and sidebar at Layout.
  let isEmbedded = false;
  const hashQSParts =
    window.location.hash.indexOf('?') > -1
      ? window.location.hash.replace(/^#/, '').split('?')[1].split('&')
      : window.location.hash.replace(/^#/, '').split('&');
  if (
    hashQSParts.length === 2 &&
    hashQSParts[0].startsWith('id_token=') &&
    hashQSParts[1].startsWith('access_token=')
  ) {
    isEmbedded = true;
  }

  ReactDOM.render(
    <Settings.Provider
      value={{
        isLite: getEnv('REACT_APP_BUILD_TARGET') === 'tasklist',
        isEmbedded,
      }}
    >
      <App />
    </Settings.Provider>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
