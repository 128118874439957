import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AppBar } from 'react-admin';

import LanguageMenu from './LanguageMenu';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title" className={classes.title} />
      </Box>
      <LanguageMenu />
    </AppBar>
  );
};

export default CustomAppBar;
