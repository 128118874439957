import { Description, Inbox } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { MenuItemLink, useSidebarState, useTranslate } from 'react-admin';

import img from '../static/logo.png';

const useStyles = makeStyles({
  img: {
    maxWidth: '200px',
    margin: '2em auto 2em auto',
  },
  spacer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

const Menu = ({ onMenuClick }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [open] = useSidebarState();
  return (
    <>
      <MenuItemLink
        key="guest"
        to={window.location.hash.replace(/^#/, '')}
        primaryText={translate('vasara.ui.guestForm')}
        leftIcon={<Inbox />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider />
      <MenuItemLink
        leftIcon={<Description />}
        onClick={(e: any) => {
          window.location.href = translate('vasara.ui.privacyNotice');
          e.preventDefault();
          e.stopPropagation();
        }}
        to=""
        sidebarIsOpen={open}
        primaryText={translate('vasara.ui.privacyNoticeLabel')}
      />
      {open ? (
        <>
          <div className={classes.spacer} />
          <img src={img} alt="" className={classes.img} />
        </>
      ) : null}
    </>
  );
};

export default Menu;
