import React, { CSSProperties } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { FieldTypes } from './constants';
import ArrayField, { ArrayInput } from './Fields/ArrayField';
import BooleanField, { BooleanInput } from './Fields/BooleanField';
import ButtonField, { ButtonDisplay } from './Fields/ButtonField';
import DateField, { DateInput } from './Fields/DateField';
import FileField, { FileInput } from './Fields/FileField';
import HelpField, { HelpDisplay } from './Fields/HelpField';
import MapCoordinateField, { MapCoordinateInput } from './Fields/MapCoordinateField';
import NumberField, { NumberInput } from './Fields/NumberField';
import SelectField, { SelectInput } from './Fields/SelectField';
import StringField, { StringInput } from './Fields/StringField';
import TableField, { TableInput } from './Fields/TableField';
import { Choice } from './types';

export interface CommonFieldProps {
  expanded: string;
  setExpanded: (fieldName: string) => void;
  inputName: string;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  sourceChoices: Choice[];
  readonlySourceChoices: Choice[];
  vocabularyChoices: Choice[];
  fieldChoices: Choice[];
  messageChoices?: Choice[];
}

export const EditableFieldComponent: Record<string, React.ComponentType<CommonFieldProps>> = {};

EditableFieldComponent[FieldTypes.ARRAY] = ArrayField;
EditableFieldComponent[FieldTypes.TABLE] = TableField;
EditableFieldComponent[FieldTypes.BOOLEAN] = BooleanField;
EditableFieldComponent[FieldTypes.DATE] = DateField;
EditableFieldComponent[FieldTypes.FILE] = FileField;
EditableFieldComponent[FieldTypes.HELP] = HelpField;
EditableFieldComponent[FieldTypes.NUMBER] = NumberField;
EditableFieldComponent[FieldTypes.SELECT] = SelectField;
EditableFieldComponent[FieldTypes.STRING] = StringField;
EditableFieldComponent[FieldTypes.BUTTON] = ButtonField;
EditableFieldComponent[FieldTypes.GEO] = MapCoordinateField;

export const FieldDefaultProps: Record<string, any> = {};

FieldDefaultProps[FieldTypes.STRING] = {
  multiline: false,
  required: false,
  type: FieldTypes.STRING,
};

FieldDefaultProps[FieldTypes.NUMBER] = {
  required: false,
  type: FieldTypes.NUMBER,
};

FieldDefaultProps[FieldTypes.BOOLEAN] = {
  required: false,
  type: FieldTypes.BOOLEAN,
};

FieldDefaultProps[FieldTypes.DATE] = {
  required: false,
  type: FieldTypes.DATE,
};

FieldDefaultProps[FieldTypes.FILE] = {
  required: false,
  type: FieldTypes.FILE,
};

FieldDefaultProps[FieldTypes.SELECT] = {
  required: false,
  type: FieldTypes.SELECT,
};

FieldDefaultProps[FieldTypes.ARRAY] = {
  required: false,
  type: FieldTypes.ARRAY,
};

FieldDefaultProps[FieldTypes.TABLE] = {
  required: false,
  type: FieldTypes.TABLE,
};

FieldDefaultProps[FieldTypes.HELP] = {
  label: null,
  helperText: null,
  required: false,
  type: FieldTypes.HELP,
};

FieldDefaultProps[FieldTypes.BUTTON] = {
  label: null,
  type: FieldTypes.BUTTON,
};

FieldDefaultProps[FieldTypes.GEO] = {
  required: false,
  type: FieldTypes.GEO,
};

/** Props that every field type should accept so that they can be used generically. **/
export interface FieldComponentProps {
  // path to this field's schema in the form data
  schemaField: string;
  // custom styles to apply to the field
  style?: CSSProperties;
  // override some fields from the schema found in form data.
  // this is used with nested fields in TableInput
  schemaOverride?: Record<string, any>;
  actionsRef?: React.RefObject<HTMLElement | undefined>;
  toolbarRef?: React.RefObject<HTMLElement | undefined>;
}

export const FieldComponent: Record<string, React.ComponentType<FieldComponentProps>> = {};

FieldComponent[FieldTypes.ARRAY] = ArrayInput;
FieldComponent[FieldTypes.TABLE] = TableInput;
FieldComponent[FieldTypes.BOOLEAN] = BooleanInput;
FieldComponent[FieldTypes.DATE] = DateInput;
FieldComponent[FieldTypes.FILE] = FileInput;
FieldComponent[FieldTypes.HELP] = HelpDisplay;
FieldComponent[FieldTypes.NUMBER] = NumberInput;
FieldComponent[FieldTypes.SELECT] = SelectInput;
FieldComponent[FieldTypes.STRING] = StringInput;
FieldComponent[FieldTypes.BUTTON] = ButtonDisplay;
FieldComponent[FieldTypes.GEO] = MapCoordinateInput;

export const EnabledFieldTypes = [
  FieldTypes.STRING,
  FieldTypes.BOOLEAN,
  FieldTypes.NUMBER,
  FieldTypes.DATE,
  FieldTypes.FILE,
  FieldTypes.SELECT,
  //  FieldTypes.ARRAY,
  FieldTypes.TABLE,
  FieldTypes.HELP,
  FieldTypes.BUTTON,
  FieldTypes.GEO,
];

export const EnabledFieldTypesChoices = EnabledFieldTypes.map((type: string) => {
  return {
    id: type,
    name: `vasara.form.${type}`,
  };
});
