import { makeStyles } from '@mui/styles';
import { IntrospectionField, IntrospectionOutputTypeRef } from 'graphql';
import get from 'lodash/get';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NumberInput,
  ArrayInput as RAArrayInput,
  SelectInput as RASelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { getUserTaskForm } from '../../DataProviders/Camunda/helpers';
import UserTaskEditContext from '../../DataProviders/Camunda/UserTaskEditContext';
import HasuraContext from '../../DataProviders/HasuraContext';
import { getFieldTypeName, isEnumField } from '../../util/helpers';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const ArrayField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <NumberInput source={`${props.inputName}.min`} label="vasara.form.min" defaultValue={1} />
      <NumberInput source={`${props.inputName}.max`} label="vasara.form.max" defaultValue={1} />

      <RAArrayInput source={`${props.inputName}.options`} label="vasara.form.options">
        <SimpleFormIterator>
          <TextInput source={`id`} label="vasara.form.value" />
          <TextInput source={`name.${locale}`} label="vasara.form.label" />
        </SimpleFormIterator>
      </RAArrayInput>

      <AutocompleteArrayInput
        id={`${props.inputName}-sources`}
        label="vasara.form.sources"
        helperText="vasara.form.helperText.options"
        source={`${props.inputName}.sources`}
        choices={props.sourceChoices}
        validate={validateRequired}
        fullWidth={true}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <TextInput
                  id={`${props.inputName}-key`}
                  label="vasara.form.key"
                  helperText="vasara.form.helperText.key"
                  source={`${props.inputName}.key`}
                  validate={validateRequired}
                  defaultValue={label}
                  fullWidth={true}
                />
              );
            }
          }
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }: any) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
              />

              {/*<BooleanInput*/}
              {/*  id={`${props.inputName}-confidential`}*/}
              {/*  label="vasara.form.confidential"*/}
              {/*  source={`${props.inputName}.confidential`}*/}
              {/*  defaultValue={false}*/}
              {/*  className={classes.floatLeft}*/}
              {/*/>*/}

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <RASelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

const maxRequired = (max: number = 1) => (value: any) => {
  return value && value.length > max ? `You've checked more than ${max} option(s)` : undefined;
};

const minRequired = (min: number = 1) => (value: any) => {
  return value && value < min ? `Please check at least ${min} option(s)` : undefined;
};

export const ArrayInputImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const [locale] = useLocaleState();
  const context = useContext(UserTaskEditContext);
  const taskForm = getUserTaskForm(context.userTask);
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  let options = (schema.options || []).map((option: any) => {
    return {
      id: option.id,
      name: option.name[locale],
    };
  });

  // Resolve options from schema
  const { fields: fieldsByName, enums } = useContext(HasuraContext);

  if (!options || options.length === 0) {
    for (const source of schema.sources) {
      const parts = source.split('.');
      if (fieldsByName.has(parts[0])) {
        const fields = fieldsByName.get(parts[0]) as Map<string, IntrospectionField>;
        const field = fields.get(parts[1]);
        if (field && isEnumField(field)) {
          const type_ = enums.get(getFieldTypeName((field.type as unknown) as IntrospectionOutputTypeRef));
          if (type_) {
            options = type_.enumValues.map(value => {
              return {
                id: value.name,
                name: value.description,
              };
            });
          }
        }
        if (options.length > 0) {
          break;
        }
      }
      if (options.length > 0) {
        break;
      }
    }
  }

  // Resolve options from user task form
  if (!options || options.length === 0) {
    for (let field of taskForm) {
      const id = `context.${field.id}`;
      if (field.values && schema.sources.indexOf(id) > -1) {
        options = field.values;
        break;
      }
    }
  }

  if (options.length <= 6) {
    return (
      <CheckboxGroupInput
        source={schema.id}
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        choices={options}
        row={false}
        validate={[minRequired(schema.min), maxRequired(schema.max)]}
        fullWidth={true}
      />
    );
  }
  return (
    <AutocompleteArrayInput
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      choices={options}
      validate={[minRequired(schema.min), maxRequired(schema.max)]}
      fullWidth={true}
    />
  );
};

export const ArrayInput = React.memo(ArrayInputImpl);
export default React.memo(ArrayField);
