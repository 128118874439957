import React, { useCallback } from 'react';
import {
  BooleanInput,
  CreateContextProvider,
  CreateControllerProps,
  FileField,
  FileInput,
  SaveContextProvider,
  SimpleForm,
  Title,
  TopToolbar,
  useCreateController,
  useTranslate,
} from 'react-admin';

import BackButton from '../../../Components/BackButton';
import { BPMN } from '../../../DataProviders/Camunda/helpers';
import { FileContainer, convertFileToText } from '../../../util/helpers';

interface Resource {
  name: string;
  payload: string;
}

const ProcessDefinitionCreate: React.FC<CreateControllerProps> = props => {
  const translate = useTranslate();
  const createControllerProps = useCreateController(props);
  const { defaultTitle, save, saving } = createControllerProps;

  const saveWithFile: typeof save = useCallback(
    async (data, callbacks) => {
      if (!save) return;

      let deploymentName = null;
      const resources: Resource[] = await Promise.all(
        data.resources.map(async (file: FileContainer) => {
          const name = file.rawFile.name;
          const payload = await convertFileToText(file);
          if (name.endsWith('.bpmn')) {
            const bpmn = await BPMN(payload);
            for (const rootElement of bpmn.getDefinitions().rootElements) {
              if (rootElement && rootElement.name) {
                deploymentName = rootElement.name;
                if (rootElement.$type === 'bpmn:Process') {
                  break;
                }
              }
            }
          }
          return { name, payload };
        })
      );
      if (deploymentName === null && resources.length > 0) {
        deploymentName = resources[0].name;
      }
      return await save(
        {
          name: deploymentName,
          resources,
          migrate: !!data.migrate,
        },
        callbacks
      );
    },
    [save]
  );

  return (
    <CreateContextProvider value={createControllerProps}>
      <SaveContextProvider value={{ save: saveWithFile, saving, mutationMode: 'pessimistic' }}>
        <div>
          <TopToolbar>
            <BackButton />
          </TopToolbar>
          <Title title={translate('vasara.page.deployProcessDefinition') || defaultTitle} />
          <SimpleForm>
            <FileInput source="resources" label="vasara.page.processDeploymentFiles" multiple={true}>
              <FileField source="src" title="title" />
            </FileInput>
            <BooleanInput
              source="migrate"
              defaultValue={true}
              label="vasara.page.migrate"
              helperText="vasara.page.helperText.migrate"
              fullWidth={true}
            />
          </SimpleForm>
        </div>
      </SaveContextProvider>
    </CreateContextProvider>
  );
};

export default ProcessDefinitionCreate;
