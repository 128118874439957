import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Button, TextInput, useCreate, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { vault_transit_decrypt_request_batch } from '../DataProviders/Actions/types';

const useStyles = makeStyles(() => ({
  confidential: {
    height: '50px',
    overflow: 'hidden',
  },
  button: {
    // don't display as inline so other elements don't line up next to it
    display: 'flex',
  },
}));

export const withSecret = (WrappedComponent: any) => ({ ...props }) => {
  const [decrypted, setDecrypted] = useState(new Date().getTime() - 1000);
  const notify = useNotify();
  const classes = useStyles();
  const form = useFormContext();

  const [doCreate] = useCreate();
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            reject(error);
          },
        }
      );
    });

  if (new Date().getTime() - decrypted < 1000) {
    return <WrappedComponent {...props} />;
  }

  const value = props.source ? form.watch(props.source) : '';
  if (!value || !value.startsWith || !value.startsWith('vault:')) {
    return <WrappedComponent {...props} />;
  }

  const request: vault_transit_decrypt_request_batch = {
    batch: [{ ciphertext: value }],
  };

  return (
    <>
      <WrappedComponent {...props} helperText={false} className={classes.confidential} disabled />
      <Button
        label="vasara.action.showConfidential"
        variant="outlined"
        className={classes.button}
        onClick={async e => {
          try {
            const result = await create('vault_transit_decrypt', request);
            if (result.batch[0].plaintext) {
              form.setValue(props.source, result.batch[0].plaintext);
              setDecrypted(new Date().getTime());
            } else {
              notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            }
          } catch (e) {}
          e.preventDefault();
        }}
      >
        <LockIcon />
      </Button>
    </>
  );
};

const VaultTextInput = withSecret(TextInput);

export default VaultTextInput;
