import { get } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { unary } from '../util/feel';
import { FieldDependencyVariable } from './types';

// This function takes in a schema and a form object, and returns a record of dependency values
export const useDependencyContext = (schema: any, form: UseFormReturn): Record<string, any> => {
  // Extract the dependency name from the schema
  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;

  // Get the dependency values from the form object
  const dependencyValues = !!dependencyName
    ? form.watch(
        [dependencyName].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.source))
      )
    : [];

  // Create a dependency context object that maps dependency names to their values
  const dependencyContext = (!!dependencyName
    ? ['?'].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.id))
    : []
  ).reduce((acc: Record<string, any>, key, index) => {
    acc[key] = dependencyValues[index];
    return acc;
  }, {});

  // Return the dependency context object
  return dependencyContext;
};

// This function takes in a schema and a form object, and returns a record of dependency values
export const buildDependencyContext = (schema: any, record: any): Record<string, any> => {
  // Extract the dependency name from the schema
  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;

  // Get the dependency values from the record object
  const dependencyValues = !!dependencyName
    ? [get(record, dependencyName)].concat(
        (((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => get(record, v.source))
      )
    : [];

  // Create a dependency context object that maps dependency names to their values
  const dependencyContext = (!!dependencyName
    ? ['?'].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.id))
    : []
  ).reduce((acc: Record<string, any>, key, index) => {
    acc[key] = dependencyValues[index];
    return acc;
  }, {});

  // Return the dependency context object
  return dependencyContext;
};

export const shouldShowField = (showExpression: string, expressionContext: Record<string, any>): boolean => {
  // ? is value of the dependency field
  // 1) if dependency field is not defined, show the field
  // 2) if dependency field is defined, but expression is not defined, show the field if dependency field is true
  // 3) if dependency field is defined and expression is defined, show the field if expression is true
  return (
    typeof expressionContext['?'] === 'undefined' ||
    (!showExpression ? !!expressionContext['?'] : unary(showExpression, expressionContext['?'], expressionContext))
  );
};

export const shouldHideField = (showExpression: string, expressionContext: Record<string, any>): boolean => {
  return !shouldShowField(showExpression, expressionContext);
};
