import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { User } from 'oidc-client';

import { getUser } from '../Auth/authProvider';
import { createTracedFetch } from '../tracing';
import { getEnv } from '../util/env';

const titleCase = (s: string): string => (s ? s[0].toUpperCase() + s.slice(1) : '');

// Runs before every request so the headers are always up-to-date
const hasuraAuthLink = setContext((_, { headers }) => {
  const user: User = getUser();
  return {
    headers: {
      ...headers,
      Authorization: !!user?.access_token ? `${titleCase(user.token_type)} ${user.access_token}` : undefined,
    },
  };
});

// Runs before every request so the headers are always up-to-date
const camundaAuthLink = setContext((_, { headers }) => {
  const user: User = getUser();
  return {
    headers: {
      ...headers,
      Authorization: !!user?.access_token ? `${titleCase(user.token_type)} ${user.access_token}` : undefined,
      /* Instruct Hasura Auth Service to not unnecessarily resolve authorizations */
      'X-Vasara-Remote-Schema': 'Camunda',
    },
  };
});

// Runs before every request so the headers are always up-to-date
const actionsAuthLink = setContext((_, { headers }) => {
  const user: User = getUser();
  return {
    headers: {
      ...headers,
      Authorization: !!user?.access_token ? `${titleCase(user.token_type)} ${user.access_token}` : undefined,
      /* Instruct Hasura Auth Service to not unnecessarily resolve authorizations */
      'X-Vasara-Remote-Schema': 'Actions',
    },
  };
});

const cache = new InMemoryCache();

let introspectionResponse: any = null;

const tracedFetch = createTracedFetch('hasura');
const introspectionCachingFetch = async (uri: string, options: any) => {
  // We instantiate three Apollo Clients (Camunda, Actions and Hasura) for the
  // same endpoint. Therefore we must do whatever to prevent doing the expensive
  // introspection query three times.
  if (options.body.startsWith('{"operationName":"IntrospectionQuery"')) {
    if (!introspectionResponse) {
      const response = await tracedFetch(uri, options);
      introspectionResponse = await response.text();
    }
    return {
      status: 200,
      text: async () => introspectionResponse,
    } as Response;
  } else {
    return await tracedFetch(uri, options);
  }
};

const client = (authLink: any) =>
  new ApolloClient({
    link: ApolloLink.from([
      authLink,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          });
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      new HttpLink({
        uri: getEnv('REACT_APP_GRAPHQL_API_URL') || 'http://localhost:8090/v1/graphql',
        fetch: introspectionCachingFetch,
      }),
    ]),
    cache,
  });

const defaultHasuraClient = client(hasuraAuthLink);

export const CamundaHasuraClient = client(camundaAuthLink);
export const ActionsHasuraClient = client(actionsAuthLink);

export default defaultHasuraClient;
