import { IntrospectionEnumValue, IntrospectionField } from 'graphql';
import React, { cloneElement, useContext, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FilterProps,
  List,
  Loading,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useCreate,
  useDataProvider,
  useListContext,
  useLocaleState,
  useResourceContext,
} from 'react-admin';

import { xlsx_export_request, xlsx_export_response } from '../../DataProviders/Actions/types';
import HasuraContext from '../../DataProviders/HasuraContext';
import Settings from '../../Settings';
import { MAIN_IDENTIFIER_COLUMNS, PAGE_SIZE, SCALARCOMPONENTS_MAP } from '../../util/constants';
import {
  base64ToBlob,
  getFieldTypeName,
  isAggregateField,
  isComputedField,
  isEnumField,
  isImplicitField,
  isScalarField,
  labelFromField,
  labelFromSchema,
  toLabel,
} from '../../util/helpers';
import EditEntitySettingsButton from './Components/EditEntitySettingsButton';

const CustomExporter = (resource: string, doCreate: any) => async (data: any[]) => {
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: (data: any) => {
            resolve(data);
          },
          onError: (error: any) => {
            reject(error);
          },
        }
      );
    });
  if (data.length > 0) {
    const sample = data[0];
    const keys = Object.keys(sample);
    if (keys.includes('metadata')) {
      const req: xlsx_export_request = {
        sheets: {
          [resource]: [keys].concat(
            data.map((row: any) =>
              Object.keys(row).map((key: any) => {
                if (row[key] === null || typeof row[key] === 'undefined') {
                  return '';
                } else if (typeof row[key] === 'object') {
                  return JSON.stringify(row[key]);
                } else {
                  return row[key];
                }
              })
            )
          ),
        },
      };
      try {
        const res: xlsx_export_response = await create('xlsx_export', req);
        const blob = base64ToBlob(res.base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = resource + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {}
    }
  }
};

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  const [doCreate] = useCreate();
  const { isLite } = useContext(Settings);
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {!isLite && <EditEntitySettingsButton />}
      <ExportButton exporter={CustomExporter(resource, doCreate)} />
    </TopToolbar>
  );
};

const EntityFilters: React.FC<Omit<FilterProps, 'children'> & { filters: any; columns: any }> = ({
  filters,
  columns,
  ...rest
}) => {
  const { enums } = useContext(HasuraContext);
  const [locale] = useLocaleState();
  return (
    <Filter {...rest}>
      {filters.map((field: any, i: number) => {
        if (isEnumField(field)) {
          const type_ = enums.get(getFieldTypeName(field.type));
          if (type_) {
            const choices = type_.enumValues.map((value: IntrospectionEnumValue) => {
              return {
                id: value.name,
                name: value.description,
              };
            });
            return <SelectInput key={i} source={field.name} choices={choices} label={labelFromField(field)} alwaysOn />;
          }
          return null;
        } else {
          return (
            <TextInput
              key={i}
              label={columns.get(field.name)?.label?.[locale] ?? labelFromField(field)}
              source={`${field.name}@_ilike`}
              alwaysOn
            />
          );
        }
      })}
    </Filter>
  );
};

interface EntitySettingsColumn {
  path: string;
  label?: Record<string, string>;
  filter: boolean;
  sortable: boolean;
}

const EntityList: React.FC<{}> = () => {
  const { schemata, enums } = useContext(HasuraContext);
  const resourceName = useResourceContext();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<EntitySettingsColumn[]>([]);
  const [locale] = useLocaleState();

  useEffect(() => {
    (async () => {
      try {
        const settings = await dataProvider.getList('vasara_entity_settings', {
          filter: { id: resourceName },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
        });
        if (settings.data?.length ?? 0) {
          setColumns(settings.data[0]?.columns ?? []);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [dataProvider, resourceName]);

  if (loading) {
    return <Loading />;
  }

  const schema = schemata.get(resourceName);
  const label = schema ? labelFromSchema(schema) : toLabel(resourceName);
  const fields: Map<string, IntrospectionField> = schema
    ? new Map(schema.fields.map((field: IntrospectionField) => [field.name, field]))
    : new Map();
  const columnByPath: Map<string, EntitySettingsColumn> = new Map(columns.map(column => [column.path, column]));

  const identifiers: (IntrospectionField | EntitySettingsColumn)[] = (() => {
    if (!schema) {
      return [];
    }
    let idents: (IntrospectionField | EntitySettingsColumn | undefined)[];
    if (columns.length > 0) {
      idents = columns.map(column => fields.get(column.path ?? 'n/a') ?? column);
    } else {
      idents = MAIN_IDENTIFIER_COLUMNS.map(name => fields.get(name));
    }
    // filter out possible undefineds and cast to reflect this in the type
    return idents.filter(field => !!field) as (IntrospectionField | EntitySettingsColumn)[];
  })();

  if (identifiers.length === 0) {
    for (const field of schema?.fields ?? []) {
      if (isImplicitField(field) || isAggregateField(field) || isComputedField(field)) {
        continue;
      } else if (isScalarField(field)) {
        identifiers.push(field);
      }
      if (identifiers.length > 2) {
        break;
      }
    }
  }
  const filters = identifiers.filter(field => 'name' in field && !!field.name && columnByPath.get(field.name)?.filter);

  return (
    <List
      perPage={PAGE_SIZE}
      title={label}
      actions={<ListActions />}
      filters={<EntityFilters filters={filters} columns={columnByPath} />}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        {identifiers.map((field: IntrospectionField | EntitySettingsColumn, i: number) => {
          if ('path' in field) {
            // type of `field` is EntitySettingsColumn, i.e. not found in schema
            return <TextField key={i} source={field.path} label={field.label?.[locale]} sortable={!!field.sortable} />;
          } else if (isScalarField(field)) {
            const typeName = getFieldTypeName(field.type);
            const FieldComponent = SCALARCOMPONENTS_MAP[typeName] || TextField;
            return FieldComponent === DateField ? (
              <DateField
                key={i}
                source={field.name}
                showTime={true}
                locales="fi-FI"
                label={columnByPath.get(field.name)?.label?.[locale] ?? labelFromField(field)}
              />
            ) : (
              <FieldComponent
                key={i}
                source={field.name}
                label={columnByPath.get(field.name)?.label?.[locale] ?? labelFromField(field)}
              />
            );
          } else if (isEnumField(field)) {
            const type_ = enums.get(getFieldTypeName(field.type));
            if (type_) {
              const choices = type_.enumValues.map((value: IntrospectionEnumValue) => {
                return {
                  id: value.name,
                  name: value.description,
                };
              });
              return (
                <SelectField
                  key={i}
                  source={field.name}
                  choices={choices}
                  label={columnByPath.get(field.name)?.label?.[locale] ?? labelFromField(field)}
                />
              );
            }
            return null;
          } else {
            return null;
          }
        })}
      </Datagrid>
    </List>
  );
};

export default EntityList;
