import DateFnsUtils from '@date-io/date-fns';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fi from 'date-fns/locale/fi';
import React, { useCallback } from 'react';
import { FieldTitle, InputProps } from 'react-admin';
import { InputHelperText, sanitizeInputRestProps, useInput } from 'react-admin';

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const convertDateToString = (value: Date): string | undefined => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return;
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const defaultInputLabelProps = { shrink: true };

const dateStringValidator = (value: string) => {
  if (value === null || value === undefined || value === '') {
    return;
  }
  value = value.split('T')[0];
  return isNaN(new DateFnsUtils({ locale: fi }).parse(value, 'yyyy-MM-dd').getTime()) &&
    isNaN(new DateFnsUtils({ locale: fi }).parse(value, 'dd.MM.yyyy').getTime())
    ? 'vasara.validation.invalidDate'
    : null;
};

const getStringFromDate = (value: string | Date) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  const dateFns = new DateFnsUtils({ locale: fi });
  const isoDate = dateFns.parse(value, 'yyyy-MM-dd');
  if (!isNaN(isoDate.getTime())) {
    return convertDateToString(isoDate);
  }

  const fiDate = dateFns.parse(value, 'dd.MM.yyyy');
  if (!isNaN(fiDate.getTime())) {
    return convertDateToString(fiDate);
  }

  return value;
};

const DateInput: React.FC<InputProps<TextFieldProps> & Omit<TextFieldProps, 'helperText' | 'label'>> = ({
  format = getStringFromDate,
  label,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant = 'filled',
  fullWidth,
  ...rest
}) => {
  const {
    id,
    field,
    isRequired,
    fieldState: { isTouched, error },
  } = useInput({
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate: validate
      ? Array.isArray(validate)
        ? validate.concat([dateStringValidator])
        : [validate, dateStringValidator]
      : [dateStringValidator],
    ...rest,
  });
  const handleChange = useCallback(
    value => {
      field.onChange(getStringFromDate(value));
    },
    [field]
  );

  // field value in form data is a string, but the date picker wants a date.
  // convert string to date if it matches a date format, otherwise set to null.
  // (must match because otherwise Chromium will create a date from anything,
  // e.g. new Date('1') returns '01.01.2001' and that gets entered into the text field
  const valueAsDate = (() => {
    if (!field.value) return null;

    // when loaded from Camunda, field.value has a time part. remove it
    const value = field.value.split('T')[0];
    const dateFns = new DateFnsUtils({ locale: fi });
    const isoDate = dateFns.parse(value, 'yyyy-MM-dd');
    if (!isNaN(isoDate.getTime())) {
      return isoDate;
    }
    // this can return an invalid date if the format doesn't match,
    // which is in fact the desired behavior
    // because returning null instead would cause the text field
    // to be cleared in certain situations, which we don't want
    const fiDate = dateFns.parse(value, 'dd.MM.yyyy');
    return fiDate;
  })();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
      <DatePicker
        value={valueAsDate}
        onChange={(date: any) => handleChange(date)}
        inputFormat="dd.MM.yyyy"
        disableMaskedInput={true}
        renderInput={(props: TextFieldProps) => (
          <TextField
            {...sanitizeInputRestProps(rest)}
            {...field}
            {...props}
            onChange={e => handleChange(e.target.value)}
            id={id}
            variant={variant}
            margin={margin}
            InputLabelProps={defaultInputLabelProps}
            error={!!error}
            label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
            helperText={<InputHelperText touched={isTouched || false} error={error?.message} helperText={helperText} />}
            fullWidth={fullWidth}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
