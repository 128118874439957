import gql from 'graphql-tag';
import { CreateParams, GetListParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const DecisionDefinitionQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          mutation MyMutation($name: String!, $resources: [camunda_DeploymentResource!]!) {
            insert_camunda_DecisionDefinition(name: $name, resources: $resources) {
              id
              key
              name
              diagram
              resource
              version
              versionTag
            }
          }
        `,
        variables: createParams.data,
        parseResponse: (response: any) => {
          return {
            data: response.data.insert_camunda_DecisionDefinition,
          };
        },
      };
    case RaFetchType.GET_LIST:
    case RaFetchType.GET_MANY:
    case RaFetchType.GET_MANY_REFERENCE:
      const getListParams = params as GetListParams;
      // NOTE: diagram is only fetched for GET_ONE
      return {
        query: gql`
          query MyQuery($latest: Boolean, $firstResult: Int, $maxResults: Int) {
            camunda_DecisionDefinitions(latest: $latest, firstResult: $firstResult, maxResults: $maxResults) {
              data {
                id
                key
                name
                diagram
                resource
                version
                versionTag
              }
              total
            }
          }
        `,
        variables: {
          ...getListParams.filter,
          firstResult: (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
          maxResults: getListParams.pagination.perPage,
        },
        parseResponse: (response: any) => response.data.camunda_DecisionDefinitions,
      };
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_DecisionDefinition(id: $id) {
              id
              key
              name
              diagram
              resource
              version
              versionTag
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_DecisionDefinition,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default DecisionDefinitionQuery;
