import DownloadIcon from '@mui/icons-material/GetApp';
import { Button } from 'ra-ui-materialui';
import React from 'react';
import { useCreate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { xlsx_export_request, xlsx_export_response } from '../DataProviders/Actions/types';
import { base64ToBlob } from '../util/helpers';

export interface XlsxExportSheet {
  name: string; // sheet name
  columns: string[]; // sheet column names in order
}

export interface XlsxExportLinkProps {
  sheets: Record<string, XlsxExportSheet>; // map field ids to sheet spec
}

export const XlsxExportLink: React.FC<XlsxExportLinkProps> = ({ sheets }) => {
  const [doCreate] = useCreate();
  const form = useFormContext();

  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            reject(error);
          },
        }
      );
    });

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const req: xlsx_export_request = {
      sheets: Object.keys(sheets)
        .map(key => {
          return {
            [`${sheets[key].name}`]: [sheets[key].columns].concat(
              (form.getValues(key) || []).map((row: any) => sheets[key].columns.map((key: any) => row[key]))
            ),
          };
        })
        .reduce((acc, cur) => {
          return { ...acc, ...cur };
        }),
    };
    try {
      const res: xlsx_export_response = await create('xlsx_export', req);
      const blob = base64ToBlob(res.base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download =
        Object.keys(sheets)
          .map(key => sheets[key].name)
          .join('_') + '.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {}
  };

  return (
    <Button onClick={handleClick} label="ra.action.export">
      <DownloadIcon />
    </Button>
  );
};
