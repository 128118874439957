import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslate } from 'ra-core';
import * as React from 'react';
import { useState } from 'react';
import { useLocaleState } from 'react-admin';

const LanguageMenu: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <nav aria-label={translate('vasara.menu.language')}>
      <Button aria-label={translate('vasara.action.switch')} color="inherit" onClick={handleMenu}>
        {locale}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={locale === 'fi'}
          onClick={() => {
            setLocale('fi');
            handleClose();
          }}
        >
          Suomeksi
        </MenuItem>
        <MenuItem
          disabled={locale === 'en'}
          onClick={() => {
            setLocale('en');
            handleClose();
          }}
        >
          In English
        </MenuItem>
      </Menu>
    </nav>
  );
};

export default LanguageMenu;
