import React, { useContext } from 'react';
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  Filter,
  FilterProps,
  List,
  Pagination,
  PaginationProps,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin';

import BackButton from '../../../Components/BackButton';
import Settings from '../../../Settings';
import { PAGE_SIZE } from '../../../util/constants';
import { StartButton } from './StartButton';

const CustomActions: React.FC<{}> = () => {
  const settings = useContext(Settings);
  return (
    <TopToolbar>
      <BackButton redirect={'/'} />
      {settings.isLite ? null : <CreateButton label="ra.action.create" resource="ProcessDefinition" />}
    </TopToolbar>
  );
};

const Filters: React.FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <BooleanInput label="vasara.filters.startableInTasklistOnly" source="startableInTasklist" alwaysOn />
  </Filter>
);

const ListPagination: React.FC<PaginationProps> = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ProcessDefinitionList: React.FC<{}> = () => {
  const settings = useContext(Settings);
  const translate = useTranslate();
  return (
    <List
      filterDefaultValues={{ latest: true }}
      title={translate('vasara.view.processDefinitionList')}
      resource="camunda_ProcessDefinition_ListItem"
      exporter={false}
      pagination={<ListPagination />}
      perPage={PAGE_SIZE}
      actions={<CustomActions />}
      filter={settings.isLite ? { startableInTasklist: true } : {}}
      filters={settings.isLite ? <></> : <Filters />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={settings.isLite ? undefined : id => `/ProcessDefinition/${id}/show`}
      >
        <TextField source="name" label={translate('vasara.column.processDefinitionName')} />
        <StartButton />
      </Datagrid>
    </List>
  );
};

export default ProcessDefinitionList;
