import { withStyles } from '@mui/styles';
import React from 'react';
import { EditButton, useRecordContext, useResourceContext } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
  },
};

const EditEntityCommentButton: React.FC<{ classes: Record<string, any> }> = ({ classes }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  if (!record) return null;
  return <EditButton className={classes.button} to={`/${resource}/${record.id}/edit`} />;
};

export default withStyles(styles)(EditEntityCommentButton);
