// RadioButtonGroupInputItem.tsx from ra-ui-materialui v4.7.2
// modifications:
// - allow removing selection by clicking on selected option

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useChoices } from 'ra-core';
import { useFormContext } from 'react-hook-form';

export const RadioButtonGroupInputItem = ({ choice, optionText, optionValue, source, translateChoice }: any) => {
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });
  const label = getChoiceText(choice);
  const choiceValue = getChoiceValue(choice);

  const form = useFormContext();
  const groupValue = form && source ? form.watch(source) : undefined;

  const nodeId = `${source}_${choiceValue}`;

  return (
    <FormControlLabel
      label={label}
      htmlFor={nodeId}
      value={choiceValue}
      control={
        groupValue === choiceValue ? (
          <Radio
            id={nodeId}
            color="primary"
            onClick={evt => {
              form.setValue(source, null);
              evt.preventDefault();
            }}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                form.setValue(source, null);
                evt.preventDefault();
              }
            }}
          />
        ) : (
          <Radio id={nodeId} color="primary" />
        )
      }
    />
  );
};

export default RadioButtonGroupInputItem;
