import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import React, { useEffect, useRef } from 'react';

import { DMNViewer as DMN } from '../DataProviders/Camunda/helpers';

interface Props {
  diagramXML: string;
}

const useStyles = makeStyles({
  dmn: {
    width: '100%',
    height: '400px',
  },
});

const ReactDmn: React.FC<Props> = props => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      const model = await DMN(props.diagramXML);
      if (ref.current !== null) {
        ref.current.innerHTML = '';
        (model as any).attachTo(ref.current);
      }
    })();
  }, [props.diagramXML, ref]);

  return <div className={classes.dmn} ref={ref} />;
};

const DmnField: React.FC<{ source: string }> = ({ source }) => {
  const record = useRecordContext();
  const value = get(record, source);
  return <ReactDmn diagramXML={value} />;
};

export default DmnField;
