import { evaluate } from 'feelin';
import React, { useState } from 'react';
import { Button, ButtonProps, useDataProvider, useNotify, useRecordContext, useRedirect, useUpdate } from 'react-admin';

import { Locale } from '../../../messages';
import { CamundaVariable } from '../../../types';

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export interface MessageButtonVariable {
  id: string;
  expression: string;
}

export interface EntitySettingsMessageButton {
  label: Record<Locale, string>;
  message: string;
  variables: MessageButtonVariable[];
}

export interface MessageButtonProps {
  messageName: string;
  processVariables: MessageButtonVariable[];
}

const inferValue = (key: string, value: any): CamundaVariable => {
  if (typeof value === 'boolean') {
    return { key, value: `${value}`, valueType: 'BOOLEAN' };
  } else if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      return { key, value: `${value}`, valueType: 'LONG' };
    } else {
      return { key, value: `${value}`, valueType: 'DOUBLE' };
    }
  } else if (value instanceof Date) {
    return { key, value: value.toUTCString().split('.')[0], valueType: 'DATE' };
  } else if (typeof value === 'object') {
    return { key, value: JSON.stringify(value), valueType: 'JSON' };
  } else {
    return { key, value: `${value || ''}`, valueType: 'STRING' };
  }
};

const MessageButton: React.FC<ButtonProps & MessageButtonProps> = props => {
  const record = useRecordContext();
  const [update] = useUpdate();
  const [busy, setBusy] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const { messageName, processVariables, ...rest } = props;

  const sendMessage = (payload: any) => {
    return new Promise<any>((resolve, _) => {
      update('ProcessInstance', payload, {
        onSuccess: data => {
          notify('vasara.notification.ok', { type: 'info' });
          resolve(data);
        },
        onError: (error: any) => {
          notify(error.message, { type: 'error' });
          resolve(null);
        },
      });
    });
  };

  const correlate = async (payload: any) => {
    await sendMessage(payload);

    // TODO: Refactor this loop to useEffect instead of loop to allow canceling
    // on manual location change.
    for (let i = 1; i < 3; i++) {
      await sleep(300 * i); // wait for task to change or disappear
      const results = await dataProvider.getList('camunda_UserTask_ListItem', {
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'created',
          order: 'desc',
        },
        filter: { businessKey: record.business_key },
      });
      if (!!results?.total) {
        redirect('edit', '/UserTask', results.data[0].id);
        return;
      }
    }
    redirect('?', '/' + props.resource, record.id);
  };

  return (
    record.business_key && (
      <Button
        {...rest}
        variant="contained"
        color="inherit"
        disabled={busy}
        onClick={async () => {
          const data = {
            messageName,
            businessKey: record.business_key,
            processVariables: processVariables.map(variable => {
              return inferValue(variable.id, evaluate(variable.expression, record));
            }),
          };
          setBusy(true);
          try {
            await correlate({
              id: record.id,
              data,
            });
          } finally {
            setBusy(false);
          }
        }}
      />
    )
  );
};

export default MessageButton;
