import Work from '@mui/icons-material/Work';
import { ResourceProps } from 'react-admin';

import messages from '../../../messages';
import DecisionDefinitionCreate from './DecisionDefinitionCreate';
import DecisionDefinitionList from './DecisionDefinitionList';
import DecisionDefinitionShow from './DecisionDefinitionShow';

const resource: ResourceProps = {
  name: 'DecisionDefinition',
  create: DecisionDefinitionCreate,
  list: DecisionDefinitionList,
  show: DecisionDefinitionShow,
  icon: Work,
  options: {
    label: messages.translate('vasara.resource.decisionDefinition'),
  },
};

export default resource;
