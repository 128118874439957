import React from 'react';
import { ListContextProvider, SingleFieldList, useRecordContext, useReferenceManyFieldController } from 'react-admin';

import { AccordionBpmnField } from '../../../Components/Accordion';

const EntityProcessList: React.FC<{}> = () => {
  const record = useRecordContext();
  const controller = useReferenceManyFieldController({
    record,
    resource: 'camunda_HistoricProcessInstance',
    reference: 'camunda_HistoricProcessInstance',
    source: 'business_key',
    target: 'businessKey',
  });
  return (
    <ListContextProvider
      value={{
        ...controller,
        data: controller.data?.sort((a, b) => {
          if (!a.endTime && !b.endTime) {
            return 0;
          }
          if (!a.endTime) {
            return -1;
          }
          if (!b.endTime) {
            return 1;
          }
          return b.startTime.localeCompare(a.startTime);
        }),
      }}
    >
      <SingleFieldList
        sx={{
          /* eslint-disable-next-line no-useless-computed-key */
          ['& > .MuiAccordion-root']: {
            width: '100%',
          },
        }}
        linkType={false}
      >
        <AccordionBpmnField
          source="processDefinition.diagram"
          label={(record: any) =>
            `${record?.processDefinition?.name}${
              record.endTime ? ` (${record.startTime.split('T')[0]} - ${record.endTime.split('T')[0]})` : ''
            }`
          }
          defaultExpanded={(record: any) => !record.endTime}
        />
      </SingleFieldList>
    </ListContextProvider>
  );
};

export default EntityProcessList;
