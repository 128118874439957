import Build from '@mui/icons-material/Build';
import { ResourceProps } from 'react-admin';

import messages from '../../../messages';
import ProcessDefinitionCreate from './ProcessDefinitionCreate';
import ProcessDefinitionList from './ProcessDefinitionList';
import ProcessDefinitionShow from './ProcessDefinitionShow';

const resource: ResourceProps = {
  name: 'ProcessDefinition',
  create: ProcessDefinitionCreate,
  list: ProcessDefinitionList,
  edit: ProcessDefinitionShow,
  show: ProcessDefinitionShow,
  icon: Build,
  options: {
    label: messages.translate('vasara.resource.processDefinition'),
  },
};

export default resource;
