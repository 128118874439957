import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListControllerProps,
  Pagination,
  PaginationProps,
  TextField,
  useListController,
} from 'react-admin';

import { getUser } from '../../../Auth/authProvider';
import { PAGE_SIZE } from '../../../util/constants';

const ListPagination: React.FC<PaginationProps> = props => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const UserTaskList: React.FC<{}> = () => {
  const isGuest = (getUser()?.profile?.preferred_username || '').match(/^guest-.*/);

  // fetching data using useListController
  // and also creating a List component with the same parameters
  // causes completed tasks to disappear from the task list immediately (somehow).
  // If we don't do this, an old tasklist containing obsolete tasks
  // is loaded from cache when navigating back to the page after completing a task
  const controllerParams: ListControllerProps = {
    resource: 'camunda_UserTask_ListItem',
    filter: { full: true },
    exporter: false,
    perPage: PAGE_SIZE,
  };
  const { data } = useListController(controllerParams);

  return (
    <List {...controllerParams} hasCreate={!isGuest} title="vasara.resource.userTask" pagination={<ListPagination />}>
      <Datagrid data={data} bulkActionButtons={false} rowClick={id => `/UserTask/${id}/edit`}>
        <TextField source="processDefinition.name" label="vasara.column.processDefinitionName" sortable={false} />
        <DateField
          source="historicProcessInstance.startTime"
          label="vasara.column.startTime"
          locales="fi-FI"
          sortable={false}
        />
        <TextField source="description" label="vasara.column.description" sortable={false} />
        <TextField source="name" label="vasara.column.userTask" sortable={false} />
        <TextField source="assignee.name" label="vasara.column.assignee" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default UserTaskList;
