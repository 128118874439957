import ContentCreate from '@mui/icons-material/Create';
import { RaRecord, useCreatePath, useRecordContext, useResourceContext } from 'ra-core';
import { Button, EditButtonProps } from 'react-admin';
import { Link } from 'react-router-dom';

const defaultIcon = <ContentCreate />;

const EditRelationsButton = <RecordType extends RaRecord = any>(props: EditButtonProps<RecordType>) => {
  const { icon = defaultIcon, label = 'ra.action.edit', scrollToTop = true, ...rest } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const createPath = useCreatePath();
  if (!record) return null;
  return (
    <Button
      component={Link}
      to={createPath({ type: 'edit', resource, id: record.id }) + '/relations'}
      state={scrollStates[String(scrollToTop)]}
      label={label}
      onClick={stopPropagation}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};

// avoids using useMemo to get a constant value for the link state
const scrollStates: { [key: string]: { _scrollToTop?: boolean } } = {
  true: { _scrollToTop: true },
  false: {},
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

export default EditRelationsButton;
