import Add from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Button, UseReferenceManyFieldControllerParams, useRecordContext, useRedirect } from 'react-admin';

const useStyles = makeStyles(() => ({
  button: {},
}));

const AddEntityRelationButton: React.FC<UseReferenceManyFieldControllerParams & { current: string }> = ({
  current,
  reference,
  target,
}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const record = useRecordContext();
  return (
    <Button
      label="ra.action.add"
      className={classes.button}
      onClick={() => {
        redirect(
          `/${reference}/create?initialField=${target}&initialValue=${record.id}&redirectTo=/${current}/${record.id}/relations`
        );
      }}
    >
      <Add />
    </Button>
  );
};

export default AddEntityRelationButton;
