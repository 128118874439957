import FeelEditor from '@bpmn-io/feel-editor';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import * as React from 'react';
import { ElementType, forwardRef, useEffect, useRef, useState } from 'react';
import { FieldTitle, TextInputProps } from 'react-admin';
import { useInput } from 'react-admin';

const InputComponent = forwardRef<HTMLDivElement, TextInputProps>((props, ref) => {
  return (
    <Box
      sx={{
        paddingTop: 2.7,
        paddingLeft: 1.5,
        paddingRight: 2,
        paddingBottom: 0.5,
        minWidth: 1,
      }}
      ref={ref}
    />
  );
});

interface variable {
  name: string;
  detail?: string;
  info?: string;
  entries?: variable[];
}

const FeelInput = ({
  label,
  resource,
  source,
  variables,
  validate,
  ...rest
}: TextInputProps & { variables: variable[] }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [editor, setEditor] = useState();
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [errorMessage, setErrorMessage]: [string | undefined, any] = useState(undefined);
  const { field, isRequired } = useInput({
    source,
    label,
    resource,
    validate: validate
      ? Array.isArray(validate)
        ? [...validate, () => errorMessage]
        : [validate, () => errorMessage]
      : () => errorMessage,
    ...rest,
  });

  const ref = useRef(null);

  useEffect(() => {
    if (!!ref?.current && !editor) {
      setEditor(
        new FeelEditor({
          ...field,
          container: ref.current,
          onLint: (messages: any) => {
            if (messages.length > 0) {
              console.log(messages[0]);
              setErrorMessage(messages[0].message);
            } else {
              setErrorMessage(undefined);
            }
          },
        })
      );
    }
  }, [ref, field, editor]);

  if (!!editor) {
    (editor as any).setVariables(variables);
  }

  return (
    <TextField
      {...field}
      {...rest}
      label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
      inputRef={ref}
      InputProps={{
        // "I just might know, what I am doing..."
        inputComponent: (InputComponent as any) as ElementType<InputBaseComponentProps>,
      }}
    />
  );
};

export default FeelInput;
