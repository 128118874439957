// FieldTitle.tsx from ra-core v4.7.2
// modifications:
// - change required field symbol to lens icon
// - add Clippy for copying field value to clipboard

import LensIcon from '@mui/icons-material/Lens';
import { ReactElement, memo } from 'react';
import { useTranslate, useTranslateLabel } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Clippy } from '../Components/Clippy';

export interface FieldTitleProps {
  isRequired?: boolean;
  resource?: string;
  source?: string;
  label?: string | ReactElement | boolean;
}

export const FieldTitle = (props: FieldTitleProps) => {
  const { source, label, resource, isRequired } = props;
  const form = useFormContext();
  const value = form && source ? form.watch(source) : undefined;
  const translateLabel = useTranslateLabel();
  const translate = useTranslate();

  if (label === true) {
    throw new Error('Label parameter must be a string, a ReactElement or false');
  }

  if (label === false || label === '') {
    return null;
  }

  if (label && typeof label !== 'string') {
    return label;
  }

  const content = (
    <>
      {translateLabel({
        label,
        resource,
        source,
      })}
      {isRequired && (
        <LensIcon
          sx={{
            color: '#cc0000',
            fontSize: '1rem',
            marginLeft: 1,
          }}
          titleAccess={translate('ra.validation.required')}
        />
      )}
    </>
  );

  return <Clippy value={value}>{content}</Clippy>;
};

// What? TypeScript loses the displayName if we don't set it explicitly
FieldTitle.displayName = 'FieldTitle';

export default memo(FieldTitle);
