// @ts-ignore
import { KEYUTIL, KJUR } from 'jsrsasign';
import { Log, User, UserManager } from 'oidc-client';
import { AuthProvider } from 'ra-core';

import { getEnv } from '../util/env';

Log.logger = console;
// Log.level = Log.DEBUG;

const AUTHORITY = getEnv('REACT_APP_AUTHORITY') || 'http://localhost:8000/auth/realms/vasara';
const CLIENT_ID = getEnv('REACT_APP_CLIENT_ID') || 'vasara-app';
const REDIRECT_URI = getEnv('REACT_APP_REDIRECT_URI') || 'http://localhost:3000/';
const LOGOUT_URI =
  getEnv('REACT_APP_LOGOUT_URI') || 'http://localhost:8000/auth/realms/vasara/protocol/openid-connect/logout';
const SESSION_KEY = `oidc.user:${AUTHORITY}:${CLIENT_ID}`;
const PUBLIC_KEY = KEYUTIL.getKey(
  getEnv('REACT_APP_TRANSIENT_USER_PUBLIC_KEY') ||
    `
-----BEGIN PUBLIC KEY-----
MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE2VQ3u7QMgSib7dKxikVNE5ZE+U3U
IOG4ZPlx6rq6n9EbdZieTvJkwPw7DIXt0udeGzkTQLbBNzEXrcpdc/Jfbw==
-----END PUBLIC KEY-----
`
);

const clientSettings = {
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  post_logout_redirect_uri: LOGOUT_URI,
  response_type: 'code',
  scope: 'openid email profile groups roles',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 30,
};

export const userManager = new UserManager(clientSettings);

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const mutex = {
  free: true,
  acquire: async () => {
    while (!mutex.free) {
      await sleep(100);
    }
    mutex.free = false;
  },
  release: async () => {
    mutex.free = true;
  },
};

export const getUser = (): User => {
  return sessionStorage.getItem(SESSION_KEY) ? JSON.parse(sessionStorage.getItem(SESSION_KEY) || '"{}"') : {};
};

export const getUserName = (): string | null => {
  const userInfo: User = getUser();
  const userName = userInfo?.profile?.preferred_username || null;
  const defaultTenant = getEnv('REACT_APP_DEFAULT_TENANT') || '';
  return userName && userName.match('@') ? userName : userName + defaultTenant;
};

export const getUserFullName = (): string => {
  const userInfo: User = getUser();
  return userInfo?.profile?.given_name && userInfo?.profile?.family_name
    ? `${userInfo.profile.given_name} ${userInfo.profile.family_name}`
    : userInfo?.profile?.name || getUserName() || 'n/a';
};

export const getUserEmail = (): string => {
  const userInfo: User = getUser();
  return userInfo?.profile?.email || 'n/a';
};

const authProvider: AuthProvider = {
  loginInProgress: false,

  login: async () => {
    try {
      await mutex.acquire();
      await userManager.signinRedirect({ state: window.location.href });
      authProvider.loginInProgress = true;
    } finally {
      await mutex.release();
    }
  },

  logout: async () => {
    const logoutRoute: string = LOGOUT_URI;
    try {
      await mutex.acquire();
      sessionStorage.clear();
      // TODO: Whatabout userManager.signoutRedirectCallback...
      if (logoutRoute.startsWith('http://') || logoutRoute.startsWith('https://')) {
        window.location.href = logoutRoute;
      } else {
        return LOGOUT_URI;
      }
    } finally {
      await mutex.release();
    }
  },

  checkAuth: async () => {
    try {
      await mutex.acquire();
      const user = getUser();
      if (!!user?.expires_at && new Date(user.expires_at * 1000) >= new Date()) {
        window.history.replaceState(null, '', window.location.href.replace(window.location.search, ''));
        return Promise.resolve();
      } else if (authProvider.loginInProgress) {
        return Promise.resolve();
      } else {
        const qsParts = window.location.search.replace(/^\?/, '').split('&');
        const hashQSParts =
          window.location.hash.indexOf('?') > -1
            ? window.location.hash.replace(/^#/, '').split('?')[1].split('&')
            : window.location.hash.replace(/^#/, '').split('&');
        if (
          hashQSParts.length === 2 &&
          hashQSParts[0].startsWith('id_token=') &&
          hashQSParts[1].startsWith('access_token=')
        ) {
          const idToken = hashQSParts[0].substring('id_token='.length);
          const token = hashQSParts[1].substring('access_token='.length);
          const joseUtil: any = (userManager as any)._joseUtil;
          const data = joseUtil.parseJwt(idToken);
          const user = new User({
            id_token: idToken,
            session_state: '',
            access_token: token,
            refresh_token: '',
            token_type: 'bearer',
            scope: '',
            profile: {
              iss: '',
              sub: '',
              aud: '',
              exp: 0,
              iat: 0,
              preferred_username: data.payload.id,
              email: data.payload.email,
              name: `${data.payload.family_name} ${data.payload.preferred_name || data.payload.given_name}`,
            },
            expires_at: new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000).getTime() / 1000,
            state: '',
          });
          await userManager.storeUser(user);
          authProvider.loginInProgress = false;
          return Promise.resolve();
        } else if (qsParts[0].startsWith('guest=') || hashQSParts[0].startsWith('guest=')) {
          const token = qsParts[0].startsWith('guest=')
            ? qsParts[0].substring('guest='.length)
            : hashQSParts[0].substring('guest='.length);
          const isValid = KJUR.jws.JWS.verify(token, PUBLIC_KEY);
          const joseUtil: any = (userManager as any)._joseUtil;
          if (isValid) {
            const data = joseUtil.parseJwt(token);
            const user = new User({
              id_token: '',
              session_state: '',
              access_token: token,
              refresh_token: '',
              token_type: 'transient',
              scope: '',
              profile: {
                iss: '',
                sub: '',
                aud: '',
                exp: 0,
                iat: 0,
                preferred_username: data.payload.id,
                email: data.payload.email,
                name: `${data.payload.lastName} ${data.payload.firstName}`,
              },
              expires_at: new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000).getTime() / 1000,
              state: '',
            });
            await userManager.storeUser(user);
            authProvider.loginInProgress = false;
          }
          return Promise.resolve();
        }
        const qs = window.location.search;
        if (qs.match(/state=/) && qs.match(/code=/)) {
          const user = await userManager.signinRedirectCallback();
          window.history.replaceState(
            null,
            '',
            user.state ? user.state : window.location.href.replace(window.location.search, '')
          );
          authProvider.loginInProgress = false;
          return Promise.resolve();
        }
      }
      throw await Promise.reject();
    } finally {
      await mutex.release();
    }
  },

  getPermissions: async () => {},

  checkError: async (error: any) => {
    const user = getUser();
    if (error.status === 401 || error.status === 403) {
      // API unauthorized
      if (!authProvider.loginInProgress) {
        sessionStorage.clear();
        return Promise.reject();
      }
    } else if (user && new Date(((user as unknown) as User).expires_at * 1000) < new Date()) {
      // Token expired
      sessionStorage.clear();
      await authProvider.login({});
      return Promise.resolve();
    }
    return Promise.resolve();
  },

  getIdentity: async () => {
    const user = getUser();
    return { id: user?.profile?.preferred_username || '', fullName: user?.profile?.name || '' };
  },
};

export default authProvider;
