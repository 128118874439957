import { ResourceProps } from 'react-admin';

import EntityCreate from './EntityCreate';
import EntityEdit from './EntityEdit';
import EntityList from './EntityList';
import EntityShow from './EntityShow';

// name is set dynamically to the specific entity's name, hence Omit here
const resource: Omit<ResourceProps, 'name'> = {
  create: EntityCreate,
  edit: EntityEdit,
  show: EntityShow,
  list: EntityList,
};

export default resource;
