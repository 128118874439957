import { makeStyles } from '@mui/styles';
import { IntrospectionEnumValue, IntrospectionField, IntrospectionObjectType } from 'graphql';
import React, { useContext } from 'react';
import {
  AutocompleteInput,
  ChipField,
  ChipFieldProps,
  Edit,
  Labeled,
  ListContext,
  NumberInput,
  ReferenceArrayField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  Toolbar,
  TopToolbar,
  UseCreateResult,
  UseReferenceManyFieldControllerParams,
  required,
  useCreate,
  useDelete,
  useEditContext,
  useEditController,
  useNotify,
  useRecordContext,
  useReferenceManyFieldController,
  useRefresh,
} from 'react-admin';

import BackButton from '../../Components/BackButton';
import CancelButton from '../../Components/CancelButton';
import VaultTextInput from '../../Components/VaultTextInput';
import VaultTextReadOnlyInput from '../../Components/VaultTextReadOnlyInput';
import VaultTextReadOnlyInputPlain from '../../Components/VaultTextReadOnlyInputPlain';
import { vault_transit_encrypt_request } from '../../DataProviders/Actions/types';
import HasuraContext from '../../DataProviders/HasuraContext';
import { SCALARINPUTS_MAP } from '../../util/constants';
import {
  base64ToBytea,
  convertFileToBase64,
  getFieldTypeName,
  getMainIdentifierColumn,
  getOtherReferenceFields,
  getReferenceFields,
  getReferenceResourceName,
  getReferencingResourceField,
  getReferencingResourceFieldName,
  getSourceResourceField,
  isAggregateField,
  isComputedField,
  isEnumField,
  isFkField,
  isImplicitField,
  isM2MField,
  isM2OField,
  isO2MField,
  isScalarField,
  labelFromField,
  labelFromSchema,
  orderFromField,
} from '../../util/helpers';
import AddRelatedEntityRelationButton from './Components/AddEntityRelatedEntityButton';
import AddEntityRelationButton from './Components/AddEntityRelationButton';

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
  relationButtons: {
    display: 'flex',
  },
});

const transform = (data: any, resource: string, record: any, doCreate: any, notify: any) => {
  const promises: Promise<any>[] = [];
  const secrets: vault_transit_encrypt_request[] = [];
  const secretsKeys: string[] = [];
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: (data: any) => {
            resolve(data);
          },
          onError: (error: any) => {
            notify(error.message, { type: 'error' });
            reject(error);
          },
        }
      );
    });
  for (const name in data) {
    // Encryption pass
    if (
      data.hasOwnProperty(name) &&
      (record[name] || '').startsWith &&
      (record[name] || '').startsWith('vault:') &&
      !((data[name] || '').startsWith && (data[name] || '').startsWith('vault:'))
    ) {
      secrets.push({
        businessKey: record.business_key,
        sources: [`${resource}.${name}`],
        confidential: true,
        pii: false,
        plaintext: data[name],
      });
      secretsKeys.push(name);
    }
    // Handle file input
    if (data.hasOwnProperty(name) && data[name] && data[name].rawFile) {
      if (!data['metadata']) {
        data['metadata'] = {};
      }
      data['metadata'][name] = {
        name: data[name].rawFile.name,
        type: data[name].rawFile.type,
        size: data[name].rawFile.size,
      };
      promises.push(
        new Promise(success => {
          convertFileToBase64(data[name]).then((a: any) => {
            data[name] = base64ToBytea(a);
            success(data);
          });
        })
      );
    }
  }
  if (secrets.length > 0) {
    return create('vault_transit_encrypt', { batch: secrets }).then(results => {
      for (let i = 0; i < results.batch.length; i++) {
        data[secretsKeys[i]] = results.batch[i].ciphertext;
        return new Promise(success => {
          Promise.all(promises).then(() => {
            success(data);
          });
        });
      }
    });
  } else if (promises.length > 0) {
    return new Promise(success => {
      Promise.all(promises).then(() => {
        success(data);
      });
    });
  } else {
    return data;
  }
};

const CustomToolbar = (props: any) => {
  const classes = useStyles();
  const edit = useEditContext();
  return (
    <Toolbar {...props}>
      <SaveButton className={classes.mr} />
      <CancelButton
        redirect={
          props.editRelations
            ? `/${edit.resource}/${edit.record?.id}/show/relations`
            : `/${edit.resource}/${edit.record?.id}/show`
        }
      />
    </Toolbar>
  );
};

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

interface ChipFieldWithDeleteProps extends Omit<ChipFieldProps, 'onDelete'> {
  onDelete: (e: MouseEvent, record: any) => void;
}

const ChipFieldWithDelete: React.FC<ChipFieldWithDeleteProps> = ({ onDelete, ...rest }) => {
  const record = useRecordContext();
  return (
    <ChipField
      {...rest}
      onDelete={(e: MouseEvent) => {
        onDelete(e, record);
      }}
    />
  );
};

const EntityEdit: React.FC<{ editRelations: boolean }> = ({ editRelations }) => {
  const [doCreate]: UseCreateResult = useCreate();
  const notify = useNotify();
  const { schemata, enums, fields: fieldsByName } = useContext(HasuraContext);
  const { resource, record } = useEditController();
  const schema = schemata.get(resource) as IntrospectionObjectType;
  const label = labelFromSchema(schema);
  const fields = fieldsByName.get(resource) as Map<string, IntrospectionField>;
  const sorted: IntrospectionField[] = schema.fields
    .map(field => [
      orderFromField(
        field,
        fields.has(`${field.name}_id`)
          ? orderFromField(fields.get(`${field.name}_id`) as IntrospectionField)
          : undefined
      ),
      field.name,
    ])
    .sort()
    .map(pair => fields.get(pair[1]) as IntrospectionField);

  if (!schema) {
    return null;
  }

  return (
    <Edit
      redirect={editRelations ? `/${resource}/${record.id}/show/relations` : 'show'}
      actions={<CustomActions />}
      title={label}
      transform={async (data: any) => await transform(data, resource, record, doCreate, notify)}
    >
      <SimpleForm toolbar={<CustomToolbar editRelations={editRelations} />}>
        {sorted.map((field: IntrospectionField, i: number) => {
          if (isImplicitField(field) || isFkField(field) || isAggregateField(field) || isComputedField(field)) {
            return null;
          }

          if (isScalarField(field) && !editRelations) {
            const typeName = getFieldTypeName(field.type);
            const InputComponent = SCALARINPUTS_MAP[typeName] || TextField;
            return InputComponent === NumberInput ? (
              <NumberInput
                key={i}
                source={field.name}
                label={labelFromField(field)}
                fullWidth={true}
                min={0}
                validate={[required()]}
                helperText={false}
              />
            ) : (
              <InputComponent
                key={i}
                source={field.name}
                label={labelFromField(field)}
                fullWidth={true}
                multiline={
                  [VaultTextInput, VaultTextReadOnlyInput, VaultTextReadOnlyInputPlain].includes(InputComponent)
                    ? true
                    : null
                }
                helperText={false}
              />
            );
          } else if (isEnumField(field) && !editRelations) {
            const type_ = enums.get(getFieldTypeName(field.type));
            if (type_) {
              const choices = type_.enumValues.map((value: IntrospectionEnumValue) => {
                return {
                  id: value.name,
                  name: value.description,
                };
              });
              return <SelectInput key={i} source={field.name} choices={choices} fullWidth={true} helperText={false} />;
            } else {
              return null;
            }
          }

          if (isM2MField(field) && editRelations) {
            const intermediateResourceName = getReferenceResourceName(field);
            const intermediateResourceSchema = schemata.get(intermediateResourceName) as IntrospectionObjectType;
            const intermediateReferenceFieldName = getReferencingResourceFieldName(field, resource, schemata);

            const referenceFieldCandidates = getOtherReferenceFields(schema.name, intermediateResourceSchema);
            const referencedResourceName = getReferenceResourceName(referenceFieldCandidates[0]);
            const referencedFieldCandidates = getReferenceFields(referencedResourceName, intermediateResourceSchema);

            if (!intermediateReferenceFieldName || referencedFieldCandidates.length === 0) {
              return null;
            }

            const referencedField = getSourceResourceField(
              referencedFieldCandidates[0],
              intermediateResourceName,
              schemata
            );

            if (!referencedField) {
              return null;
            }
            const referencedResourceIdentifierColumn = getMainIdentifierColumn(referencedResourceName, schemata);

            console.log(
              `M2M ${field.name}: ${intermediateResourceName}.${intermediateReferenceFieldName} => ${intermediateResourceName}.${referencedField.name} => ${referencedResourceName}.id (${referencedResourceIdentifierColumn})`
            );

            const fieldControllerProps = {
              reference: intermediateResourceName,
              source: 'id',
              target: intermediateReferenceFieldName,
            };

            // field contents as a separate component because it needs to use a hook,
            // which isn't allowed here because we're inside a callback
            return (
              <M2MField
                {...{
                  currentResourceName: resource,
                  fieldControllerProps,
                  referencedField,
                  referencedResourceName,
                  intermediateResourceName,
                  referencedResourceIdentifierColumn,
                  record,
                }}
              />
            );
          }

          if (isM2OField(field) && editRelations) {
            const referencedResourceName = getReferenceResourceName(field);
            const referencedResourceSchema = schemata.get(referencedResourceName) as IntrospectionObjectType;
            const referenceField = getSourceResourceField(field, resource, schemata);

            if (referencedResourceName === 'camunda_User' && fields.has(`${field.name}_id`)) {
              return (
                <ReferenceInput
                  key={i}
                  label={labelFromField(fields.get(`${field.name}_id`) as IntrospectionField)}
                  source={`${field.name}_id`}
                  reference={referencedResourceName}
                  allowEmpty={true}
                  fullWidth={true}
                  helperText={false}
                >
                  <AutocompleteInput
                    filterToQuery={(q: string) => {
                      if (!q) {
                        return {};
                      }
                      return { q };
                    }}
                    optionText={'name'}
                    helperText={false}
                  />
                </ReferenceInput>
              );
            } else if (!referenceField) {
              return null;
            }

            console.log(`M2O ${field.name}: ${schema.name}.${referenceField.name} => ${referencedResourceName}.id`);

            return (
              <ReferenceInput
                key={i}
                label={labelFromField(referenceField, labelFromSchema(referencedResourceSchema))}
                source={referenceField.name}
                reference={referencedResourceName}
                allowEmpty={true}
                fullWidth={true}
                sort={{ field: 'name', order: 'ASC' }}
                helperText={false}
              >
                <AutocompleteInput
                  filterToQuery={(q: string) => {
                    if (!q) {
                      return {};
                    }
                    return {
                      name: {
                        format: 'hasura-raw-query',
                        value: {
                          _ilike: `%${q}%`,
                        },
                      },
                    };
                  }}
                  optionText={'name'}
                  helperText={false}
                />
              </ReferenceInput>
            );
          }

          if (isO2MField(field) && editRelations) {
            const referencingResourceName = getReferenceResourceName(field);
            const referencingField = getReferencingResourceField(field, resource, schemata);
            const mainIdentifierColumn = getMainIdentifierColumn(referencingResourceName, schemata);

            if (!referencingField) {
              return null;
            }

            const referencingResourceSchema = schemata.get(referencingResourceName) as IntrospectionObjectType;

            console.log(
              `O2M ${field.name}: ${referencingResourceName}.${referencingField?.name} (${mainIdentifierColumn})`
            );

            return (
              <Labeled label={labelFromSchema(referencingResourceSchema)}>
                <ReferenceManyField key={i} reference={referencingResourceName} target={referencingField.name}>
                  <SingleFieldList linkType="show">
                    <ChipField source={mainIdentifierColumn} />
                  </SingleFieldList>
                </ReferenceManyField>
              </Labeled>
            );
          }

          return null;
        })}
      </SimpleForm>
    </Edit>
  );
};

// M2M field separated into a new component so that we can use hooks
interface M2MFieldProps {
  currentResourceName: string;
  fieldControllerProps: UseReferenceManyFieldControllerParams;
  referencedField: IntrospectionField;
  referencedResourceName: string;
  intermediateResourceName: string;
  referencedResourceIdentifierColumn: string;
  record: any;
}
const M2MField: React.FC<M2MFieldProps> = props => {
  const record = useRecordContext();
  const fieldController = useReferenceManyFieldController({
    ...props.fieldControllerProps,
    record,
  });
  const [doDelete] = useDelete();
  const refresh = useRefresh();
  const classes = useStyles();

  return (
    <>
      <Labeled label={labelFromField(props.referencedField)}>
        <ListContext.Provider
          value={{
            ...fieldController,
            resource: props.referencedResourceName,
          }}
        >
          <ReferenceArrayField
            resource={props.intermediateResourceName}
            reference={props.referencedResourceName}
            record={{
              id: '', // this is a faux record for passing ids
              ids: (fieldController.data || []).map((record: any) => record[props.referencedField.name]),
            }}
            source={'ids'}
          >
            <SingleFieldList linkType="show">
              <ChipFieldWithDelete
                source={props.referencedResourceIdentifierColumn}
                onDelete={(e: MouseEvent, record: any) => {
                  e.preventDefault();
                  for (const reference of fieldController.data) {
                    if (reference[props.referencedField.name] === record.id) {
                      doDelete(
                        props.intermediateResourceName,
                        { id: reference.id },
                        {
                          onSuccess: () => {
                            refresh();
                          },
                        }
                      );
                    }
                  }
                }}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </ListContext.Provider>
      </Labeled>

      <div className={classes.relationButtons}>
        <AddEntityRelationButton {...props.fieldControllerProps} current={props.currentResourceName} />
        <AddRelatedEntityRelationButton
          {...props.fieldControllerProps}
          current={props.currentResourceName}
          referenced={props.referencedResourceName}
        />
      </div>
    </>
  );
};

export default EntityEdit;
