import gql from 'graphql-tag';
import { CreateParams, GetListParams, GetOneParams, UpdateParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

// mutation MyMutation {
//   update_camunda_ProcessInstance_correlate(messageName: "cancel", processInstanceId: "680c2105-b4f3-11eb-8af1-0242ac1f0009") {
//     businessKey
//   }
// }

interface ProcessInstanceUpdateParams extends UpdateParams {
  action: 'correlate' | 'modify';
}

const ProcessInstanceQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          mutation MyMutation($processDefinitionKey: String!) {
            insert_camunda_ProcessInstance(processDefinitionKey: $processDefinitionKey) {
              id
              businessKey
            }
          }
        `,
        variables: {
          processDefinitionKey: createParams.data.processDefinitionKey,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.insert_camunda_ProcessInstance ? response.data.insert_camunda_ProcessInstance : {},
          };
        },
      };
    case RaFetchType.GET_LIST:
    case RaFetchType.GET_MANY:
    case RaFetchType.GET_MANY_REFERENCE:
      const getListParams = params as GetListParams;
      return {
        query: gql`
          query MyQuery($firstResult: Int, $maxResults: Int) {
            camunda_ProcessInstances(firstResult: $firstResult, maxResults: $maxResults) {
              data {
                id
                businessKey
                ended
                suspended
                variables {
                  key
                  value
                  valueType
                }
                historicActivityInstances {
                  activityId
                  startTime
                  endTime
                  calledProcessInstanceId
                }
                incidents {
                  activityId
                  incidentType
                  incidentMessage
                  incidentTimestamp
                }
              }
              total
            }
          }
        `,
        variables: {
          ...getListParams.filter,
          firstResult: (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
          maxResults: getListParams.pagination.perPage,
        },
        parseResponse: (response: any) => response.data.camunda_ProcessInstances,
      };
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_ProcessInstance(id: $id) {
              id
              businessKey
              ended
              suspended
              variables {
                key
                value
                valueType
              }
              historicActivityInstances {
                activityId
                startTime
                endTime
                calledProcessInstanceId
              }
              incidents {
                activityId
                incidentType
                incidentMessage
                incidentTimestamp
              }
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_ProcessInstance,
          };
        },
      };
    case RaFetchType.UPDATE:
      const updateParams = params as ProcessInstanceUpdateParams;
      console.log(updateParams);
      return {
        query: !!updateParams.data?.businessKey
          ? gql`
              mutation MyMutation(
                $messageName: String!
                $businessKey: String!
                $processVariables: [camunda_KeyValuePairInput!]
              ) {
                camunda_ProcessInstance: update_camunda_ProcessInstance_correlate_by_key(
                  messageName: $messageName
                  businessKey: $businessKey
                  processVariables: $processVariables
                ) {
                  id
                  businessKey
                  ended
                  suspended
                  variables {
                    key
                    value
                    valueType
                  }
                  historicActivityInstances {
                    activityId
                    startTime
                    endTime
                    calledProcessInstanceId
                  }
                  incidents {
                    activityId
                    incidentType
                    incidentMessage
                    incidentTimestamp
                  }
                }
              }
            `
          : gql`
              mutation MyMutation(
                $messageName: String!
                $processInstanceId: String!
                $processVariables: [camunda_KeyValuePairInput!]
              ) {
                camunda_ProcessInstance: update_camunda_ProcessInstance_correlate(
                  messageName: $messageName
                  processInstanceId: $processInstanceId
                  processVariables: $processVariables
                ) {
                  id
                  businessKey
                  ended
                  suspended
                  variables {
                    key
                    value
                    valueType
                  }
                  historicActivityInstances {
                    activityId
                    startTime
                    endTime
                    calledProcessInstanceId
                  }
                  incidents {
                    activityId
                    incidentType
                    incidentMessage
                    incidentTimestamp
                  }
                }
              }
            `,
        variables: !!updateParams.data?.businessKey
          ? {
              businessKey: `${updateParams.data.businessKey}`,
              messageName: `${updateParams.data.messageName}`,
              processVariables: updateParams.data.processVariables || [],
            }
          : {
              processInstanceId: `${updateParams.id}`,
              messageName: `${updateParams.data.messageName}`,
              processVariables: updateParams.data.processVariables || [],
            },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_ProcessInstance || {
              id: `${updateParams.id}`,
            },
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default ProcessInstanceQuery;
