import gql from 'graphql-tag';
import { DeleteParams, GetListParams, UpdateParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const GRAPHQL_USER_TASK_LIST_ITEM = `
  id
  name
  description
  created
`;

const GRAPHQL_USER_TASK_LIST_ITEM_FULL = `
  id
  name
  description
  created
  assignee {
    id
    name
  }
  processInstanceId
  historicProcessInstance {
    startTime
  }
  processDefinition {
    id
    name
  }
`;

const UserTaskListItemQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.UPDATE:
      // TODO: This intentionally does not update, but is used to force refreshing of a list item.
      const updateParams = params as UpdateParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Task(id: $id) {
              ${GRAPHQL_USER_TASK_LIST_ITEM_FULL}
            }
          }
        `,
        variables: {
          id: `${updateParams.id}`,
        },
        parseResponse: (response: any) => {
          if (response.data.camunda_Task) {
            return {
              data: {
                ...response.data.camunda_Task,
              },
            };
          } else {
            return {
              data: deleteParams.previousData,
            };
          }
        },
      };
    case RaFetchType.DELETE:
      // TODO: This intentionally does not deleted. It exists to allow react-admin cache to clear
      // data after delete has been called. If possible, refactor this to not do a query at all.
      const deleteParams = params as DeleteParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Task(id: $id) {
              ${GRAPHQL_USER_TASK_LIST_ITEM_FULL}
            }
          }
        `,
        variables: {
          id: `${deleteParams.id}`,
        },
        parseResponse: (response: any) => {
          if (response.data.camunda_Task) {
            return {
              data: {
                ...response.data.camunda_Task,
              },
            };
          } else {
            return {
              data: deleteParams.previousData,
            };
          }
        },
      };
    case RaFetchType.GET_LIST:
    case RaFetchType.GET_MANY:
    case RaFetchType.GET_MANY_REFERENCE:
      const getListParams = params as GetListParams;
      let full = true;
      if (typeof getListParams.filter.full !== 'undefined') {
        full = !!getListParams.filter.full;
        delete getListParams.filter['full'];
      }
      return {
        query: gql`
          query MyQuery($businessKey: String, $firstResult: Int, $maxResults: Int) {
            camunda_Tasks(businessKey: $businessKey, firstResult: $firstResult, maxResults: $maxResults) {
              data {
                ${full ? GRAPHQL_USER_TASK_LIST_ITEM_FULL : GRAPHQL_USER_TASK_LIST_ITEM}
              }
              total
            }
          }
        `,
        variables: {
          ...getListParams.filter,
          firstResult: (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
          maxResults: getListParams.pagination.perPage,
        },
        parseResponse: (response: any) => response.data.camunda_Tasks,
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default UserTaskListItemQuery;
