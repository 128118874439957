import React, { useCallback } from 'react';
import {
  CreateContextProvider,
  FileField,
  FileInput,
  SimpleForm,
  Title,
  TopToolbar,
  useCreateController,
  useTranslate,
} from 'react-admin';

import BackButton from '../../../Components/BackButton';
import { DMN } from '../../../DataProviders/Camunda/helpers';
import { FileContainer, convertFileToText } from '../../../util/helpers';

interface Resource {
  name: string;
  payload: string;
}

const CustomCreate: React.FC<{ title: string }> = props => {
  const createControllerProps = useCreateController();
  const { defaultTitle, save } = createControllerProps;

  const saveWithFile: typeof save = useCallback(
    async (data, callbacks) => {
      if (!save) return;
      let deploymentName = null;
      const resources: Resource[] = await Promise.all(
        data.resources.map(async (file: FileContainer) => {
          const name = file.rawFile.name;
          const payload = await convertFileToText(file);
          if (name.endsWith('.dmn')) {
            const dmn = await DMN(payload);
            for (const drgElement of dmn.rootElement.drgElement) {
              if (drgElement && drgElement.name) {
                deploymentName = drgElement.name;
                if (drgElement.$type === 'dmn:Decision') {
                  break;
                }
              }
            }
          }
          return { name, payload };
        })
      );
      if (deploymentName === null && resources.length > 0) {
        deploymentName = resources[0].name;
      }
      return await save(
        {
          name: deploymentName,
          resources,
        },
        callbacks
      );
    },
    [save]
  );

  return (
    <CreateContextProvider value={{ ...createControllerProps, save: saveWithFile }}>
      <div>
        <TopToolbar>
          <BackButton />
        </TopToolbar>
        <Title title={props.title || defaultTitle} />
        {props.children}
      </div>
    </CreateContextProvider>
  );
};

const DecisionDefinitionCreate: React.FC<{}> = () => {
  const translate = useTranslate();
  return (
    <CustomCreate title={translate('vasara.page.deployDecisionDefinition')}>
      <SimpleForm>
        <FileInput source="resources" label="vasara.page.decisionDeploymentFiles" multiple={true}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </CustomCreate>
  );
};

export default DecisionDefinitionCreate;
