import { CreateParams } from 'ra-core';
import buildDataProvider, { BuildQueryFactory, BuildQueryResult } from 'ra-data-graphql';

import { ActionsHasuraClient } from '../HasuraClient';
import { RaFetchType } from '../types';
import GuestProcessDefinitionQuery from './GuestProcessDefinitionQuery';
import GuestProcessInstanceQuery from './GuestProcessInstanceQuery';
import VaultTransitDecryptQuery from './VaultTransitDecryptQuery';
import VaultTransitEncryptQuery from './VaultTransitEncryptQuery';
import XlsxExportQuery from './XlsxExportQuery';
import XlsxImportQuery from './XlsxImportQuery';

const buildQuery: BuildQueryFactory = (introspectionResults: any) => (
  // string for type compatibility, converted later to RaFetchType enum
  raFetchType_: string,
  resource: string,
  params: CreateParams
): BuildQueryResult => {
  const raFetchType = raFetchType_ as RaFetchType;
  switch (resource) {
    case 'xlsx_import':
      return XlsxImportQuery(introspectionResults, raFetchType, resource, params);
    case 'xlsx_export':
      return XlsxExportQuery(introspectionResults, raFetchType, resource, params);
    case 'vault_transit_encrypt':
      return VaultTransitEncryptQuery(introspectionResults, raFetchType, resource, params);
    case 'vault_transit_decrypt':
      return VaultTransitDecryptQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_guest_ProcessDefinition':
      return GuestProcessDefinitionQuery(introspectionResults, raFetchType, resource, params);
    case 'insert_camunda_guest_ProcessInstance':
      return GuestProcessInstanceQuery(introspectionResults, raFetchType, resource, params);
    default:
      throw new Error(`Unknown resource ${resource}.`);
  }
};

const provider = async () => {
  return await buildDataProvider({
    buildQuery,
    // @ts-ignore TS somehow gets confused by ApolloClient<T> where T != unknown
    client: ActionsHasuraClient,
  });
};

export default provider;
