import BuildIcon from '@mui/icons-material/Build';
import { ResourceProps } from 'ra-core';

import EntitySettingsFormEdit from './EntitySettingsFormEdit';

const resource: ResourceProps = {
  name: 'vasara_entity_settings',
  edit: EntitySettingsFormEdit,
  icon: BuildIcon,
  options: { hideFromMenu: true },
};

export default resource;
