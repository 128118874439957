import Timeline from '@mui/icons-material/Timeline';
import { ResourceProps } from 'react-admin';

import messages from '../../../messages';
import ProcessInstanceCreate from './ProcessInstanceCreate';
import ProcessInstanceList from './ProcessInstanceList';

const resource: ResourceProps = {
  name: 'ProcessInstance',
  create: ProcessInstanceCreate,
  list: ProcessInstanceList,
  icon: Timeline,
  options: {
    label: messages.translate('vasara.resource.processInstance'),
  },
};

export default resource;
