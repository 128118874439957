import BpmnJS from 'bpmn-js';
import copyPaste from 'bpmn-js/lib/features/copy-paste';
import modeling from 'bpmn-js/lib/features/modeling';
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import { UserTask } from 'bpmn-moddle';
import camundaExtensionModule from 'camunda-bpmn-moddle/lib';
import camundaModdle from 'camunda-bpmn-moddle/resources/camunda.json';
import camundaDmnExtensionModule from 'camunda-dmn-moddle';
import camundaDmnModdle from 'camunda-dmn-moddle/resources/camunda.json';
import tooltips from 'diagram-js/lib/features/tooltips';
import DmnViewer from 'dmn-js/lib/NavigatedViewer';
import DmnModdle from 'dmn-moddle';

import robotExtensionModule from './RobotModule';

export const BPMN = async (diagram: string) => {
  const model = new BpmnJS({
    additionalModules: [copyPaste, modeling, camundaExtensionModule, tooltips],
    moddleExtensions: {
      camunda: camundaModdle,
    },
  });
  try {
    await model.importXML(diagram);
  } catch (e) {
    // nothing we can do
  }
  return model;
};

export const DMN = async (diagram: string) => {
  let model;
  const moddle = new DmnModdle({ camunda: camundaDmnModdle });
  try {
    model = await moddle.fromXML(diagram, 'dmn:Definitions');
  } catch (e) {
    // nothing we can do
  }
  return model;
};

export const DMNViewer = async (diagram: string) => {
  const model = new DmnViewer({
    additionalModules: [camundaDmnExtensionModule],
    moddleExtensions: {
      camunda: camundaDmnModdle,
    },
  });
  try {
    await model.importXML(diagram);
  } catch (e) {
    // nothing we can do
  }
  return model;
};

export const BPMNViewer = async (diagram: string) => {
  const model = new BpmnViewer({
    additionalModules: [copyPaste, modeling, camundaExtensionModule, robotExtensionModule, tooltips],
    moddleExtensions: {
      camunda: camundaModdle,
    },
  });
  try {
    await model.importXML(diagram);
  } catch (e) {
    // nothing we can do
  }
  return model;
};

export const getUserTaskEntities = (task: UserTask): string[] => {
  const entities: string[] = [];
  for (let association of (task?.dataInputAssociations || []).concat(task?.dataOutputAssociations || [])) {
    if (association?.targetRef?.extensionElements?.values?.length) {
      for (let value of association.targetRef.extensionElements.values) {
        if ((value as any).$type === 'camunda:Properties') {
          for (let property of (value as any).values) {
            if (property.name === 'entity') {
              entities.push(property.value);
            }
          }
        }
      }
    }
  }
  return entities;
};

export const getUserTaskForm = (task: UserTask): any[] => {
  if (task?.extensionElements?.values?.length) {
    for (let i = 0; i < task.extensionElements.values.length; i++) {
      const formData = task.extensionElements.values[i];
      if ((formData as any).$type === 'camunda:FormData') {
        return (formData as any).fields || [];
      }
    }
  }
  return [];
};

export const getAllMessages = (model: any) => {
  const definitions = model._definitions;
  const messages = definitions.rootElements
    .filter((element: any) => {
      return element.$type === 'bpmn:Message';
    })
    .sort((a: any, b: any) => {
      if ((a.id || a.name) < (b.id || b.name)) {
        return -1;
      } else if ((a.id | a.name) > (b.id || b.name)) {
        return 1;
      } else {
        return 0;
      }
    });
  return messages;
};

export const getAllUserTasks = (model: any) => {
  const registry = model.get('elementRegistry');
  const tasks = registry
    .filter((element: any) => {
      return element.type === 'bpmn:UserTask';
    })
    .map((element: any) => {
      return element.businessObject;
    })
    .sort((a: any, b: any) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
  return tasks;
};

export const getUserTask = (model: any, id: string) => {
  const registry = model.get('elementRegistry');
  const element = registry.get(id);
  return element ? element.businessObject : null;
};

export const getAllDataObjectReferences = (model: any) => {
  const registry = model.get('elementRegistry');
  return registry
    .filter((element: any) => {
      return element.type === 'bpmn:DataObjectReference';
    })
    .map((element: any) => {
      return element.businessObject;
    });
};

export const getAllEntities = (model: any) => {
  const objects = getAllDataObjectReferences(model);
  const entities = [];
  for (let obj of objects) {
    if (obj?.extensionElements?.values?.length) {
      for (let value of obj.extensionElements.values || []) {
        if ((value as any).$type === 'camunda:Properties') {
          for (let property of (value as any).values) {
            if (property.name === 'entity') {
              entities.push(property.value);
            }
          }
        }
      }
    }
  }
  return entities;
};

export const getAllVariables = (model: any): any[] => {
  const registry = model.get('elementRegistry');
  const businessObjects = registry.getAll().map((element: any) => {
    return element.businessObject;
  });
  const tasks = businessObjects.filter((businessObject: any) => {
    return businessObject.$type === 'bpmn:UserTask' || businessObject.$type === 'bpmn:StartEvent';
  });
  const variables: Map<string, any> = new Map();
  for (const task of tasks) {
    const form = getUserTaskForm(task);
    for (const field of form) {
      variables.set(field.id, field);
    }
  }
  for (const businessObject of businessObjects) {
    if (businessObject?.extensionElements?.values?.length) {
      for (const extensionElement of businessObject.extensionElements.values) {
        if (extensionElement.$type === 'camunda:InputOutput') {
          for (const outputParameter of extensionElement.outputParameters || []) {
            if (!variables.has(outputParameter.name)) {
              variables.set(outputParameter.name, {
                id: outputParameter.name,
                label: outputParameter.name,
                type: 'any',
              });
            }
          }
          for (const inputParameter of extensionElement.inputParameters || []) {
            if (!variables.has(inputParameter.name)) {
              variables.set(inputParameter.name, {
                id: inputParameter.name,
                label: inputParameter.name,
                type: 'any',
              });
            }
          }
        }
      }
    }
    if (businessObject.resultVariable && !variables.has(businessObject.resultVariable)) {
      variables.set(businessObject.resultVariable, {
        id: businessObject.resultVariable,
        label: businessObject.resultVariable,
        type: 'any',
      });
    }
  }
  const result = Array.from(variables.values());
  result.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
  return result;
};

export const arrayMove = (array: any[], from: number, to: number) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};
