import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import { useLocaleState } from 'react-admin';
import { v4 as uuid } from 'uuid';

type Props = {
  appendField: (field: { id: string; label: { [K: string]: string }; fields: any[] }) => void;
  expand: () => void;
};

const AddFieldsetInput: React.FC<Props> = props => {
  const [fieldsetName, setFieldsetName] = useState<string>('');
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const htmlId = uuid();

  const addNewFieldset = (): boolean => {
    if (fieldsetName !== '') {
      props.appendField({ label: { [locale]: fieldsetName }, fields: [], id: uuid() });
      setFieldsetName('');
      return true;
    }
    return false;
  };

  const handleNewFieldsetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldsetName(event.target.value);
  };

  return (
    <FormControl fullWidth size="small" variant="outlined" style={{ marginBottom: '1rem' }}>
      <InputLabel htmlFor={htmlId}>{translate('vasara.form.helperText.fieldset')}</InputLabel>
      <OutlinedInput
        type="text"
        inputProps={{ id: htmlId }}
        value={fieldsetName}
        onChange={handleNewFieldsetNameChange}
        onKeyPress={e => e.key === 'Enter' && addNewFieldset() && props.expand()}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="add fieldset" onClick={addNewFieldset} disabled={fieldsetName === ''}>
              <AddCircleIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default AddFieldsetInput;
