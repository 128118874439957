import gql from 'graphql-tag';
import { GetListParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const ProcessInstanceUserTaskQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_LIST:
      const getListParams = params as GetListParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_ProcessInstance(id: $id) {
              tasks: tasksByBusinessKey {
                id
                taskDefinitionKey
              }
            }
          }
        `,
        variables: { id: getListParams.filter.id },
        parseResponse: (response: any) => {
          const instance = response.data.camunda_ProcessInstance;
          let tasks = instance?.tasks ?? [];
          tasks.sort((a: any, b: any) => (a.taskDefinitionKey > b.taskDefinitionKey ? 1 : -1));
          return {
            data: tasks.slice(
              (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
              getListParams.pagination.page * getListParams.pagination.perPage
            ),
            total: tasks.length,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default ProcessInstanceUserTaskQuery;
