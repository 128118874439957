import gql from 'graphql-tag';
import { GetManyReferenceParams, Identifier } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const HistoricActivityInstanceQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_MANY_REFERENCE:
      const getManyReferenceParams = params as GetManyReferenceParams;
      const variables: Record<string, Identifier> = {};
      variables[getManyReferenceParams.target] = getManyReferenceParams.id;
      return {
        query: gql`
          query MyQuery($businessKey: String, $processInstanceIds: [String]) {
            camunda_HistoricActivityInstances(businessKey: $businessKey, processInstanceIds: $processInstanceIds) {
              id
              activityName
              assignee {
                name
              }
              activityId
              activityType
              endTime
              processDefinition {
                name
              }
            }
          }
        `,
        variables,
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_HistoricActivityInstances,
            total: response.data.camunda_HistoricActivityInstances.length,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default HistoricActivityInstanceQuery;
