import gql from 'graphql-tag';
import { GetManyReferenceParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { BPMN, getAllEntities, getAllMessages, getAllUserTasks, getAllVariables, getUserTask } from './helpers';
import { CamundaQueryBuilder } from './types';

const ProcessDefinitionUserTaskQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_MANY_REFERENCE:
      const params_ = params as GetManyReferenceParams;
      if (!params_.id) {
        throw new Error('Missing parameter "id".');
      }
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_ProcessDefinition(id: $id) {
              id
              key
              name
              diagram
              version
            }
          }
        `,
        variables: { id: params_.id },
        parseResponse: (response: any) =>
          new Promise(async (resolve, reject) => {
            const { id, key, name, version, diagram } = response.data.camunda_ProcessDefinition;
            const model = await BPMN(diagram);
            const tasks = getAllUserTasks(model);
            const messages = getAllMessages(model);
            resolve({
              data: tasks.map((task: any) => {
                return {
                  ...task,
                  processDefinition: {
                    id,
                    key,
                    name,
                    version,
                  },
                  messages,
                };
              }),
              total: tasks.length,
            });
          }),
      };
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return {
        query: getOneParams.id
          ? gql`
              query MyQuery($id: String!) {
                camunda_ProcessDefinition(id: $id) {
                  id
                  key
                  name
                  diagram
                  version
                }
              }
            `
          : gql`
              query MyQuery($key: String!, $version: Int!) {
                camunda_ProcessDefinitions(key: $key, version: $version) {
                  data {
                    id
                    key
                    name
                    diagram
                    version
                  }
                }
              }
            `,
        variables: getOneParams.id
          ? {
              id: `${getOneParams.id}`,
            }
          : {
              key: `${(getOneParams as any).key}`,
              version: (getOneParams as any).version,
            },
        parseResponse: async (response: any) => {
          const { id, key, name, version, diagram } = response.data.camunda_ProcessDefinitions?.data.length
            ? response.data.camunda_ProcessDefinitions.data[0]
            : response.data.camunda_ProcessDefinition;
          const model = await BPMN(diagram);
          const task = getUserTask(model, params.meta.filter.user_task_id);
          const messages = await getAllMessages(model);
          const entities = getAllEntities(model);
          const variables = getAllVariables(model);
          return {
            data: {
              ...task,
              processDefinition: {
                id,
                key,
                name,
                version,
                entities: entities,
                variables: variables,
              },
              messages: messages,
            },
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default ProcessDefinitionUserTaskQuery;
