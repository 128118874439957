import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslate } from 'ra-core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  TopToolbar,
  useLocaleState,
} from 'react-admin';
import { DragDropContext, Draggable, Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import BackButton, { BackButtonProps } from '../Components/BackButton';
import { getUserTaskForm } from '../DataProviders/Camunda/helpers';
import UserTaskEditContext from '../DataProviders/Camunda/UserTaskEditContext';
import HasuraContext from '../DataProviders/HasuraContext';
import { localeChoices } from '../messages';
import { getSourceChoices, labelFromSchema } from '../util/helpers';
import AddFieldsetInput from './Fieldsets/AddFieldsetInput';
import Fieldset from './Fieldsets/Fieldset';
import Preview from './Preview';
import { Choice } from './types';

interface ContainerProps {
  title: string;
  backButtonProps?: BackButtonProps;
  messageChoices: Choice[];
  sourceChoices: Choice[];
  readonlySourceChoices: Choice[];
  vocabularyChoices: Choice[];
}

const getFieldsetDropStyle = (snapshot: DroppableStateSnapshot) => ({
  background: snapshot.isDraggingOver ? '#edf2f7' : snapshot.draggingFromThisWith ? '#ecc0c0' : 'inherit',
  borderRadius: '4px',
  paddingBottom: '10px',
  width: 'auto',
});

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
});

const FormBuilderContainer: React.FC<ContainerProps> = props => {
  const form = useFormContext();
  const fieldsName = 'schema';
  const { fields, remove: removeField, move: moveField, append: appendField } = useFieldArray({
    name: fieldsName,
    control: form.control,
  });
  const [expanded, setExpanded] = useState<string>(`${fieldsName}[0]`);
  const expandLast = () => setExpanded(`${fieldsName}[${fields.length}]`);
  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        removeField(result.source.index);
      } else if (result.source.index === result.destination.index) {
        return;
      } else {
        moveField(result.source.index, result.destination.index);
      }
    },
    [moveField, removeField]
  );
  const [locale] = useLocaleState();
  const toolbarRef = useRef();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <TopToolbar>
        <BackButton className={classes.mr} {...(props.backButtonProps ?? {})} />
        <Preview title={props.title} toolbarRef={toolbarRef} />
      </TopToolbar>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className="VasaraForm-root VasaraForm-builder"
      >
        <Grid item xs={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="fieldset">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getFieldsetDropStyle(snapshot)}>
                  {fields.map((field, index) => (
                    <Draggable key={field.id} draggableId={field.id} index={index}>
                      {(provided, snapshot) => (
                        <Fieldset
                          fieldsetName={`${fieldsName}[${index}]`}
                          provided={provided}
                          snapshot={snapshot}
                          expanded={expanded}
                          setExpanded={setExpanded}
                          messageChoices={props.messageChoices}
                          vocabularyChoices={props.vocabularyChoices}
                          sourceChoices={props.sourceChoices}
                          readonlySourceChoices={props.readonlySourceChoices}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid item xs={11}>
          <AddFieldsetInput appendField={appendField} expand={expandLast} />
        </Grid>
        <Grid item xs={11}>
          <Accordion elevation={0} variant={'outlined'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{translate('vasara.form.formSettingsLabel')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <SelectInput
                label="vasara.form.language"
                source={`settings.language`}
                choices={localeChoices.concat([{ id: 'all', name: translate('vasara.form.multilingual') }])}
                defaultValue={'all'}
                fullWidth={true}
                helperText={false}
              />
              <BooleanInput
                label="vasara.form.customSaveAndSubmit"
                helperText="vasara.form.customSaveAndSubmitHelp"
                source={`settings.saveAndSubmit.customize`}
                defaultValue={false}
                fullWidth={true}
              />
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData }: any) => {
                  return formData?.settings?.saveAndSubmit?.customize ? (
                    <>
                      <TextInput
                        label="vasara.form.customSaveAndSubmitLabel"
                        source={`settings.saveAndSubmit.label.${locale}`}
                        defaultValue={translate('vasara.action.saveAndSubmit')}
                        fullWidth={true}
                        helperText={false}
                      />

                      <TextInput
                        label="vasara.form.customSaveAndSubmitSuccessMessage"
                        source={`settings.saveAndSubmit.helperText.${locale}`}
                        defaultValue={translate('ra.notification.updated', { smart_count: 1 })}
                        fullWidth={true}
                        helperText={false}
                      />

                      <TextInput
                        label="vasara.form.customSaveAndSubmitLoadingPrimaryMessage"
                        source={`settings.saveAndSubmit.loadingPrimary.${locale}`}
                        defaultValue={translate('ra.page.loading')}
                        fullWidth={true}
                        helperText={false}
                      />

                      <TextInput
                        label="vasara.form.customSaveAndSubmitLoadingSecondaryMessage"
                        source={`settings.saveAndSubmit.loadingSecondary.${locale}`}
                        defaultValue={translate('ra.message.loading')}
                        fullWidth={true}
                        helperText={false}
                      />
                    </>
                  ) : null;
                }}
              </FormDataConsumer>
              <NumberInput
                label="vasara.form.waitForNextTask"
                source="settings.waitForNextTask"
                helperText="vasara.form.waitForNextTaskHelp"
                defaultValue={false}
                format={v =>
                  String(
                    Math.max(
                      0,
                      typeof v === 'undefined' || v === null || v === false ? 2 : v === true ? 15 : parseInt(v, 10)
                    )
                  )
                }
                parse={v =>
                  Math.max(
                    0,
                    typeof v === 'undefined' || v === null || v === false ? 2 : v === true ? 15 : parseInt(v, 10)
                  )
                }
                fullWidth={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export interface FormBuilderProps {
  title: string;
  backButtonProps?: BackButtonProps;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ title, backButtonProps }) => {
  const translate = useTranslate();
  const context = useContext(UserTaskEditContext);
  const { introspection, vocabularies } = useContext(HasuraContext);
  const userTaskForm = getUserTaskForm(context.userTask);
  const entitySourceChoices = getSourceChoices(introspection, context.userTask.processDefinition.entities);
  const messageChoices = (context.userTask.messages || []).map(message => {
    return {
      id: message.name,
      name: message.name,
    };
  });
  const mutableSourceChoices = userTaskForm
    .map((field: any) => {
      return {
        id: `context.${field.id}`,
        name: `${translate('vasara.form.process')}: ${field.id} (${field.type})`,
      };
    })
    .concat(entitySourceChoices);
  const readonlySourceChoices = (context.userTask.processDefinition?.variables || [])
    .map((field: any) => {
      return {
        id: `context.${field.id}`,
        name: `${translate('vasara.form.process')}: ${field.id} (${field.type})`,
      };
    })
    .concat(entitySourceChoices);
  const vocabularyChoices = [
    { id: 'camunda_User', name: translate('vasara.vocabulary.user') },
    { id: 'camunda_Group', name: translate('vasara.vocabulary.group') },
  ].concat(
    Array.from(vocabularies.values()).map(type => {
      return {
        id: type.name,
        name: labelFromSchema(type),
      };
    })
  );
  vocabularyChoices.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <FormBuilderContainer
      title={title ? title : context.userTask.name || ''}
      backButtonProps={backButtonProps}
      messageChoices={messageChoices}
      vocabularyChoices={vocabularyChoices}
      sourceChoices={mutableSourceChoices}
      readonlySourceChoices={readonlySourceChoices}
    />
  );
};

export default FormBuilder;
