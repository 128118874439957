import React from 'react';
import { Admin, Loading, Resource } from 'react-admin';
import { CustomRoutes } from 'react-admin';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';

import { GuestForm } from './GuestForm';
import GuestLayout from './Layout/GuestLayout';
import i18nProvider, { DEFAULT_LOCALE, resolveLocale } from './messages';
import { IAppState } from './types';
import { theme } from './util/theme';

export const GuestApp: React.FC<IAppState> = props => {
  const { mainDataProvider } = props;
  if (!mainDataProvider) {
    return <Loading loadingPrimary={''} loadingSecondary={'Loading...'} />;
  }
  return (
    <Admin
      theme={theme}
      authProvider={undefined}
      loginPage={undefined}
      i18nProvider={i18nProvider}
      layout={GuestLayout}
      dashboard={undefined}
      disableTelemetry
      dataProvider={mainDataProvider}
    >
      <Helmet
        htmlAttributes={{ lang: resolveLocale(DEFAULT_LOCALE) }}
        title={`Vasara — ${i18nProvider.translate('vasara.ui.subtitle')}`}
      />
      <Resource name="camunda_guest_ProcessDefinition" options={{ hideFromMenu: true }} />
      <Resource name="camunda_insert_guest_ProcessInstance" options={{ hideFromMenu: true }} />
      <CustomRoutes>
        <Route path="/guest" element={<GuestForm />} />,
        <Route path="/guest/" element={<GuestForm />} />,
        <Route path="/guest/:needle" element={<GuestForm />} />,
      </CustomRoutes>
    </Admin>
  );
};
