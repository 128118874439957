import React from 'react';
import { Button, ButtonProps, RedirectionSideEffect, useRedirect } from 'react-admin';
import { useNavigate } from 'react-router';

export type CancelButtonProps = ButtonProps & {
  // Address to redirect to when the button if pressed.
  // If not given, navigates one entry back in history instead.
  redirect?: RedirectionSideEffect;
};

const CancelButton: React.FC<CancelButtonProps> = props => {
  const navigate = useNavigate();
  const redirect = useRedirect();
  return (
    <Button
      {...props}
      size="small"
      variant="text"
      label="vasara.action.exitForm"
      onClick={() => {
        if (props.redirect) {
          redirect(props.redirect);
        } else {
          navigate(-1);
        }
      }}
    />
  );
};

export default CancelButton;
