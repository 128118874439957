import gql from 'graphql-tag';
import { CreateParams, CreateResult } from 'ra-core';
import { BuildQueryResult } from 'ra-data-graphql';
import { v4 as uuid } from 'uuid';

import { RaFetchType } from '../types';
import { ActionsQueryBuilder } from './types';

const XlsxImportQuery: ActionsQueryBuilder = (
  introspectionResults,
  raFetchType,
  resource,
  params
): BuildQueryResult => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          query($base64: String!) {
            xlsx_import(base64: $base64) {
              sheets
            }
          }
        `,
        variables: {
          base64: createParams.data.base64,
        },
        parseResponse: (response: any): CreateResult => {
          return {
            data: {
              id: uuid(),
              ...response.data.xlsx_import,
            },
            // @ts-ignore: react-admin types are incomplete, validUntil is used for caching
            validUntil: new Date(new Date().getTime() + 60000), // a minute
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default XlsxImportQuery;
