import React from 'react';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

const CommentShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="body" />
    </SimpleShowLayout>
  </Show>
);

export default CommentShow;
