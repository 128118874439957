import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import * as React from 'react';
import { DateField, Labeled, NumberField, TextField, useRecordContext } from 'react-admin';

import VaultTextFieldPlain from './VaultTextFieldPlain';
import YesNoField from './YesNoField';

const useStyles = makeStyles({
  multiline: {
    whiteSpace: 'pre-line',
  },
  nomargin: {
    margin: 0,
    padding: 0,
  },
  inline: {
    display: 'inline-block',
    marginRight: '1em',
  },
});

const MultipleSelectField: React.FC<{ source: string }> = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <ul className={classes.nomargin}>
      {(get(record, source) || []).map((item: any, idx: number) => (
        <li key={item} className={classes.inline}>
          {typeof item === 'object' ? (
            <div style={{ marginLeft: '1rem' }}>
              <JSONBField source={`${source}[${idx}]`} />
            </div>
          ) : (
            <Typography variant="body2">{}</Typography>
          )}
        </li>
      ))}
    </ul>
  );
};

const getFieldComponent = (value: any): any => {
  if (value === null) {
    return null;
  }
  if (Array.isArray(value)) {
    return MultipleSelectField;
  }
  switch (typeof value) {
    case 'object':
      return JSONBField;
    case 'boolean':
      return YesNoField;
    case 'number':
      return NumberField;
    case 'string':
      if (value === 'true' || value === 'false') {
        return YesNoField;
      } else if (value.match(/^\d{4}-\d{2}-\d{2}T?.*/)) {
        return DateField;
      } else if (value.startsWith('vault:')) {
        return VaultTextFieldPlain;
      } else {
        return TextField;
      }
    default:
      return TextField;
  }
};

const JSONBField: React.FC<{ source: string; label?: string; [K: string]: any }> = ({ source, label, ...rest }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const content = get(record, source) || {};
  const keys = content['@order'] || Object.keys(content).sort((a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0));
  const component = rest.component || 'div';
  return Array.isArray(content) ? (
    <Labeled label={label}>
      <table>
        {content.map((value: any, idx: number) => (
          <tr key={idx}>
            <JSONBField source={`${source}[${idx}]`} {...rest} component="td" />
          </tr>
        ))}
      </table>
    </Labeled>
  ) : (
    <>
      {keys
        .filter((key: string) => key !== '@order')
        .map((key: string) => {
          const FieldComponent = getFieldComponent(content[key]);
          return FieldComponent === null
            ? null
            : FieldComponent === TextField
            ? React.createElement(component, {
                key,
                children:
                  component !== 'td' ? (
                    <Labeled label={key} source={`${source}.${key}`}>
                      <FieldComponent source={`${source}.${key}`} label={key} {...rest} className={classes.multiline} />
                    </Labeled>
                  ) : (
                    <FieldComponent source={`${source}.${key}`} label={key} {...rest} className={classes.multiline} />
                  ),
              })
            : FieldComponent === NumberField
            ? React.createElement(component, {
                key,
                children:
                  component !== 'td' ? (
                    <Labeled label={key} source={`${source}.${key}`}>
                      <NumberField source={`${source}.${key}`} label={key} {...rest} locales={['fi']} />
                    </Labeled>
                  ) : (
                    <NumberField source={`${source}.${key}`} label={key} {...rest} locales={['fi']} />
                  ),
              })
            : React.createElement(component, {
                key,
                children:
                  component !== 'td' ? (
                    <Labeled label={key} source={`${source}.${key}`}>
                      <FieldComponent source={`${source}.${key}`} label={key} {...rest} />
                    </Labeled>
                  ) : (
                    <FieldComponent source={`${source}.${key}`} label={key} {...rest} />
                  ),
              });
        })}
    </>
  );
};

JSONBField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default JSONBField;
