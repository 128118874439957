import React from 'react';
import { RadioButtonGroupInput, choices, required } from 'react-admin';

const YesNoInput: React.FC = (props: any) => {
  return (
    <RadioButtonGroupInput
      {...props}
      validate={
        props.required
          ? [required(), choices([true, false], 'ra.validation.required')]
          : [choices([true, false], 'ra.validation.required')]
      }
      choices={[
        { id: true, name: 'vasara.form.yes' },
        { id: false, name: 'vasara.form.no' },
      ]}
      fullWidth={props.fullWidth ?? true}
    />
  );
};

export default YesNoInput;
