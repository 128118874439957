import AddIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { evaluate } from 'feelin';
import get from 'lodash/get';
import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  RadioButtonGroupInput,
  RecordContextProvider,
  SelectInput,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextInput,
  Validator,
  required,
  useLocaleState,
  useRecordContext,
} from 'react-admin';
import { useArrayInput } from 'react-admin';
import { ButtonProps, IconButtonWithTooltip } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';

import FeelInput from '../../Components/FeelInput';
import { XlsxExportLink } from '../../Components/XlsxExportLink';
import { XlsxImportLink } from '../../Components/XlsxImportLink';
import { unary } from '../../util/feel';
import { FieldTypes } from '../constants';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponent, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { Choice } from '../types';

const TableField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const { sourceChoices, readonlySourceChoices } = props;
  const combinedChoices = props.fieldChoices.concat(readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  // current form values used to determine visibility of fields
  const form = useFormContext();
  const hasDynamicColumns = !!form.watch(`${props.inputName}.dynamicColumnsSource`);
  const fixedColumns = form.watch(`${props.inputName}.fields`);
  const hasFixedColumns = fixedColumns !== undefined && fixedColumns.length > 0;

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={[required()]}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      {hasDynamicColumns ? null : (
        <ArrayInput
          className="VasaraTableInputIterator"
          source={`${props.inputName}.fields`}
          label="vasara.form.columns"
        >
          <SimpleFormIterator inline>
            <SelectInput
              source={`type`}
              label="vasara.form.type"
              choices={EnabledFieldTypesChoices.filter((choice: Choice) =>
                [FieldTypes.STRING, FieldTypes.NUMBER, FieldTypes.BOOLEAN, FieldTypes.DATE, FieldTypes.SELECT].includes(
                  choice.id
                )
              )}
              validate={validateRequired}
              helperText={false}
            />
            <TextInput source={`id`} label="vasara.form.id" validate={validateRequired} helperText={false} />
            <TextInput
              source={`label.${locale}`}
              label="vasara.form.label"
              validate={validateRequired}
              helperText={false}
            />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, getSource, ...rest }) => {
                if (!getSource) return;
                const type = get(formData, getSource('type')) || '';
                const behavior = getSource('behavior');
                const decimals = getSource('decimals');
                const fullWidth = getSource('fullWidth');
                const multiline = getSource('multiline');
                const readonly = getSource('readonly');
                const required = getSource('required');
                const vocabulary = getSource('vocabulary');
                const varSource = getSource('variableSource');
                const width = getSource('width');
                const inputType = getSource('inputType');
                const isReadonly = get(formData, getSource('readonly')) || false;
                const isFullwidth = get(formData, getSource('fullWidth')) || false;
                const hasVocabulary = !!get(formData, vocabulary);
                const hasVarSource = !!get(formData, varSource);
                const hideExpression = getSource('conditional.hide');

                const fields = getSource('').replace(/\.[0-9]+\.$/, '');
                const feelVariables = get(formData, fields).map((f: any) => {
                  return { name: f.id };
                });

                const choices = !hasVocabulary
                  ? props.readonlySourceChoices
                      // only list process variables
                      .filter((src: Choice) => src.id.startsWith('context'))
                      // remove the "context." prefix
                      .map(
                        (src: Choice): Choice => {
                          return { id: src.id.substring(8), name: src.name };
                        }
                      )
                  : [];

                if (type === FieldTypes.SELECT && vocabulary) {
                  return (
                    <>
                      {!hasVarSource && (
                        <AutocompleteInput
                          label="vasara.form.vocabulary"
                          source={vocabulary}
                          choices={props.vocabularyChoices}
                          helperText={false}
                          fullWidth={false}
                          validate={validateRequired}
                        />
                      )}
                      {!hasVocabulary && (
                        <AutocompleteInput
                          label="vasara.form.variableSource"
                          source={varSource}
                          choices={choices}
                          helperText={false}
                          fullWidth={false}
                          validate={validateRequired}
                        />
                      )}
                      <FeelInput
                        label="vasara.column.hideExpression"
                        source={hideExpression}
                        defaultValue=""
                        fullWidth={false}
                        variables={feelVariables}
                      />
                      <BooleanInput
                        source={readonly}
                        label="vasara.form.readonly"
                        helperText={false}
                        defaultValue={false}
                      />
                      {!isFullwidth && (
                        <NumberInput
                          sx={{ width: '50px' }}
                          source={width}
                          label="vasara.form.width"
                          helperText={false}
                          defaultValue={0}
                        />
                      )}
                      <BooleanInput
                        source={fullWidth}
                        label="vasara.form.fullWidth"
                        helperText={false}
                        defaultValue={false}
                      />
                      <BooleanInput
                        source={required}
                        label="ra.validation.required"
                        helperText={false}
                        defaultValue={false}
                      />
                    </>
                  );
                }

                if (type === FieldTypes.NUMBER) {
                  return (
                    <>
                      <SelectInput
                        id={`${props.inputName}-behavior`}
                        label="vasara.form.behavior"
                        helperText={false}
                        source={behavior}
                        defaultValue="default"
                        choices={[
                          {
                            id: 'default',
                            name: 'vasara.form.integerBehavior.default',
                          },
                          {
                            id: 'percentage',
                            name: 'vasara.form.integerBehavior.percentage',
                          },
                          {
                            id: 'euro',
                            name: 'vasara.form.integerBehavior.euro',
                          },
                        ]}
                        validate={validateRequired}
                        fullWidth={false}
                      />
                      <NumberInput
                        id={`${props.inputName}-decimals`}
                        label="vasara.form.decimals"
                        helperText={false}
                        source={decimals}
                        format={(v: number) => round(v, 0)}
                        parse={(v: string) => round(parseFloat(v), 0)}
                        defaultValue={0}
                      />
                      <FeelInput
                        label="vasara.column.hideExpression"
                        source={hideExpression}
                        defaultValue=""
                        fullWidth={false}
                        variables={feelVariables}
                      />
                      <BooleanInput
                        source={readonly}
                        label="vasara.form.readonly"
                        helperText={false}
                        defaultValue={false}
                      />
                      {!isFullwidth && (
                        <NumberInput
                          sx={{ width: '50px' }}
                          source={width}
                          label="vasara.form.width"
                          helperText={false}
                          defaultValue={0}
                        />
                      )}
                      <BooleanInput
                        source={fullWidth}
                        label="vasara.form.fullWidth"
                        helperText={false}
                        defaultValue={false}
                      />
                      <BooleanInput
                        source={required}
                        label="ra.validation.required"
                        helperText={false}
                        defaultValue={false}
                      />
                    </>
                  );
                }

                if (type === FieldTypes.STRING && multiline) {
                  return (
                    <>
                      {get(formData, getSource('readonly')) ? (
                        <SelectInput
                          id={`${props.inputName}-behavior`}
                          style={{ minWidth: 100 }}
                          label="vasara.form.behavior"
                          helperText={false}
                          source={behavior}
                          defaultValue="default"
                          choices={[
                            {
                              id: 'default',
                              name: 'vasara.form.stringBehavior.default',
                            },
                            {
                              id: 'link',
                              name: 'vasara.form.stringBehavior.link',
                            },
                          ]}
                          validate={validateRequired}
                          fullWidth={true}
                        />
                      ) : null}
                      <FeelInput
                        label="vasara.column.hideExpression"
                        source={hideExpression}
                        defaultValue=""
                        fullWidth={false}
                        variables={feelVariables}
                      />
                      <BooleanInput
                        source={readonly}
                        label="vasara.form.readonly"
                        helperText={false}
                        defaultValue={false}
                      />
                      <BooleanInput
                        id={`${props.inputName}-multiline`}
                        label="vasara.form.multiline"
                        helperText={false}
                        source={multiline}
                        defaultValue={false}
                      />
                      {!isFullwidth && (
                        <NumberInput
                          sx={{ width: '50px' }}
                          source={width}
                          label="vasara.form.width"
                          helperText={false}
                          defaultValue={0}
                        />
                      )}
                      <BooleanInput
                        source={fullWidth}
                        label="vasara.form.fullWidth"
                        helperText={false}
                        defaultValue={false}
                      />
                      <BooleanInput
                        source={required}
                        label="ra.validation.required"
                        helperText={false}
                        defaultValue={false}
                      />
                    </>
                  );
                }

                if (type === FieldTypes.BOOLEAN && inputType) {
                  return (
                    <>
                      {!isReadonly && (
                        <RadioButtonGroupInput
                          source={inputType}
                          label="vasara.form.inputType"
                          choices={[
                            { id: 'toggle', name: 'vasara.form.toggle' },
                            { id: 'radio', name: 'vasara.form.radio' },
                          ]}
                          defaultValue="radio"
                          helperText={false}
                          style={{ width: 'auto' }}
                        />
                      )}
                      <FeelInput
                        label="vasara.column.hideExpression"
                        source={hideExpression}
                        defaultValue=""
                        fullWidth={false}
                        variables={feelVariables}
                      />
                      <BooleanInput
                        source={readonly}
                        label="vasara.form.readonly"
                        helperText={false}
                        defaultValue={false}
                      />
                      {!isFullwidth && (
                        <NumberInput
                          sx={{ width: '50px' }}
                          source={width}
                          label="vasara.form.width"
                          helperText={false}
                          defaultValue={0}
                        />
                      )}
                      <BooleanInput
                        source={fullWidth}
                        label="vasara.form.fullWidth"
                        helperText={false}
                        defaultValue={false}
                      />
                      <BooleanInput
                        source={required}
                        label="ra.validation.required"
                        helperText={false}
                        defaultValue={false}
                      />
                    </>
                  );
                }

                return (
                  <>
                    <FeelInput
                      label="vasara.column.hideExpression"
                      source={hideExpression}
                      defaultValue=""
                      fullWidth={false}
                      variables={feelVariables}
                    />
                    <BooleanInput
                      source={readonly}
                      label="vasara.form.readonly"
                      helperText={false}
                      defaultValue={false}
                    />
                    {!isFullwidth && (
                      <NumberInput
                        sx={{ width: '50px' }}
                        source={width}
                        label="vasara.form.width"
                        helperText={false}
                        defaultValue={0}
                      />
                    )}
                    <BooleanInput
                      source={fullWidth}
                      label="vasara.form.fullWidth"
                      helperText={false}
                      defaultValue={false}
                    />
                    <BooleanInput
                      source={required}
                      label="ra.validation.required"
                      helperText={false}
                      defaultValue={false}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}

      {hasFixedColumns ? null : (
        <AutocompleteInput
          id={`${props.inputName}-dynamicColumnsSource`}
          label="vasara.form.dynamicColumnsSource"
          source={`${props.inputName}.dynamicColumnsSource`}
          choices={readonlySourceChoices}
          fullWidth={true}
          helperText="vasara.form.helperText.dynamicColumns"
        />
      )}

      <NumberInput source={`${props.inputName}.min`} min={0} label="vasara.form.min" defaultValue={0} />
      <NumberInput source={`${props.inputName}.max`} min={0} label="vasara.form.max" defaultValue={0} />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          const dependency = get(formData, `${props.inputName}.dependency`);
          const exportImport = get(formData, `${props.inputName}.export`);
          return (
            <>
              <AutocompleteArrayInput
                id={`${props.inputName}-sources`}
                label="vasara.form.sources"
                source={`${props.inputName}.sources`}
                choices={readonly ? readonlySourceChoices : sourceChoices}
                validate={validateRequired}
                fullWidth={true}
                helperText={false}
              />

              <BooleanInput
                id={`${props.inputName}-readonly`}
                label="vasara.form.readonly"
                source={`${props.inputName}.readonly`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />

              {!readonly && (
                <BooleanInput
                  id={`${props.inputName}-PII`}
                  label="vasara.form.PII"
                  source={`${props.inputName}.PII`}
                  defaultValue={false}
                  className={classes.floatLeft}
                  helperText={false}
                />
              )}
              {!readonly && (
                <BooleanInput
                  id={`${props.inputName}-required`}
                  label="ra.validation.required"
                  source={`${props.inputName}.required`}
                  defaultValue={false}
                  className={classes.floatLeft}
                  helperText={false}
                />
              )}
              {!readonly && (
                <BooleanInput
                  id={`${props.inputName}-fixedlength`}
                  label="vasara.form.fixedLengthTable"
                  source={`${props.inputName}.fixedLength`}
                  defaultValue={false}
                  className={classes.floatLeft}
                  helperText={false}
                />
              )}
              <BooleanInput
                id={`${props.inputName}-fluidLayout`}
                label="vasara.form.fluidLayoutTable"
                source={`${props.inputName}.fluidLayout`}
                defaultValue={false}
                sx={{ float: 'left' }}
                helperText={false}
              />
              <BooleanInput
                id={`${props.inputName}-export`}
                label={readonly ? 'vasara.form.xlsxExport' : 'vasara.form.xlsxImportExport'}
                source={`${props.inputName}.export`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />
              {!readonly && exportImport && (
                <BooleanInput
                  id={`${props.inputName}-disabled`}
                  label="vasara.form.xlsxIsSourceOfTruth"
                  source={`${props.inputName}.disabled`}
                  defaultValue={false}
                  className={classes.floatLeft}
                  helperText={false}
                />
              )}
              <AutocompleteInput
                id={`${props.inputName}-dependency`}
                label="vasara.form.dependency"
                source={`${props.inputName}.dependency`}
                choices={combinedChoices}
                fullWidth={true}
                helperText={false}
                className={classes.clearLeft}
              />
              {!!dependency && (
                <TextInput
                  id={`${props.inputName}-condition`}
                  label="vasara.form.dependencyExpression"
                  source={`${props.inputName}.condition`}
                  defaultValue=""
                  fullWidth={true}
                  helperText={false}
                />
              )}
              {!!dependency && (
                <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                  <SimpleFormIterator className="VasaraVariablesIterator">
                    <TextInput
                      source={`id`}
                      label="vasara.form.variable"
                      helperText={false}
                      validate={validateRequired}
                    />
                    <AutocompleteInput
                      label="vasara.form.source"
                      source={`source`}
                      choices={combinedChoices}
                      validate={validateRequired}
                      helperText={false}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              )}
            </>
          );
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'width',
  },
});

const maxRequired = (max: number = 1) => (value: any) => {
  return Array.isArray(value) && value.length > max
    ? { message: 'vasara.validation.tooManyRows', args: { smart_count: max } }
    : undefined;
};

const minRequired = (min: number = 1) => (value: any) => {
  return !Array.isArray(value) || value.length < min
    ? { message: 'vasara.validation.notEnoughRows', args: { smart_count: min } }
    : undefined;
};

interface AddItemProps {
  fields: string[];
}

export const AddItemButton = (props: ButtonProps & AddItemProps) => {
  const { append } = useArrayInput();
  const { onClick, fields, ...rest } = props;
  return (
    <IconButtonWithTooltip
      label="ra.action.add"
      size="small"
      onClick={() => {
        append(fields.reduce((acc: any, field: string) => ({ ...acc, [field]: null }), {}));
      }}
      color="primary"
      {...rest}
    >
      <AddIcon fontSize="small" />
    </IconButtonWithTooltip>
  );
};

export const TableInputImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const record = useRecordContext();
  // if the table has dynamic columns
  // (columns set in a process variable instead of in the usual fixed schema),
  // they are resolved into the schema during the variable resolution in UserTaskEdit.tsx,
  // requiring no changes here
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };

  const fluidLayout = schema?.fluidLayout ?? false;
  const subfieldsName = `${schemaField}.fields`;
  const { fields: subfields } = useFieldArray({ name: subfieldsName, control: form.control });
  const subfieldSchemas = form.getValues(subfieldsName).reduce((acc: any, obj: any, index: number) => {
    acc[subfields[index].id] = obj;
    return acc;
  }, {});
  const columns = subfields.map(subfield => subfieldSchemas[subfield.id].id);

  const label = schema.label?.[locale] ?? '';
  const isFixed = !!schema.fixedLength ?? false;
  const exportImport = !!schema.export ?? false;
  const importIsSourceOfTruth = exportImport && !!schema.disabled;

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];
  const validator: Validator[] = [];
  if ((schema.min || -1) > 0) {
    validator.push(minRequired(schema.min));
    if (schema.required) {
      validator.push(required());
    }
  }
  if (schema.max || -1 > 0) {
    validator.push(maxRequired(Math.max(schema.min, schema.max)));
  }

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      !dependencyName ||
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      // If we returned null, the previously registered validator for the react-hook-form would remain in effect.
      // Therefore we return the simples possible input field with empty validator instead.
      return <TextInput sx={{ display: 'none' }} source={schema.id} validate={[]} disabled />;
    }
  }

  if (schema.readonly || importIsSourceOfTruth) {
    const fieldValue = form.watch(schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    // Note: Record is patched with fieldValue to support immediate update from Xlsx import
    // when isSourceOfTruth=true and we want to show "readonly" for editable field.
    return (
      <RecordContextProvider value={{ ...record, [schema.id]: fieldValue }}>
        <ArrayInput
          label={label}
          helperText={(schema.helperText?.[locale] ?? false) || ''}
          source={schema.id}
          fullWidth={true}
        >
          <>
            {exportImport ? (
              <Box sx={{ position: 'absolute', right: '0' }}>
                <XlsxExportLink
                  sheets={{
                    [schema.id]: {
                      name: label,
                      columns,
                    },
                  }}
                />
                {importIsSourceOfTruth && (
                  <XlsxImportLink
                    sheets={{
                      [schema.id]: {
                        name: label,
                        columns: subfields
                          .filter(subfield => subfieldSchemas[subfield.id].readonly !== true)
                          .map(subfield => subfieldSchemas[subfield.id].id),
                        isFixed,
                        maxRows: schema.max,
                      },
                    }}
                  />
                )}
              </Box>
            ) : null}
            <SimpleFormIterator
              className={fluidLayout ? '' : 'VasaraTableFieldIterator VasaraTableFieldReadOnly'}
              disableAdd={true}
              disableRemove={true}
              disableClear={true}
              disableReordering={true}
              inline
            >
              <SimpleFormIteratorItemContext.Consumer>
                {iterCtx =>
                  subfields
                    .filter(field => {
                      const subfieldSchema = subfieldSchemas[field.id];
                      const hideExpression = subfieldSchema?.conditional?.hide;
                      return !hideExpression || !evaluate(hideExpression, form.watch(`${schema.id}[${iterCtx.index}]`));
                    })
                    .map(field => {
                      const subfieldSchema = subfieldSchemas[field.id];
                      const subfieldName = `${subfieldsName}[${columns.indexOf(subfieldSchema.id)}]`;
                      const id = `${schema.id}[${iterCtx.index}].${subfieldSchema.id}`;
                      const schemaOverride: any = {
                        id,
                        readonly: true,
                        helperText: false,
                        label: false,
                        fullWidth: true,
                      };

                      const FormComponent = FieldComponent[subfieldSchema.type];
                      return (
                        <div className={fluidLayout ? '' : 'VasaraTableCell'} key={field.id}>
                          <Labeled
                            label={subfieldSchema.label?.[locale] ?? ''}
                            className={subfieldSchema?.fullWidth ? classes.fullWidth : undefined}
                            style={
                              subfieldSchema.width
                                ? {
                                    marginBottom: 0,
                                    minWidth: subfieldSchema.width,
                                  }
                                : {
                                    marginBottom: 0,
                                  }
                            }
                          >
                            <FormComponent schemaField={subfieldName} schemaOverride={schemaOverride} />
                          </Labeled>
                        </div>
                      );
                    })
                }
              </SimpleFormIteratorItemContext.Consumer>
            </SimpleFormIterator>
          </>
        </ArrayInput>
      </RecordContextProvider>
    );
  }

  return (
    <ArrayInput
      label={label}
      helperText={schema.helperText?.[locale] || ''}
      source={schema.id}
      fullWidth={true}
      validate={validator}
    >
      <>
        {exportImport ? (
          <Box sx={{ position: 'absolute', right: '0' }}>
            <XlsxExportLink
              sheets={{
                [schema.id]: {
                  name: label,
                  columns,
                },
              }}
            />
            <XlsxImportLink
              sheets={{
                [schema.id]: {
                  name: label,
                  columns: subfields
                    .filter(subfield => subfieldSchemas[subfield.id].readonly !== true)
                    .map(subfield => subfieldSchemas[subfield.id].id),
                  isFixed,
                  maxRows: schema.max,
                },
              }}
            />
          </Box>
        ) : null}
        <SimpleFormIterator
          className={fluidLayout ? '' : 'VasaraTableFieldIterator'}
          addButton={<AddItemButton fields={columns} />}
          disableAdd={isFixed}
          disableClear={isFixed}
          disableRemove={isFixed}
          disableReordering={isFixed}
          inline
        >
          <SimpleFormIteratorItemContext.Consumer>
            {iterCtx =>
              subfields
                .filter(field => {
                  const subfieldSchema = subfieldSchemas[field.id];
                  const hideExpression = subfieldSchema?.conditional?.hide;
                  return !hideExpression || !evaluate(hideExpression, form.watch(`${schema.id}[${iterCtx.index}]`));
                })
                .map(field => {
                  const subfieldSchema = subfieldSchemas[field.id];
                  const subfieldName = `${subfieldsName}[${columns.indexOf(subfieldSchema.id)}]`;
                  const id = `${schema.id}[${iterCtx.index}].${subfieldSchema.id}`;
                  const schemaOverride = { id, helperText: false, disabled: importIsSourceOfTruth, fullWidth: true };

                  const FormComponent = FieldComponent[subfieldSchema.type];
                  return (
                    <div className={fluidLayout ? '' : 'VasaraTableCell'} key={field.id}>
                      <FormComponent
                        style={subfieldSchema.width ? { minWidth: subfieldSchema.width } : undefined}
                        schemaField={subfieldName}
                        schemaOverride={schemaOverride}
                      />
                    </div>
                  );
                })
            }
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </>
    </ArrayInput>
  );
};

export const TableInput = React.memo(TableInputImpl);
export default React.memo(TableField);
