import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import { RichTextInput } from 'ra-input-rich-text';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const useStyles = makeStyles(() => ({
  help: {
    color: '#000000',
    fontSize: '1rem',
  },
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
}));

const HelpField: React.FC<CommonFieldProps> = props => {
  const [locale] = useLocaleState();
  const classes = useStyles();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />
      <RichTextInput
        label="vasara.form.help"
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        helperText={false}
      />
      <BooleanInput
        fullWidth={true}
        label="vasara.form.showLabel"
        source={`${props.inputName}.showLabel`}
        defaultValue={false}
        helperText={false}
      />

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          if (!dependency) {
            return null;
          }
          return (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          );
        }}
      </FormDataConsumer>
    </FieldsetField>
  );
};

export const HelpDisplayImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.showLabel && label) {
    return (
      <>
        <h3>{label}</h3>
        <div className={classes.help} dangerouslySetInnerHTML={{ __html: schema.helperText?.[locale] ?? '' }} />
      </>
    );
  }

  return <div className={classes.help} dangerouslySetInnerHTML={{ __html: schema.helperText?.[locale] ?? '' }} />;
};

export const HelpDisplay = React.memo(HelpDisplayImpl);
export default React.memo(HelpField);
