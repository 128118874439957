import { TranslationMessages, resolveBrowserLocale } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import { getEnv } from './util/env';

export interface Choice {
  id: string;
  name: string;
}

// https://github.com/Aikain/ra-language-finnish/blob/master/src/index.ts

const finnishMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'Lisää suodatin',
      add: 'Lisää',
      back: 'Takaisin',
      bulk_actions: '%{smart_count} valittu',
      cancel: 'Peruuta',
      clear_array_input: 'Tyhjennä lista',
      clear_input_value: 'Tyhjennä',
      clone: 'Kopioi',
      confirm: 'Vahvista',
      create: 'Uusi',
      create_item: 'Uusi %{item}',
      delete: 'Poista',
      edit: 'Muokkaa',
      export: 'Vie',
      list: 'Lista',
      refresh: 'Päivitä',
      remove_filter: 'Poista suodatin',
      remove_all_filters: 'Poista kaikki suodattimet',
      remove: 'Poista',
      save: 'Tallenna',
      search: 'Etsi',
      select_all: 'Valitse kaikki',
      select_row: 'Valitse tämä rivi',
      show: 'Näytä',
      sort: 'Järjestä',
      undo: 'Kumoa',
      unselect: 'Poista valinnat',
      expand: 'Laajenna',
      close: 'Sulje',
      open_menu: 'Avaa valikko',
      close_menu: 'Sulje valikko',
      update: 'Päivitä',
      move_up: 'Siirrä ylös',
      move_down: 'Siirrä alas',
      open: 'Avaa',
      toggle_theme: 'Vaihda teema',
      select_columns: 'Sarakkeet',
    },
    boolean: {
      true: 'Kyllä',
      false: 'Ei',
      null: '',
    },
    page: {
      create: 'Luo %{name}',
      dashboard: 'Työpöytä',
      edit: '%{name} %{recordRepresentation}',
      error: 'Jotain meni pieleen',
      list: '%{name} -lista',
      loading: 'Ladataan',
      not_found: 'Ei löytynyt',
      show: '%{name} %{recordRepresentation}',
      empty: 'Kohteessa %{name} ei ole sisältöä.',
      invite: 'Haluako lisätä ensimmäisen?',
    },
    input: {
      file: {
        upload_several: 'Pudota tiedostoja tai klikkaa valitaksesi.',
        upload_single: 'Pudota tiedosto tai klikkaa valitaksesi.',
      },
      image: {
        upload_several: 'Pudota kuvia tai klikkaa valitaksesi.',
        upload_single: 'Pudota kuva tai klikkaa valitaksesi.',
      },
      password: {
        toggle_hidden: 'Näytä salasana',
        toggle_visible: 'Piilota salasana',
      },
      references: {
        all_missing: 'Viittauksen tietoja ei löydetty.',
        many_missing: 'Vähintään yksi viittauksista ei ole saatavilla.',
        single_missing: 'Viittaus ei ole saatavilla.',
      },
    },
    message: {
      about: 'Tietoja',
      are_you_sure: 'Oletko varma?',
      auth_error: 'Autentikointitokenin vahvistamisessa tapahtui virhe',
      bulk_delete_content:
        'Oletko varma, että %{name} poistetaan? |||| Oletko varma että haluat poistaa %{smart_count} kohdetta?',
      bulk_delete_title: 'Poista %{name} |||| Poista %{smart_count} %{name} kohdetta',
      bulk_update_content:
        'Oletko varma, että %{name} päivitetään? |||| Oletko varma, että haluat päivittää %{smart_count} kohdetta?',
      bulk_update_title: 'Päivitä %{name} |||| Päivitä %{smart_count} %{name} kohdetta',
      clear_array_input: 'Oletko varma, että haluat tyhjentää koko listan?',
      delete_content: 'Oletko varma, että haluat poistaa tämän kohteen?',
      delete_title: 'Poista %{name} #%{id}',
      details: 'Yksityiskohdat',
      error: 'Ongelma havaittu eikä pyyntöäsi pystytty suorittamaan.',
      invalid_form: 'Lomake ei täytä vaatimuksia. Tarkista virheet.',
      loading: 'Sivua ladataan, ole hyvä ja odota hetki',
      no: 'Ei',
      not_found: 'Kirjoitit viallisen osoitteen tai seurasit viallista linkkiä.',
      yes: 'Kyllä',
      unsaved_changes: 'Joitakin muutoksia ei ole tallennettu. Haluatko varmasti hylätä ne?',
    },
    navigation: {
      no_results: 'Yhtään kohdetta ei löytynyt',
      no_more_results: '%{page}. sivu ylittää tulosten määrän. Yritä edellistä sivua.',
      page_out_of_boundaries: '%{page}. sivu on rajojen ulkopuolella',
      page_out_from_end: 'Olet jo viimeisellä sivulla',
      page_out_from_begin: 'Olet jo ensimmäisellä sivulla',
      page_range_info: '%{offsetBegin}-%{offsetEnd} / %{total}',
      partial_page_range_info: '%{offsetBegin}-%{offsetEnd} / %{offsetEnd}+',
      current_page: 'Sivu %{page}',
      page: 'Siirry sivulle %{page}',
      first: 'Ensimmäinen sivu',
      last: 'Viimeinen sivu',
      next: 'Seuraava sivu',
      previous: 'Edellinen sivu',
      page_rows_per_page: 'Rivejä sivua kohti:',
      skip_nav: 'Siirry sisältöön',
    },
    sort: {
      sort_by: "Järjestä %{order} '%{field}'-kentän mukaan",
      ASC: 'nousevasti',
      DESC: 'laskevasti',
    },
    auth: {
      auth_check_error: 'Kirjaudu sisään jatkaaksesi',
      user_menu: 'Profiili',
      username: 'Käyttäjätunnus',
      password: 'Salasana',
      sign_in: 'Kirjaudu sisään',
      sign_in_error: 'Kirjautuminen epäonnistui, ole hyvä ja yritä uudelleen',
      logout: 'Kirjaudu ulos',
    },
    notification: {
      updated: 'Kohde päivitetty |||| %{smart_count} kohdetta päivitetty',
      created: 'Kohde luotu',
      deleted: 'Kohde poistettu |||| %{smart_count} kohdetta poistettu',
      bad_item: 'Virheellinen kohde',
      item_doesnt_exist: 'Kohdetta ei ole olemassa',
      http_error: 'Virhe palvelimelle yhdistettäessä',
      data_provider_error: 'dataProvider virhe. Tarkista console lisätietojen saamiseksi.',
      i18n_error: 'Valitulle kielelle ei voitu ladata käännöstä',
      canceled: 'Toiminta peruutettu',
      logged_out: 'Istuntosi on päättynyt, ole hyvä kirjaudu uudelleen.',
      not_authorized: 'Sinulla ei ole lupaa käyttää tätä resurssia.',
    },
    validation: {
      required: 'Pakollinen',
      minLength: 'Vaadittu vähintään %{min} merkkiä pitkä',
      maxLength: 'Sallittu enintään %{max} merkkiä pitkä',
      minValue: 'Pienin sallittu numero on %{min}',
      maxValue: 'Suurin sallittu numero on %{max}',
      number: 'On oltava numero',
      email: 'Sähköpostin täytyy olla kelvollinen',
      oneOf: 'Valinnan on oltava joku seuraavista: %{options}',
      true: 'Pakollinen valinta.',
      regex: 'Sisällön on täsmättävä regexp:iin: %{pattern}',
    },
    saved_queries: {
      label: 'Tallennetut kyselyt',
      query_name: 'Kyselyn nimi',
      new_label: 'Tallenna nykyinen kysely...',
      new_dialog_title: 'Tallenna kysely nimellä',
      remove_label: 'Poista tallennettu kysely',
      remove_label_with_name: 'Poista kysely "%{name}"',
      remove_dialog_title: 'Poistetaanko tallennettu kysely?',
      remove_message: 'Oletko varma, että haluat poistaa kyseisen kohteen tallennetuista kyselyistä?',
      help: 'Suodata listaa ja tallenna tämä kysely myöhempää käyttöä varten',
    },
    configurable: {
      customize: 'Mukauta',
      configureMode: 'Mukauta tätä sivua',
      inspector: {
        title: 'Tarkastelu',
        content: 'Vie hiiri elementin päälle mukauttaaksesi sitä',
        reset: 'Nollaa asetukset',
        hideAll: 'Piilota kaikki',
        showAll: 'Näytä kaikki',
      },
      Datagrid: {
        title: 'Taulukko',
        unlabeled: 'Nimetön kenttä: %{column}',
      },
      SimpleForm: {
        title: 'Lomake',
        unlabeled: 'Nimetön kenttä: %{input}',
      },
      SimpleList: {
        title: 'Lista',
        primaryText: 'Ensisijainen teksti',
        secondaryText: 'Toissijainen teksti',
        tertiaryText: 'Tertiäärinen teksti',
      },
    },
  },
};

const PRIVACY_NOTICE_FI =
  getEnv('REACT_APP_PRIVACY_NOTICE_FI') ||
  'https://www.jyu.fi/fi/yliopisto/tietosuojailmoitus/tietosuojailmoitus-vasara.pdf';
const PRIVACY_NOTICE_EN = getEnv('REACT_APP_PRIVACY_NOTICE_EN') || 'https://www.jyu.fi/en/university/privacy-notice';
const SUBTITLE_FI = getEnv('REACT_APP_SUBTITLE_FI') || 'Jyväskylän yliopisto';
const SUBTITLE_EN = getEnv('REACT_APP_SUBTITLE_EN') || 'University of Jyväskylä';

const customFinnishMessages: any = {
  ra: {
    ...finnishMessages.ra,
    page: {
      ...finnishMessages.ra.page,
      empty: 'Lista on tyhjä',
      invite: '',
    },
    notification: {
      ...finnishMessages.ra.notification,
      created: 'Asia tallennettu',
      updated: 'Asia tallennettu |||| %{smart_count} asiaa tallennettu',
    },
    message: {
      ...finnishMessages.ra.message,
      invalid_form: 'Pakollinen tieto puuttuu tai on virheellinen. Tarkista syötetyt tiedot.',
    },
  },
  vasara: {
    action: {
      add: 'Lisää',
      claim: 'Ota käsiteltäväksi',
      copy: 'Kopioi leikepöydälle',
      exitForm: 'Poistu lomakkeelta',
      import: 'Tuo',
      importing: 'Tuodaan…',
      move: 'Siirrä',
      open: 'Avaa %{name}',
      paste: 'Liitä leikepöydältä',
      settings: 'Asetukset',
      start: 'Käynnistä uusi',
      startNamed: 'Tee uusi %{name}',
      submit: 'Lähetä',
      saveAndClose: 'Tallenna ja sulje',
      saveAndSubmit: 'Lähetä eteenpäin',
      saveDraft: 'Tallenna muutokset',
      showConfidential: 'Avaa luottamuksellinen kenttä',
      switch: 'Vaihda',
      update: 'Päivitä',
      zoomIn: 'Suurenna kuvaajaa',
      zoomOut: 'Pienennä kuvaajaa',
      locate: 'Valitse sijaintisi',
      fullscreen: 'Näytä koko näytöllä',
    },
    filters: {
      startableInTasklistOnly: 'Vain käynnistettävät',
    },
    message: {
      builder_available_controls: 'Uusi kenttä:',
      error_affected_rows_zero: 'Tallennus epäonnistui (asiassa ei muutoksia tai muutoksia ei sallittu).',
      mapLoading: 'Karttaa ladataan...',
    },
    form: {
      expression: 'FEEL-lauseke',
      buttons: 'Painikkeet',
      language: 'Kieli',
      multilingual: 'Monikielinen',
      mimeTypes: 'Tiedostotyypit',
      maxFileSize: 'Maksimikoko',
      maxFileSizeHelp: 'Mt',
      table: 'Taulukko',
      tableXlsxFileToImport: 'Tuotava Excel-tiedosto',
      dynamicColumnsSource: 'Sarakkeiden skeema muuttujasta',
      id: 'Tunniste',
      columns: 'Sarakkeet',
      field: 'Kenttä',
      fullWidth: 'Kokoleveä',
      decimals: 'Sallitut desimaalit',
      add: 'Lisää',
      activities: 'Tapahtumat',
      array: 'Monivalinta',
      boolean: 'Kyllä / Ei',
      button: 'Painike',
      confirmation: 'Pyydä vahvistus',
      areYouSure: 'Oletko varma?',
      yesAction: '%{action}',
      cancel: 'Peruuta',
      date: 'Päivämäärä',
      dependency: 'Ehto',
      variable: 'Muuttuja',
      variables: 'Ehtolausekkeen lisämuuttujat',
      dependencyExpression: 'Ehtolauseke',
      dropdown: 'Pudotusvalikko',
      fieldset: 'Osio',
      file: 'Liitetiedosto',
      buttonType: 'Toiminto',
      buttonTypeMessage: 'Viesti tehtävän prosessille',
      buttonTypeStart: 'Käynnistä uusi prosessi',
      messageName: 'Viestin tyyppi / nimi',
      messageSuccess: 'Onnistumisviesti',
      messageVariables: 'Viestin muuttujat',
      processDefinitionName: 'Prosessi',
      filterResources: 'Tietokanta',
      formSettingsLabel: 'Lomakkeen asetukset',
      customSaveAndSubmit: 'Räätälöi "Lähetä eteenpäin"',
      customSaveAndSubmitHelp: 'Määritä oma teksti ja onnistumisviesti "Lähetä eteenpäin"-painikkeelle',
      customSaveAndSubmitLabel: 'Painikkeen teksti',
      customSaveAndSubmitSuccessMessage: 'Onnistumisviesti',
      customSaveAndSubmitLoadingPrimaryMessage: 'Odotussivun otsikko',
      customSaveAndSubmitLoadingSecondaryMessage: 'Odotussivun kuvausteksti',
      waitForNextTask: 'Odota seuraavaa tehtävälomaketta',
      waitForNextTaskHelp: 'Odota seuraavan samaan prosessiin liittyvän tehtävälomakkeen avautumista (sekuntia)',
      help: 'Ohjeteksti',
      helperText: {
        hideExpression: 'FEEL-lauseke tämän piilotamiseksi',
        field: 'Lisää kenttä',
        fieldset: 'Lisää osio',
        filterResources: 'Suodata tietokantatauluja',
        key: 'Dokumenttikentässä (jsonb) käytettävä tallennusavain',
        label: 'Syötä otsikko',
        type: 'Vaihda kentän tyyppiä (jos tiedät mitä teet)',
        options: 'Vaihtoehdot voi jättää tyhjäksi, jos Vasara löytää ne kytkennästä',
        variableSource: 'Muuttujan tulee olla JSON-lista objekteja, joilla on id ja name -kentät',
        dynamicColumns:
          'Määrittele sarakkeet JSON-muotoisen prosessimuuttujan avulla siten, että ne voivat vaihdella instanssikohtaisesti.',
        dependency: 'Näytetään vain, kun jokin riippuvuus on jo asetettu',
      },
      behavior: 'Esitystapa',
      fixedLengthTable: 'Estä rivien lisäys ja poisto',
      fluidLayoutTable: 'Rivittyvät sarakkeet',
      xlsxImportExport: 'Salli vienti ja tuonti',
      xlsxExport: 'Salli vienti',
      xlsxIsSourceOfTruth: 'Muokkaus vain tuonnilla',
      inputType: 'Tyyli',
      integer: 'Numero',
      integerBehavior: {
        euro: 'Euro',
        percentage: 'Prosentti 0–100 % kokonaisluvusta',
        decimalPercentage: 'Prosentti desimaaliluvusta',
        default: 'Oletus',
      },
      stringBehavior: {
        link: 'Linkki',
        default: 'Oletus',
        error: 'Virheilmoitus',
      },
      initialValue: {
        label: 'Oletusarvo',
        name: 'Käyttäjän koko nimi',
        email: 'Käyttäjän sähköpostiosoite',
      },
      key: 'Avain',
      label: 'Otsikko',
      max: 'Enintään',
      min: 'Vähintään',
      multiline: 'Monirivinen',
      PII: 'Henkilötietoa',
      confidential: 'Luottamuksellista',
      relations: 'Suhteet',
      record: 'Tietue',
      comments: 'Kommentit',
      no: 'Ei',
      options: 'Vaihtoehdot',
      preview: 'Esikatselu',
      process: 'Prosessi',
      radio: 'Radiopainikkeet',
      readonly: 'Vain luku',
      width: 'Leveys',
      select: 'Valinta',
      size: 'Koko',
      showLabel: 'Näytä otsikko',
      processes: 'Prosessit',
      source: 'Kytkentä',
      sources: 'Kytkennät',
      string: 'Teksti',
      task: 'Tehtävä',
      toggle: 'Kytkin',
      type: 'Tyyppi',
      value: 'Arvo',
      vocabulary: 'Sanasto',
      variableSource: 'Vaihtoehdot prosessimuuttujasta',
      yes: 'Kyllä',
      geo: 'Sijainti kartalla',
      selectedLocation: 'Valittu sijainti',
    },
    page: {
      entitySettingsEdit: '%{resource} asetukset',
      createUserTaskForm: 'Uusi lomake: ',
      deployProcessDefinition: 'Lisää tai päivitä prosessikuvaus',
      deployDecisionDefinition: 'Lisää tai päivitä päätöstaulu',
      processDeploymentFiles: 'Prosessikuvauksen tiedostot',
      decisionDeploymentFiles: 'Päätöstaulujen tiedostot',
      migrate: 'Päivitä käynnissä olevat prosessit',
      helperText: {
        migrate: 'Yritä automaattista versiopäivitystä käynnissä oleville prosesseille',
      },
    },
    view: {
      processDefinition: 'Prosessi',
      processDefinitionList: 'Prosessit',
      decisionDefinition: 'Päätöstaulu',
      decisionDefinitionList: 'Päätöstaulut',
      versions: 'Versiot',
      diagram: 'Prosessikaavio',
    },
    column: {
      hideExpression: 'Piilotusehto',
      assignee: 'Omistaja',
      author: 'Tekijä',
      body: 'Sisältö',
      decisionDefinitionName: 'Päätöstaulu',
      description: 'Kuvaus',
      diagram: 'Kaavio',
      endTime: 'Suoritettu',
      identifier: 'Tunniste',
      modified: 'Päivitetty',
      processDefinitionName: 'Prosessi',
      startTime: 'Luotu',
      subject: 'Aihe',
      title: 'Nimike',
      userTask: 'Tehtävä',
      JSONPath: 'JSON-polku',
      filterable: 'Suodata',
      sortable: 'Järjestä',
      localizedLabel: 'Otsikko (%{locale})',
      localizedName: 'Nimi (%{locale})',
      editable: 'Näytä Muokkaa-painike',
    },
    menu: {
      database: 'Tietokanta',
      language: 'Language menu, kielivalikko',
    },
    resource: {
      comment: 'Kommentti',
      userTask: 'Tehtävät',
      processDefinition: 'Prosessit',
      decisionDefinition: 'Päätöstaulut',
      processInstance: 'Prosessit',
    },
    notification: {
      ok: 'Toiminto suoritettu',
      updated: 'Tietue tallennettu',
      openConfidentialFailed: 'Luottamuksellisen kentän avaus epäonnistui',
    },
    validation: {
      invalidDate: 'Virheellinen päivämäärä',
      tooManyRows: 'Syötä korkeintaan yksi rivi. |||| Syötä korkeintaan %{smart_count} riviä.',
      notEnoughRows: 'Syötä vähintään yksi rivi. |||| Syötä vähintään %{smart_count} riviä.',
      maxFileSize: 'Tiedoston suurin sallittu koko on 1 Mt. |||| Tiedoston suurin sallittu koko on %{smart_count} Mt.',
    },
    vocabulary: {
      user: 'Käyttäjä',
      group: 'Ryhmä',
    },
    ui: {
      title: 'Vasara',
      guestForm: 'Aloita tästä',
      subtitle: SUBTITLE_FI,
      privacyNotice: PRIVACY_NOTICE_FI,
      privacyNoticeLabel: 'Tietosuojailmoitus',
    },
    guest: {
      title: 'Vierailijan kirjautuminen',
      welcome: 'Tervetuloa',
      instructions:
        'Voit pyytää tähän palveluun henkilökohtaisen kirjautumislinkin täyttämällä ja lähettämällä alla olevan lomakkeen.',
      tos: 'Käyttöehdot',
      privacyNoticeIntro:
        'Palvelua käyttämällä hyväksyt palvelun keräämien tietojen tallentamisen ja käsittelyn tietosuojailmoituksen mukaisesti. Katso myös',
      consent: 'Hyväksyn palvelun käyttöehdot.',
      processDefinitionKey: 'Kirjautumisen tarve',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      email: 'Sähköpostiosoite',
      emailHelper: 'Henkilökohtainen kirjautumislinkkisi lähetetään tähän sähköpostiosoitteeseen.',
      save: 'Lähetä pyyntö',
      success: 'Pyyntö vastaanotettu. Ole hyvä ja tarkista sähköpostisi.',
      error: 'Pyyntö epäonnistui. Tarkista lomakkeen valinnat ja yritä myöhemmin uudelleen.',
    },
  },
};

const customEnglishMessage = {
  ra: {
    ...englishMessages.ra,
    page: {
      ...englishMessages.ra.page,
      empty: 'List is empty',
      invite: '',
    },
    notification: {
      ...englishMessages.ra.notification,
      created: 'Record saved',
      updated: 'Record saved |||| %{smart_count} records saved',
    },
    message: {
      ...englishMessages.ra.message,
      invalid_form: 'Mandatory information is missing or invalid. Please check the form.',
    },
    validation: {
      ...englishMessages.ra.validation,
      true: 'Required selection.',
    },
  },
  vasara: {
    action: {
      add: 'Add',
      open: 'Open %{name}',
      claim: 'Claim',
      copy: 'Copy to clipboard',
      exitForm: 'Exit form',
      import: 'Import',
      importing: 'Importing…',
      move: 'Move',
      paste: 'Paste from clipboard',
      settings: 'Settings',
      start: 'Create new',
      startNamed: 'Create new %{name}',
      submit: 'Submit',
      saveAndClose: 'Save and close',
      saveAndSubmit: 'Submit forward',
      saveDraft: 'Save changes',
      showConfidential: 'Open confidential field',
      switch: 'Switch',
      update: 'Update',
      zoomIn: 'Zoom in diagram',
      zoomOut: 'Zoom out diagram',
      locate: 'Select your location',
      fullscreen: 'View fullscreen',
    },
    filters: {
      startableInTasklistOnly: 'Startable only',
    },
    message: {
      builder_available_controls: 'New field:',
      error_affected_rows_zero: 'Unable to save changes (no changes or not enough permissions for changes).',
      mapLoading: 'Map loading...',
    },
    form: {
      expression: 'FEEL expression',
      buttons: 'Buttons',
      language: 'Language',
      multilingual: 'Multilingual',
      mimeTypes: 'File types',
      maxFileSize: 'Maximum size',
      maxFileSizeHelp: 'MB',
      id: 'Id',
      table: 'Table',
      tableXlsxFileToImport: 'Excel file to import',
      dynamicColumnsSource: 'Columns schema from variable',
      columns: 'Columns',
      field: 'Field',
      decimals: 'Allowed decimals',
      fullWidth: 'Fullwidth',
      add: 'Add',
      activities: 'Activities',
      array: 'Select many',
      boolean: 'Yes / No',
      button: 'Button',
      confirmation: 'Require confirmation',
      areYouSure: 'Are you sure?',
      yesAction: '%{action}',
      cancel: 'Cancel',
      date: 'Date',
      dependency: 'Condition',
      variable: 'Variable',
      variables: 'Additional variables in condition expression',
      dependencyExpression: 'Condition expression',
      dropdown: 'Dropdown',
      fieldset: 'Fieldset',
      file: 'Attachment',
      buttonType: 'Action',
      buttonTypeMessage: 'Message for the current process',
      buttonTypeStart: 'Start new process',
      processDefinitionName: 'Process',
      messageName: 'Message type / name',
      messageSuccess: 'Success message',
      messageVariables: 'Message variables',
      filterResources: 'Database',
      formSettingsLabel: 'Form settings',
      customSaveAndSubmit: 'Customize "Save and submit"',
      customSaveAndSubmitHelp: 'Set custom label and success message for "Save and submit"-button',
      customSaveAndSubmitLabel: 'Button label',
      customSaveAndSubmitSuccessMessage: 'Success message',
      customSaveAndSubmitLoadingPrimaryMessage: 'Next for page header',
      customSaveAndSubmitLoadingSecondaryMessage: 'Next for page description',
      waitForNextTask: 'Wait for next task',
      waitForNextTaskHelp: 'Wait for the next task on the business key to open (seconds)',
      help: 'Help',
      helperText: {
        hideExpression: 'FEEL expression for hiding this',
        field: 'Add field',
        fieldset: 'Add fieldset',
        filterResources: 'Filter database tables',
        key: 'Document field (jsonb) store key',
        label: 'Type label',
        type: 'Change field type (if you know what you are doing)',
        options: 'Options may be empty when Vasara is able to read them from the binding',
        variableSource: 'The variable must be a JSON list of objects with id and name',
        dynamicColumns: 'Define columns in a JSON process variable such that they can vary from instance to instance.',
        dependency: 'Will be shown only when one of the dependencies has been set',
        variables: 'bla',
      },
      behavior: 'Display style',
      fixedLengthTable: 'Disable adding/removing rows',
      fluidLayoutTable: 'Fluid layout',
      xlsxImportExport: 'Enable Excel import/export',
      xlsxExport: 'Enable Excel export',
      xlsxIsSourceOfTruth: 'Edit with import only',
      inputType: 'Style',
      integer: 'Number',
      integerBehavior: {
        euro: 'Euro',
        percentage: 'Percentage 0–100 % from intger',
        decimalPercentage: 'Percentage from decimal',
        default: 'Default',
      },
      stringBehavior: {
        link: 'Link',
        default: 'Default',
        error: 'Error message',
      },
      initialValue: {
        label: 'Default value',
        name: 'User full name',
        email: 'User email address',
      },
      key: 'Key',
      label: 'Label',
      max: 'Max',
      min: 'Min',
      multiline: 'Multiline',
      PII: 'PII',
      confidential: 'Confidential',
      record: 'Record',
      comments: 'Comments',
      no: 'No',
      options: 'Options',
      preview: 'Preview',
      process: 'Process',
      radio: 'Radio buttons',
      readonly: 'Readonly',
      width: 'Width',
      relations: 'Relations',
      processes: 'Processes',
      select: 'Select one',
      size: 'Size',
      source: 'Binding',
      sources: 'Bindings',
      showLabel: 'Show label',
      string: 'Text',
      task: 'Task',
      toggle: 'Toggle',
      type: 'Type',
      value: 'Value',
      vocabulary: 'Vocabulary',
      variableSource: 'Options from process variable',
      yes: 'Yes',
      geo: 'Map location',
      selectedLocation: 'Selected location',
    },
    page: {
      entitySettingsEdit: '%{resource} settings',
      createUserTaskForm: 'New form: ',
      deployProcessDefinition: 'Add or update process',
      deployDecisionDefinition: 'Add or update decision table',
      processDeploymentFiles: 'Process definition files',
      decisionDeploymentFiles: 'Decision definition files',
      migrate: 'Migrate process instances',
      helperText: {
        migrate: 'Try to automatically migrate all existing process instances',
      },
    },
    view: {
      processDefinition: 'Process definition',
      processDefinitionList: 'Process definitions',
      decisionDefinition: 'Decision definitions',
      decisionDefinitionList: 'Decision definition',
      versions: 'Versions',
      diagram: 'Process diagram',
    },
    column: {
      hideExpression: 'Hide expression',
      assignee: 'Assignee',
      author: 'Author',
      body: 'Body',
      decisionDefinitionName: 'Decision definition',
      description: 'Description',
      diagram: 'Diagram',
      endTime: 'Completed',
      identifier: 'Identifier',
      modified: 'Update at',
      processDefinitionName: 'Process',
      startTime: 'Created',
      subject: 'Subject',
      title: 'Title',
      userTask: 'Task',
      JSONPath: 'JSON-path',
      filterable: 'Filtering',
      sortable: 'Ordering',
      localizedLabel: 'Title (%{locale})',
      localizedName: 'Nimi (%{locale})',
      editable: 'Show Edit-button',
    },
    menu: {
      database: 'Database',
      language: 'Kielivalikko, language menu',
    },
    resource: {
      userTask: 'Tasks',
      comment: 'Comment',
      processDefinition: 'Processes',
      decisionDefinition: 'Decisions',
      processInstance: 'Running',
    },
    notification: {
      ok: 'Ok',
      updated: 'Record saved',
      openConfidentialFailed: 'Unable to open confidential field',
    },
    validation: {
      invalidDate: 'Invalid date',
      tooManyRows: 'Only one row allowed. |||| Only %{smart_count} rows allowed.',
      notEnoughRows: 'You must add at least one row. |||| You must add at least %{smart_count} rows.',
      maxFileSize: 'Maximum file size is 1 MB. |||| Maximum file size is %{smart_count} MB.',
    },
    vocabulary: {
      user: 'User',
      group: 'Group',
    },
    ui: {
      title: 'Vasara',
      guestForm: 'Guest sign in',
      subtitle: SUBTITLE_EN,
      privacyNotice: PRIVACY_NOTICE_EN,
      privacyNoticeLabel: 'Privacy notice',
    },
    guest: {
      title: 'Login',
      welcome: 'Welcome',
      instructions:
        'You can request a personal login link to access this service by filling out and submitting the form below.',
      tos: 'Terms of Service',
      privacyNoticeIntro:
        'By using the service, you agree to the storage and processing of information collected by the service in accordance with the privacy notice. Also, see',
      consent: 'I accept the terms of service for the use of the service.',
      processDefinitionKey: 'Login Request',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      emailHelper: 'Your personal login link will be sent to this email address.',
      save: 'Submit Request',
      success: 'Request received. Please check your email.',
      error: 'Request failed. Check the form selections and try again later.',
    },
  },
};

const messages = {
  fi: customFinnishMessages as TranslationMessages,
  en: customEnglishMessage as TranslationMessages,
};

export type Locale = 'fi' | 'en';

export const locales: Locale[] = ['fi', 'en'];

export const localeChoices: Choice[] = [
  { id: 'fi', name: 'Suomi' },
  { id: 'en', name: 'English' },
];

export const DEFAULT_LOCALE: Locale = 'en';

export const resolveLocale = (defaultLocale: string) => {
  const stored = localStorage.getItem('locale');
  const locale = stored ? stored : resolveBrowserLocale(DEFAULT_LOCALE);
  for (const choice of localeChoices) {
    if (locale === choice.id) {
      return choice.id;
    }
  }
  return DEFAULT_LOCALE;
};

const i18nProvider = polyglotI18nProvider((locale: string) => {
  localStorage.setItem('locale', locale);
  if (messages[locale as Locale]) {
    return messages[locale as Locale];
  } else {
    return messages.en;
  }
}, resolveLocale(DEFAULT_LOCALE));

export default i18nProvider;
