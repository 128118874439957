import get from 'lodash/get';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  NumberInput,
  SimpleFormIterator,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

import DateInput from './DateInput';
import VaultTextReadOnlyInputPlain from './VaultTextReadOnlyInputPlain';
import YesNoInput from './YesNoInput';

const getInputComponent = (value: any) => {
  if (value === null) {
    return TextInput;
  }
  if (Array.isArray(value)) {
    if (!!value.length && typeof value[0] === 'object') {
      return JSONBInput;
    } else {
      return AutocompleteArrayInput;
    }
  } else if (typeof value === 'object') {
    return JSONBInput;
  }
  switch (typeof value) {
    case 'boolean':
      return YesNoInput;
    case 'number':
      return NumberInput;
    case 'string':
      if (value === 'true' || value === 'false') {
        // Fix issues where boolean was saved as a string
        return YesNoInput;
      } else if (value.match(/^\d{4}-\d{2}-\d{2}T?.*/)) {
        return DateInput;
      } else if (value.startsWith('vault:')) {
        return VaultTextReadOnlyInputPlain;
      } else {
        return TextInput;
      }
    default:
      return TextInput;
  }
};

interface JSONBInputProps {
  source: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
}

const JSONBInput: React.FC<JSONBInputProps> = ({ source, label, className, fullWidth }) => {
  const record = useRecordContext();
  const content = get(record, source) || {};
  const keys = content['@order'] || Object.keys(content).sort((a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0));

  const isFullWidth = fullWidth ?? true;
  return Array.isArray(content) ? (
    <ArrayInput label={label} source={source} fullWidth={true}>
      <SimpleFormIterator disableAdd={true} inline>
        <JSONBInput source={''} fullWidth={false} />
      </SimpleFormIterator>
    </ArrayInput>
  ) : (
    <>
      {keys.map((key: string, idx: number) => {
        const InputComponent = getInputComponent(get(record, source)[key]);
        if (InputComponent === ArrayInput) {
          return (
            <ArrayInput key={key} source={`${source}[${idx}]`}>
              <SimpleFormIterator disableAdd={true} inline>
                <JSONBInput source={`${source}[${idx}]`} />
              </SimpleFormIterator>
            </ArrayInput>
          );
        } else if (InputComponent === AutocompleteArrayInput) {
          return (
            <FormDataConsumer key={key} subscription={{ values: true }}>
              {({ formData }) => {
                const value = get(formData, source)[key] || [];
                const choices = value.map((choice: string) => {
                  return {
                    id: choice,
                    name: choice,
                  };
                });
                return (
                  <AutocompleteArrayInput
                    className={className}
                    source={`${source}.${key}`}
                    label={key}
                    choices={choices}
                    matchSuggestion={() => true}
                    setFilter={(searchText: string) => {
                      if (searchText) {
                        while (choices.length > value.length) {
                          choices.pop();
                        }
                        choices.push({
                          id: searchText,
                          name: searchText,
                        });
                      }
                    }}
                    fullWidth={isFullWidth}
                    helperText={false}
                  />
                );
              }}
            </FormDataConsumer>
          );
        }

        if (InputComponent === TextInput) {
          return (
            <TextInput
              key={key}
              source={`${source}.${key}`}
              label={key}
              fullWidth={isFullWidth}
              multiline={true}
              helperText={false}
            />
          );
        }

        if (InputComponent === NumberInput) {
          return (
            <NumberInput
              key={key}
              source={`${source}.${key}`}
              label={key}
              fullWidth={isFullWidth}
              min={0}
              validate={[required()]}
              helperText={false}
            />
          );
        }

        return (
          <InputComponent
            key={key}
            source={`${source}.${key}`}
            label={key}
            fullWidth={isFullWidth}
            helperText={false}
          />
        );
      })}
    </>
  );
};

JSONBInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default JSONBInput;
