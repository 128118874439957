import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  DateField as RADateField,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import RADateInput from '../../Components/DateInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const DateField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                    helperText={false}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <AutocompleteArrayInput
              id={`${props.inputName}-sources`}
              label="vasara.form.sources"
              source={`${props.inputName}.sources`}
              choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
              validate={validateRequired}
              fullWidth={true}
              helperText={false}
            />
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
              />

              {/*<BooleanInput*/}
              {/*  id={`${props.inputName}-confidential`}*/}
              {/*  label="vasara.form.confidential"*/}
              {/*  source={`${props.inputName}.confidential`}*/}
              {/*  defaultValue={false}*/}
              {/*  className={classes.floatLeft}*/}
              {/*/>*/}

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

export const DateInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const formData = form.watch();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? get(formData, dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  const context: Record<string, any> = Object.fromEntries(
    variables.map((variable: any) => {
      return get(formData, variable.source) !== undefined
        ? [variable.id, get(formData, variable.source)]
        : [variable.id, get(formData, `${schema.id}:${variable.source}`)];
    })
  );

  if (dependencyName) {
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.readonly) {
    const fieldValue = get(formData, schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <RADateField record={formData} label={label} source={schema.id} locales="fi-FI" fullWidth={fullWidth} />
      </Labeled>
    );
  }

  return !!schema.disabled ? (
    <TextInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? false) || false}
      source={schema.id}
      validate={schema.required ? [required()] : []}
      multiline={false}
      parse={(v: string) => v}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  ) : (
    <RADateInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      source={schema.id}
      validate={schema.required ? [required()] : []}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

export const DateInput = React.memo(DateInputImpl);
export default React.memo(DateField);
