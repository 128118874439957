import SaveAlt from '@mui/icons-material/SaveAlt';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Show, SimpleShowLayout, TopToolbar, useRecordContext } from 'react-admin';

import BackButton from '../../../Components/BackButton';
import DmnField from '../../../Components/DmnField';
import TextFileField from '../../../Components/TextFileField';

const StyledTextFileField = styled(TextFileField)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& a': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    display: 'inline-block',
    marginLeft: '-2em',
    paddingLeft: '2.5em',
  },
}));

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const CustomTitle: React.FC<{}> = () => {
  const record = useRecordContext<{ name: string }>();
  return <span>{record.name}</span>;
};

const DecisionDefinitionShow: React.FC<{}> = () => {
  return (
    <Show emptyWhileLoading title={<CustomTitle />} actions={<CustomActions />}>
      <SimpleShowLayout>
        <StyledTextFileField source="diagram" title="name" filename="resource" contentType="application/xml">
          <SaveAlt />
        </StyledTextFileField>
        <DmnField source="diagram" />
      </SimpleShowLayout>
    </Show>
  );
};

export default DecisionDefinitionShow;
