import ELK, { ElkNode, ElkPrimitiveEdge } from 'elkjs/lib/elk.bundled.js';
import { Elements, isNode } from 'react-flow-renderer';

const DEFAULT_WIDTH = 0;
const DEFAULT_HEIGHT = 0;

const elk = new ELK({
  workerUrl: './node_modules/elkjs/lib/elk-worker.min.js',
  defaultLayoutOptions: {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
  },
});

const createGraphLayout = async (elements: Elements, root: string): Promise<Elements> => {
  const nodes: ElkNode[] = [];
  const edges: ElkPrimitiveEdge[] = [];

  elements.forEach(el => {
    if (isNode(el)) {
      nodes.push({
        id: el.id,
        width: el.__rf?.width ?? DEFAULT_WIDTH,
        height: el.__rf?.height ?? DEFAULT_HEIGHT,
      });
    } else {
      edges.push({
        id: el.id,
        target: el.target,
        source: el.source,
      });
    }
  });

  const newGraph = await elk.layout({
    id: 'root',
    children: nodes,
    edges: edges,
  });

  return elements.map(el => {
    if (isNode(el)) {
      const node = newGraph?.children!?.find(n => n.id === el.id);
      if (node?.x && node?.y && node?.width && node?.height) {
        el.position = {
          x: (node.x + node.width) * 1.4,
          y: (node.y + node.height) * 1.4,
        };
      }
    }
    return el;
  });
};

export default createGraphLayout;
