import React, { useContext } from 'react';
import { CreateButton, Datagrid, List, TextField, TopToolbar, useTranslate } from 'react-admin';

import BackButton from '../../../Components/BackButton';
import Settings from '../../../Settings';
import { PAGE_SIZE } from '../../../util/constants';

const CustomActions: React.FC<{}> = () => {
  const settings = useContext(Settings);
  return (
    <TopToolbar>
      <BackButton redirect={'/'} />
      {settings.isLite ? null : <CreateButton label="ra.action.create" resource="DecisionDefinition" />}
    </TopToolbar>
  );
};

const DecisionDefinitionList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filterDefaultValues={{ latest: true }}
      title={translate('vasara.view.decisionDefinitionList')}
      exporter={false}
      perPage={PAGE_SIZE}
      actions={<CustomActions />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" label={translate('vasara.column.decisionDefinitionName')} />
      </Datagrid>
    </List>
  );
};

export default DecisionDefinitionList;
