import SlideshowIcon from '@mui/icons-material/Slideshow';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { Button, Form, FormDataConsumer } from 'react-admin';

import FormBuilderForm, { FormProps } from './Form';

const ModalStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2, // To be able to see dropdown suggestions since RaAutocompleteSuggestionList-suggestionsContainer has z-index: 2
};

const PaperStyle: React.CSSProperties = {
  padding: '2rem 1rem',
  minWidth: '50%',
};

const Preview: React.FC<FormProps> = ({ title, toolbarRef }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button size="small" startIcon={<SlideshowIcon />} label="vasara.form.preview" onClick={handleOpen} />
      <Modal style={ModalStyle} open={openModal} onClose={handleClose}>
        <Paper style={PaperStyle} elevation={3} className="VasaraForm-preview">
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }: any) => (
              <Form onSubmit={() => {}} defaultValues={{ schema: (formData?.schema ?? []) || [] }}>
                <FormBuilderForm title={title} toolbarRef={toolbarRef} />
              </Form>
            )}
          </FormDataConsumer>
        </Paper>
      </Modal>
    </>
  );
};

export default Preview;
