import HourclassBottom from '@mui/icons-material/HourglassBottom';
import ContentSave from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/UploadFile';
import { Dialog, DialogContent } from '@mui/material';
import { Button } from 'ra-ui-materialui';
import React, { useState } from 'react';
import { FileField, FileInput, SaveButton, SimpleForm, Toolbar, required, useCreate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { xlsx_import_request, xlsx_import_response } from '../DataProviders/Actions/types';
import { XlsxExportSheet } from './XlsxExportLink';

export interface XlsxImportSheet extends XlsxExportSheet {
  isFixed: boolean; // when true, no new rows should be added
  maxRows: number; // when set, only the first maxRows rows are imported
}

export interface XlsxImportLinkProps {
  sheets: Record<string, XlsxImportSheet>; // map field ids to sheet spec
}

export interface XlsxImportToolbarProps {
  onCancel: () => void; // allow closing the dialog by its caller
  isImporting: boolean; // style import button on save
}
const XlsxImportToolbar: React.FC<XlsxImportToolbarProps> = ({ onCancel, isImporting }) => {
  // XXX: HourClassBottom is a workaround for the default CircularProgress being invisible
  return (
    <Toolbar>
      <SaveButton
        label={isImporting ? 'vasara.action.importing' : 'vasara.action.import'}
        icon={isImporting ? <HourclassBottom /> : <ContentSave />}
        disabled={isImporting}
      />
      <Button sx={{ marginLeft: '1rem' }} label="ra.action.cancel" variant="text" onClick={onCancel} />
    </Toolbar>
  );
};

export const XlsxImportLink: React.FC<XlsxImportLinkProps> = ({ sheets }) => {
  const [isOpen, setOpen] = useState(false);
  const [isImporting, setImporting] = useState(false);
  const [doCreate] = useCreate();
  const form = useFormContext();

  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            reject(error);
          },
        }
      );
    });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImport = (values: any) => {
    setImporting(true);
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const base64 = (reader.result as string).split(',')[1];
        const req: xlsx_import_request = {
          base64,
        };
        const res: xlsx_import_response = await create('xlsx_import', req);
        Object.keys(sheets).forEach(fieldId => {
          const { name: sheetName, columns: sheetColumns, isFixed, maxRows } = sheets[fieldId];
          if (!res.sheets[sheetName] || res.sheets[sheetName].length < 2) {
            return;
          }
          const value = form.getValues(fieldId) || [];
          const columnNames = res.sheets[sheetName][0];
          for (let i = 1; i < res.sheets[sheetName].length; i++) {
            const row = res.sheets[sheetName][i];
            const rowValues = row.reduce((acc, cur, idx) => {
              return sheetColumns.includes(columnNames[idx]) ? { ...acc, [columnNames[idx]]: cur } : acc;
            }, {});
            if (value.length > i - 1) {
              value[i - 1] = { ...value[i - 1], ...rowValues };
            } else if (!isFixed && (maxRows < 1 || value.length < maxRows)) {
              value.push(rowValues);
            }
          }
          // XXX: setValue does not trigger re-rendering unless we do "state change"
          form.setValue(fieldId, value, { shouldValidate: false });
          form.setValue(fieldId, value, { shouldValidate: true });
        });
      } finally {
        handleClose();
        setImporting(false);
      }
    };
    reader.readAsDataURL(values.file.rawFile);
  };

  return (
    <>
      <Button onClick={handleClick} label="vasara.action.import">
        <UploadIcon />
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <SimpleForm
            record={{}}
            onSubmit={handleImport}
            toolbar={<XlsxImportToolbar onCancel={handleClose} isImporting={isImporting} />}
          >
            <FileInput
              source="file"
              validate={required()}
              label="vasara.form.tableXlsxFileToImport"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
