import { makeStyles } from '@mui/styles';
import format from 'date-fns/format';
import fi from 'date-fns/locale/fi';
import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Link } from 'react-router-dom';

import { toLabel } from '../../util/helpers';

const useStyles = makeStyles({
  node: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '7pt',
  },
  nodeTitle: {
    fontSize: '1.1rem',
    position: 'relative',
    padding: '8px 32px',
    flexGrow: 1,
    color: 'black',
    textAlign: 'center',
  },
  nodeWrapper: {
    padding: '1em',
    background: '#fafafa',
    flexGrow: 1,
    backgroundColor: '#f5dfac',
    border: '1px solid #666666',
    boxShadow: (nodeProps: NodeProps) =>
      `${
        nodeProps.selected
          ? '6px 6px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
          : 'none'
      }`,
    minHeight: '80px',
    minWidth: '250px',
    maxWidth: '500px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  nodeContent: {
    flexGrow: 1,
  },
  field: {
    marginBottom: '1rem',
  },
  fieldLabel: {
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  fieldBody: {},
  footer: {
    '& > .update-time': {
      marginTop: '1rem',
      color: 'grey',
      textAlign: 'right',
      '& > span': {
        fontSize: '0.7rem',
      },
    },
  },
  handle: {
    width: '1px', // Does not work
    height: '1px',
    margin: 'auto',
    background: '#ddd',
    border: 'none',

    '& left': {
      left: '-4px',
    },
    '& right': {
      right: '-1px',
      top: 'auto',
    },
  },
});

const EntityNode: React.FC<NodeProps> = nodeProps => {
  const classes = useStyles(nodeProps);
  const parent = nodeProps.data.parent;
  const child = nodeProps.data.child;
  const record = nodeProps.data.record;
  const node = nodeProps.data.node;
  const updatedAt = format(new Date(record.updated_at), 'dd-MM-yyyy HH:mm:ss', { locale: fi });

  return (
    <div className={classes.node}>
      <div className={classes.nodeTitle}>
        {labelFromRecord(node)} {parent ? '(parent)' : child ? '(child)' : ''}
      </div>
      <div className={classes.nodeWrapper}>
        <Handle
          type="target"
          id={nodeProps.id + '_target'}
          position={Position.Left}
          className={`${classes.handle} left`}
        />
        <div className={classes.nodeContent}>
          <div className={classes.field}>
            <div className={classes.fieldLabel}>Nimi</div>
            <div className={classes.fieldBody}>
              <Link to={{ pathname: `/${node.name}/${record.id}/show/relations` }}>{record.name}</Link>
            </div>
          </div>
          {record.description && (
            <div className={classes.field}>
              <div className={classes.fieldLabel}>Kuvaus</div>
              <div className={classes.fieldBody}>{record.description}</div>
            </div>
          )}
          <div className={classes.footer}>
            <div className="update-time">
              <span>Updated: {updatedAt}</span>
            </div>
          </div>
        </div>
        <Handle
          type="source"
          id={nodeProps.id + '_source'}
          position={Position.Right}
          className={`${classes.handle} right`}
        />
      </div>
    </div>
  );
};

const labelFromRecord = (record: any, defaultValue?: string): string => {
  const description = record.description;

  if (!description || description.startsWith('columns and') || description.startsWith('A computed field,')) {
    return defaultValue || toLabel(record.name);
  } else {
    return description
      .split('\n')[0]
      .replace(/#\d{2,3}/g, '')
      .replace(/^\s+|\s+$/g, '');
  }
};

export default memo(EntityNode);
