import gql from 'graphql-tag';
import { CreateParams, GetListParams, GetManyReferenceParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

interface GetOneCommentParams extends GetOneParams {
  taskId: string;
}

const CommentQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          mutation MyMutation($id: String, $message: String!) {
            insert_camunda_Comments(id: $id, message: $message) {
              id
            }
          }
        `,
        variables: createParams.data,
        parseResponse: (response: any) => {
          return {
            data: response.data.insert_camunda_Comment,
          };
        },
      };
    case RaFetchType.GET_LIST:
    case RaFetchType.GET_MANY:
      const getListParams = params as GetListParams;
      const { filter } = getListParams;

      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Comments(id: $id) {
              id
              user {
                id
                firstName
                lastName
              }
              task {
                id
              }
              time
              message
              removalTime
              rootProcessInstance {
                id
              }
            }
          }
        `,
        variables: {
          id: filter.id,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Comments.slice(
              (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
              getListParams.pagination.page * getListParams.pagination.perPage
            ),
            total: response.data.camunda_Comments.length,
          };
        },
      };
    case RaFetchType.GET_MANY_REFERENCE:
      const getManyReferenceParams = params as GetManyReferenceParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Comments(id: $id) {
              id
              user {
                id
                firstName
                lastName
              }
              task {
                id
              }
              time
              message
              removalTime
              rootProcessInstance {
                id
              }
            }
          }
        `,
        variables: {
          id: getManyReferenceParams.id,
        },
        parseResponse: (response: any) => {
          response.data.camunda_Comments.sort((a: any, b: any) => {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          });
          return {
            data: response.data.camunda_Comments.slice(
              (getManyReferenceParams.pagination.page - 1) * getManyReferenceParams.pagination.perPage,
              getManyReferenceParams.pagination.page * getManyReferenceParams.pagination.perPage
            ),
            total: response.data.camunda_Comments.length,
          };
        },
      };
    case RaFetchType.GET_ONE:
      const getOneParams = (params as unknown) as GetOneCommentParams;
      return {
        query: gql`
          query MyQuery($id: String!, $commentId: String!) {
            camunda_Comment(id: $id, commentId: $commentId) {
              id
              user {
                id
                firstName
                lastName
              }
              task {
                id
              }
              time
              message
              removalTime
              rootProcessInstance {
                id
              }
            }
          }
        `,
        variables: {
          id: `${getOneParams.taskId}`,
          commentId: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Comment,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default CommentQuery;
