import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import * as React from 'react';
import { FC, memo } from 'react';
import { TextFieldProps, sanitizeFieldRestProps, useRecordContext, useTranslate } from 'react-admin';

const YesNoField: FC<TextFieldProps> = memo<TextFieldProps>(({ className, source, emptyText, ...rest }) => {
  const record = useRecordContext();
  const value = get(record, source as string);
  const translate = useTranslate();

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
      {value === true || value === 'true' ? translate('vasara.form.yes') : translate('vasara.form.no')}
    </Typography>
  );
});

export default YesNoField;
