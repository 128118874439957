import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { ResourceProps } from 'ra-core';

import UserTaskFormCreate from './UserTaskFormCreate';
import UserTaskFormEdit from './UserTaskFormEdit';

const resource: ResourceProps = {
  name: 'vasara_user_task_form',
  create: UserTaskFormCreate,
  edit: UserTaskFormEdit,
  icon: ChatBubbleIcon,
  options: { hideFromMenu: true },
};

export default resource;
