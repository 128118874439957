import Check from '@mui/icons-material/Check';
import { ResourceProps } from 'react-admin';

import messages from '../../../messages';
import UserTaskCreate from './UserTaskCreate';
import UserTaskEdit from './UserTaskEdit';
import UserTaskList from './UserTaskList';
import UserTaskShow from './UserTaskShow';

const resource: ResourceProps = {
  name: 'UserTask',
  edit: UserTaskEdit,
  show: UserTaskShow,
  create: UserTaskCreate,
  list: UserTaskList,
  icon: Check,
  options: { label: messages.translate('vasara.resource.userTask') },
};

export default resource;
