import React, { useContext } from 'react';
import { CoreLayoutProps, Layout, LayoutComponent, useSidebarState } from 'react-admin';

import Settings from '../Settings';
import AppBar from './AppBar';
import Menu from './Menu';

const CustomLayout: LayoutComponent = (props: CoreLayoutProps) => {
  const context = useContext(Settings);
  const [open] = useSidebarState();
  if (context.isEmbedded) {
    return (
      <Layout
        sx={{ '& .RaLayout-appFrame': { marginTop: '-24px' } }}
        {...props}
        menu={() => null}
        appBar={() => null}
        sidebar={() => null}
        className={open ? 'VasaraSideBarOpen' : 'VasaraSideBarClosed'}
      />
    );
  } else {
    return (
      <Layout {...props} menu={Menu} appBar={AppBar} className={open ? 'VasaraSideBarOpen' : 'VasaraSideBarClosed'} />
    );
  }
};

export default CustomLayout;
