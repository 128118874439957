import BuildIcon from '@mui/icons-material/Build';
import { useDataProvider, useNotify, useResourceContext } from 'ra-core';
import * as React from 'react';
import { useCallback } from 'react';
import { Button, ButtonProps, useCreate, useRedirect } from 'react-admin';

const EditEntitySettingsButton = (props: EditEntitySettingsProps) => {
  const { onClick, label = 'vasara.action.settings', icon = defaultIcon, ...rest } = props;
  const resource = useResourceContext(props);
  const redirect = useRedirect();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [create, { isLoading, error }] = useCreate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(
    event => {
      dataProvider
        .getList('vasara_entity_settings', {
          filter: { id: resource },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
        })
        .then(async ({ data }) => {
          if (data.length === 0) {
            create(
              'vasara_entity_settings',
              { data: { id: resource } },
              { onSuccess: () => redirect('edit', 'vasara_entity_settings', resource) }
            );
          } else {
            redirect('edit', 'vasara_entity_settings', data[0].id);
          }
        })
        .catch(error => {
          notify('ra.notification.http_error', { type: 'error' });
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [dataProvider, create, redirect, notify, onClick, resource]
  );

  return (
    <Button onClick={handleClick} label={label} {...sanitizeRestProps(rest)}>
      {icon}
    </Button>
  );
};

const defaultIcon = <BuildIcon />;

const sanitizeRestProps = ({ resource, ...rest }: Omit<EditEntitySettingsProps, 'label'>) => rest;

interface Props {
  icon?: JSX.Element;
  label?: string;
  onClick?: (e: Event) => void;
  resource?: string;
}

export type EditEntitySettingsProps = Props & ButtonProps;

export default EditEntitySettingsButton;
