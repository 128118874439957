import gql from 'graphql-tag';
import { CreateParams, CreateResult } from 'ra-core';
import { BuildQueryResult } from 'ra-data-graphql';

import { RaFetchType } from '../types';
import { ActionsQueryBuilder } from './types';

const CamundaGuestProcessInstanceQuery: ActionsQueryBuilder = (
  introspectionResults,
  raFetchType,
  resource,
  params
): BuildQueryResult => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          mutation MyMutation(
            $processDefinitionKey: String!
            $firstName: String!
            $lastName: String!
            $email: String!
          ) {
            insert_camunda_guest_ProcessInstance(
              processDefinitionKey: $processDefinitionKey
              firstName: $firstName
              lastName: $lastName
              email: $email
            ) {
              id
            }
          }
        `,
        variables: {
          processDefinitionKey: createParams.data.processDefinitionKey,
          firstName: createParams.data.firstName,
          lastName: createParams.data.lastName,
          email: createParams.data.email,
        },
        parseResponse: (response: any): CreateResult => {
          return {
            data: response.data.insert_camunda_guest_ProcessInstance
              ? response.data.insert_camunda_guest_ProcessInstance
              : {},
          };
        },
      } as any;
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default CamundaGuestProcessInstanceQuery;
