import { Description, Gavel, Inbox, ViewList, Work } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import {
  GetListResult,
  MenuProps,
  Menu as RaMenu,
  ResourceDefinition,
  useDataProvider,
  useResourceDefinitions,
  useSidebarState,
  useTranslate,
} from 'react-admin';

import { getUser } from '../Auth/authProvider';
import Settings from '../Settings';
import img from '../static/logo.png';
import { TRACER } from '../tracing';
import { isAllowedInMainMenu } from '../util/helpers';

// styled components used here because the old JSS style doesn't receive
// the theme provider in this particular component for some reason

const StyledRaMenu = styled(RaMenu)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: 0,
}));
const StyledFilterContainer = styled(Paper)(() => ({
  margin: '8px 4px 4px 4px',
  display: 'flex',
  alignItems: 'center',
}));
const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}));
const StyledImg = styled('img')(() => ({
  maxWidth: '200px',
  margin: '2em auto 2em auto',
}));
const StyledSpacer = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const Menu: React.FC<MenuProps> = ({ onMenuClick, logout }) => {
  const context = useContext(Settings);
  const translate = useTranslate();

  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [open] = useSidebarState();
  const allResources = useResourceDefinitions();
  const isGuest = (getUser()?.profile?.preferred_username || '').match(/^guest-.*/);

  const dataProvider = useDataProvider();
  const [resources, setResources] = useState<ResourceDefinition[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter]: [string | RegExp, any] = useState('');

  useEffect(() => {
    if (!allResources || loaded || loading) {
      return;
    }

    const candidateResources = Object.values(allResources).filter(
      resource =>
        resource &&
        !resource.name.match(/^_/) &&
        !resource.options.hideFromMenu &&
        isAllowedInMainMenu(resource.name) &&
        !['ProcessDefinition', 'DecisionDefinition', 'ProcessInstance', 'UserTask'].includes(resource.name)
    );
    if (!candidateResources.length) {
      return;
    }
    candidateResources.sort((a: any, b: any) => {
      if ((a?.options?.label ?? a.name) < (b?.options?.label ?? b.name)) {
        return -1;
      }
      if ((a?.options?.label ?? a.name) > (b?.options?.label ?? b.name)) {
        return 1;
      }
      return 0;
    });

    TRACER.local('component: Menu', () => {
      setLoading(true);
      dataProvider
        .getList('AvailableResources', {
          pagination: { page: 0, perPage: 1000 },
          sort: { field: 'id', order: 'asc' },
          filter: { resources: candidateResources.map(resource => resource.name) },
        })
        .then(({ data }: GetListResult) => {
          const available = data.map((item: any) => item.id);
          setResources(candidateResources.filter(resource => available.includes(resource.name)));
        })
        .catch((error: any) => {
          console?.error(error);
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    });
  }, [dataProvider, allResources, loaded, loading, resources]);

  return (
    <StyledRaMenu>
      {/*
       * main resources
       */}
      <RaMenu.Item
        key="UserTask"
        to="/UserTask"
        primaryText={translate('vasara.resource.userTask')}
        leftIcon={<Inbox />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {!isGuest ? (
        <RaMenu.Item
          key="ProcessDefinition"
          to="/ProcessDefinition"
          primaryText={translate('vasara.resource.processDefinition')}
          leftIcon={<Work />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ) : null}
      {context.isLite || isGuest ? null : (
        <RaMenu.Item
          key="DecisionDefinition"
          to="/DecisionDefinition"
          primaryText={translate('vasara.resource.decisionDefinition')}
          leftIcon={<Gavel />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {/*
       * filter input and database tables
       */}
      {resources.length > 0 ? <Divider /> : null}
      {resources.length > 5 && open ? (
        <StyledFilterContainer as="form">
          <StyledInput
            placeholder={translate('vasara.form.filterResources')}
            inputProps={{ 'aria-label': translate('vasara.form.helperText.filterResources') }}
            onChange={evt => {
              setFilter(new RegExp(evt.target.value, 'i'));
            }}
            onKeyDown={evt => {
              // prevent the default behavior of Menu
              // where key strokes will focus the item with the pressed key in the title
              evt.stopPropagation();
            }}
          />
          <SearchIcon />
        </StyledFilterContainer>
      ) : null}
      {resources
        .filter(
          (resource: ResourceDefinition) =>
            !filter || resource.name.match(filter) || (resource?.options?.label && resource.options.label.match(filter))
        )
        .map((resource: ResourceDefinition) => (
          <RaMenu.Item
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <ViewList />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {/*
       * bottom section
       */}
      {isXSmall && logout}
      <Divider />
      <RaMenu.Item
        leftIcon={<Description />}
        onClick={(e: any) => {
          window.location.href = translate('vasara.ui.privacyNotice');
          e.preventDefault();
          e.stopPropagation();
        }}
        to=""
        sidebarIsOpen={open}
        primaryText={translate('vasara.ui.privacyNoticeLabel')}
      />
      {open ? <StyledSpacer /> : null}
      {open ? <StyledImg src={img} alt="" /> : null}
    </StyledRaMenu>
  );
};

export default Menu;
