import { makeStyles } from '@mui/styles';
import { useRedirect } from 'ra-core';
import React, { useContext } from 'react';
import { Title, useTranslate } from 'react-admin';

import Settings from '../Settings';
import img from '../static/vasara.png';

const useStyles = makeStyles({
  img: {
    maxWidth: '100vw',
    width: '510px',
    margin: '0 auto',
  },
});

const Dashboard = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const settings = useContext(Settings);
  const redirect = useRedirect();
  if (settings.isLite) {
    redirect(`/UserTask`);
    return null;
  } else {
    return (
      <>
        <Title title={translate('vasara.ui.title')} />
        <img src={img} alt="Welcome" className={classes.img} />
      </>
    );
  }
};

export default Dashboard;
