import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Theme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import lodashGet from 'lodash/get';
import React from 'react';
import { ReferenceManyField, useRecordContext } from 'react-admin';

import BpmnField from './BpmnField';
import JSONBField from './JSONBField';
import TextFileField from './TextFileField';

const downloadStyles = ({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  zIndex: 1,
  '& a': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    display: 'inline-block',
    marginLeft: '-2em',
    paddingLeft: '2.5em',
  },
});
const StyledTextFileField = styled(TextFileField)(downloadStyles);
const StyledSpan = styled('span')(downloadStyles);

const inAccordion = (WrappedComponent: any, download?: boolean): React.FC<any> => (props: any) => {
  const { id, source } = props;
  const record = useRecordContext();
  const { defaultExpanded, disabled, ...rest } = props;
  let label;
  if (typeof props.label === 'function') {
    label = props.label(record);
  } else {
    label = lodashGet(record, props.label) || props.label;
    let match = label.match(/%{([^}]*)}/);
    while (match) {
      label = label.replace(match[0], lodashGet(record, match[1]) || match[1]);
      match = label.match(/%{([^}]*)}/);
    }
  }
  const prefix = `${id}-${source}`;
  const expanded = typeof defaultExpanded === 'function' ? defaultExpanded(record) : defaultExpanded;
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${prefix}-details`} id={`${prefix}-header`}>
        <Typography>{label}</Typography>
        {download ? (
          disabled ? null : (
            <StyledSpan onClick={e => e.stopPropagation()} onKeyDown={e => e.stopPropagation()}>
              <StyledTextFileField source="diagram" title="name" filename="resource" contentType="application/xml">
                <SaveAlt />
              </StyledTextFileField>
            </StyledSpan>
          )
        ) : null}
      </AccordionSummary>
      <AccordionDetails id={`${prefix}-details`}>{disabled ? null : <WrappedComponent {...rest} />}</AccordionDetails>
    </Accordion>
  );
};

export const AccordionJsonbField = inAccordion(JSONBField);
export const AccordionBpmnField = inAccordion(BpmnField);
export const AccordionBpmnFieldWithDownload = inAccordion(BpmnField, true);
export const AccordionReferenceManyField = inAccordion(ReferenceManyField);
