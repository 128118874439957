import { makeStyles } from '@mui/styles';
import lodashGet from 'lodash/get';
import { RecordContext, useRecordContext } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { FileField, Labeled } from 'react-admin';

import { textToFile } from '../util/helpers';

interface Props {
  label?: string;
  title?: string;
  filename: string;
  contentType: string;
  source: string;
  className?: string;
}

const useStyles = makeStyles({
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
});

const TextFileField: React.FC<Props> = props => {
  const { label, title, filename, contentType, source, className, ...rest } = props;
  const [file, setFile] = useState<any>(undefined);
  const classes = useStyles();
  const record = useRecordContext();

  useEffect(() => {
    if (file === undefined && !!lodashGet(record, source)) {
      try {
        setFile(textToFile(lodashGet(record, source), lodashGet(record, filename) || filename, contentType));
      } catch (e) {
        console.error(e);
      }
    }
  }, [file, source, filename, contentType, record]);

  const props_ = {
    ...rest,
    title: title ? lodashGet(record, title) || title : filename,
  };

  return file ? (
    label ? (
      <Labeled label={label} className={className} {...props_}>
        <RecordContext.Provider value={file}>
          <FileField source="src" title="title" target="_blank" download={file.title} />
        </RecordContext.Provider>
      </Labeled>
    ) : props.children ? (
      <div className={className ? className : classes.centered}>
        {props.children}
        <RecordContext.Provider value={file}>
          <FileField source="src" title="title" target="_blank" download={file.title} />
        </RecordContext.Provider>
      </div>
    ) : (
      <RecordContext.Provider value={file}>
        <FileField source="src" title="title" target="_blank" download={file.title} />
      </RecordContext.Provider>
    )
  ) : null;
};

export default TextFileField;
