import React from 'react';
import { Datagrid, List, TextField, useResourceContext } from 'react-admin';

import { toLabel } from '../../../util/helpers';

const ProcessInstanceList: React.FC<{}> = () => {
  const resource = useResourceContext();
  return (
    <List title={toLabel(resource)}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export default ProcessInstanceList;
