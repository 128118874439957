import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import {
  CreateContextProvider,
  CreateControllerProps,
  CreateProps,
  Loading,
  SaveButton,
  SimpleForm,
  Title,
  Toolbar,
  ToolbarProps,
  useCreateController,
  useDataProvider,
  useLocaleState,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import CancelButton from '../../../Components/CancelButton';
import UserTaskEditContext, { UserTask } from '../../../DataProviders/Camunda/UserTaskEditContext';
import FormBuilder from '../../../FormBuilder/FormBuilder';
import { TRACER, TracedError } from '../../../tracing';
import { normalizeAndMigrateForm } from '../../../util/helpers';

const CustomCreate: React.FC<CreateProps & CreateControllerProps> = props => {
  const createControllerProps = useCreateController(props);
  const { defaultTitle, save } = createControllerProps;

  const customSave: typeof save = useCallback(
    async (data, callbacks) => {
      if (!save) return;
      // Prevent array input artifacts from being persisted
      delete data.schemaIds;
      for (const fieldset of data.schema || {}) {
        delete fieldset.fieldsIds;
      }
      return await save(data, callbacks);
    },
    [save]
  );

  return (
    <CreateContextProvider value={{ ...createControllerProps, save: customSave }}>
      <Title title={props.title ?? defaultTitle} />
      {props.children}
    </CreateContextProvider>
  );
};

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
  delete: {
    marginLeft: 'auto',
  },
});

const UserTaskFormCreate: React.FC<{}> = () => {
  const location = useLocation();
  const { processDefinitionId, processDefinitionKey, processDefinitionVersion, userTaskId } = parse(location.search);
  const initialValues = {
    process_definition_key: processDefinitionKey,
    process_definition_version: processDefinitionVersion,
    user_task_id: userTaskId,
  };
  const [locale] = useLocaleState();
  const notify = useNotify();
  const redirect = useRedirect();
  const redirectTo = processDefinitionId ? `/ProcessDefinition/${processDefinitionId}/show` : 'list';

  const dataProvider = useDataProvider();
  const [userTask, setUserTask]: [UserTask | undefined, any] = useState();
  const [loadingContext, setLoadingContext]: [boolean, any] = useState(true);
  const [template, setTemplate]: [Record<any, any>, any] = useState({});
  const [loadingTemplate, setLoadingTemplate]: [boolean, any] = useState(true);
  const [error, setError]: [{ name: string; message: string } | undefined, any] = useState();

  useEffect(() => {
    TRACER.local('component: UserTaskFormCreate', () => {
      (async () => {
        await Promise.all([
          dataProvider
            .getOne('camunda_ProcessDefinition_UserTask', {
              id: processDefinitionId,
              meta: {
                filter: {
                  user_task_id: userTaskId,
                },
              },
            })
            .then(({ data }) => {
              setUserTask(data);
              setLoadingContext(false);
            })
            .catch(error => {
              setError(error);
              setLoadingContext(false);
            }),
          dataProvider
            .getList('vasara_user_task_form', {
              filter: {
                user_task_id: userTaskId,
                process_definition_key: processDefinitionKey,
                process_definition_version: {
                  format: 'hasura-raw-query',
                  value: { _lte: parseInt(processDefinitionVersion as string, 10) || 0 },
                },
              },
              sort: { field: 'process_definition_version', order: 'DESC' },
              pagination: { page: 1, perPage: 1 },
            })
            .then(({ data }) => {
              if (data.length) {
                setTemplate(data[0]);
              }
              setLoadingTemplate(false);
            })
            .catch(error => {
              setError(error);
              setLoadingTemplate(false);
            }),
        ]);
      })();
    });
  }, [dataProvider, processDefinitionId, processDefinitionKey, processDefinitionVersion, userTaskId]);

  if (loadingContext || loadingTemplate || !userTask) {
    return <Loading />;
  }

  if (error) {
    return <TracedError error={error} />;
  }

  // Remove ID from copied template, to allow save as new
  if (template['id']) {
    delete template['id'];
  }

  const defaults: any = {
    schema: [
      {
        id: uuid(),
        label: {},
        fields: [],
      },
    ],
  };
  defaults.schema[0].label[locale] = userTask?.name ?? '';

  normalizeAndMigrateForm(template);

  const showCreatedNotification = () => {
    notify('ra.notification.created', {
      type: 'info',
      messageArgs: { smart_count: 1 },
      undoable: false,
    });
  };

  const onSuccess = () => {
    showCreatedNotification();
    redirect(redirectTo);
  };

  const CustomToolbar: React.FC<ToolbarProps> = props => {
    const classes = useStyles();
    return (
      <Toolbar {...props}>
        <SaveButton
          label="ra.action.save"
          mutationOptions={{
            onSuccess: data => {
              // "save without closing", i.e. immediately open the edit view
              showCreatedNotification();
              redirect(`/vasara_user_task_form/${data.id}?processDefinitionId=${processDefinitionId}`);
            },
          }}
          type="button"
          className={classes.mr}
        />
        <SaveButton
          label="vasara.action.saveAndClose"
          mutationOptions={{ onSuccess }}
          type="button" // do not submit on enter
          className={classes.mr}
        />
        <CancelButton className={classes.mr} />
      </Toolbar>
    );
  };

  return (
    <UserTaskEditContext.Provider value={{ userTask }}>
      <CustomCreate title={userTask?.name ?? ''} mutationOptions={{ onSuccess }}>
        <SimpleForm
          defaultValues={{ ...defaults, ...template, ...initialValues }}
          toolbar={<CustomToolbar />}
          reValidateMode="onBlur"
        >
          <FormBuilder title={userTask?.name ?? ''} />
        </SimpleForm>
      </CustomCreate>
    </UserTaskEditContext.Provider>
  );
};

export default UserTaskFormCreate;
