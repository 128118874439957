import { UserTask as BpmnUserTask } from 'bpmn-moddle';
import { Context, createContext } from 'react';
import { RaRecord, RedirectionSideEffect, SaveHandler } from 'react-admin';

export type UserTask = BpmnUserTask & {
  // TODO: add type for this
  processDefinition: any;
  messages: { name: string }[];
};

export interface UserTaskEditContextContent {
  userTask: UserTask;
  redirect?: RedirectionSideEffect;
  resource?: string;
  saveDraft?: SaveHandler<RaRecord>;
  saveAndContinue?: SaveHandler<RaRecord>;
  saving?: boolean;
}

const UserTaskEditContext: Context<UserTaskEditContextContent> = createContext<UserTaskEditContextContent>(
  // there's no reasonable default value to give;
  // this should make a somewhat readable error if context is used outside of a provider
  (undefined as unknown) as UserTaskEditContextContent
);

export default UserTaskEditContext;
