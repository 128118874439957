import gql from 'graphql-tag';
import { CreateParams, GetOneParams, HttpError } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const RE_UUID = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const ProcessDefinitionQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          mutation MyMutation($name: String!, $resources: [camunda_DeploymentResource!]!, $migrate: Boolean!) {
            insert_camunda_ProcessDefinition(name: $name, resources: $resources, migrate: $migrate) {
              id
              key
              name
              description
              diagram
              resource
              suspended
              version
              versionTag
              startableInTasklist
            }
          }
        `,
        variables: createParams.data,
        parseResponse: (response: any) => {
          return {
            data: response.data.insert_camunda_ProcessDefinition,
          };
        },
      };
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return (getOneParams.id as string).match(/:/) || (getOneParams.id as string).match(RE_UUID)
        ? {
            query: gql`
              query MyQuery($id: String!) {
                camunda_ProcessDefinition(id: $id) {
                  id
                  key
                  name
                  description
                  diagram
                  resource
                  suspended
                  version
                  versionTag
                  startableInTasklist
                }
              }
            `,
            variables: {
              id: `${getOneParams.id}`,
            },
            parseResponse: (response: any) => {
              if (response.data.camunda_ProcessDefinition !== null) {
                return {
                  data: response.data.camunda_ProcessDefinition,
                };
              }
              return Promise.reject(new HttpError('404 Not found', 404));
            },
          }
        : {
            query: gql`
              query MyQuery($key: String!) {
                camunda_ProcessDefinitions(key: $key, latest: true) {
                  data {
                    id
                    key
                    name
                    description
                    diagram
                    resource
                    suspended
                    version
                    versionTag
                    startableInTasklist
                  }
                }
              }
            `,
            variables: {
              key: `${getOneParams.id}`,
            },
            parseResponse: (response: any) => {
              if (response.data.camunda_ProcessDefinitions.data.length) {
                return {
                  data: {
                    ...(response.data.camunda_ProcessDefinitions.data?.[0] || {}),
                    id: getOneParams.id,
                  },
                };
              }
              return Promise.reject(new HttpError('404 Not found', 404));
            },
          };

    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default ProcessDefinitionQuery;
