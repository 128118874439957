import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import round from 'lodash/round';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  RecordContext,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import ByteaFileField from '../../Components/ByteaFileField';
import ByteaFileInput from '../../Components/ByteaFileInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { Choice } from '../types';

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

const acceptChoices: Choice[] = [
  { id: 'text/csv', name: 'Comma-separated values (CSV) (.csv)' },
  { id: 'application/msword', name: 'Microsoft Word (.doc)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'Microsoft Word (OpenXML) (.docx)',
  },
  { id: 'image/jpeg', name: 'JPEG images (.jpeg, .jpg)' },
  { id: 'application/vnd.oasis.opendocument.presentation', name: 'OpenDocument presentation document (.odp)' },
  { id: 'application/vnd.oasis.opendocument.spreadsheet', name: 'OpenDocument spreadsheet document (.ods)' },
  { id: 'application/vnd.oasis.opendocument.text', name: 'OpenDocument text document (.odt)' },
  { id: 'image/png', name: 'Portable Network Graphics (.png)' },
  { id: 'application/pdf', name: 'Adobe Portable Document Format (PDF) (.pdf)' },
  { id: 'application/vnd.ms-powerpoint', name: 'Microsoft PowerPoint (.ppt)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'Microsoft PowerPoint (OpenXML) (.pptx)',
  },
  { id: 'application/rtf', name: 'Rich Text Format (RTF) (.rtf)' },
  { id: 'image/svg+xml', name: 'Scalable Vector Graphics (SVG) (.svg)' },
  { id: 'image/tiff', name: 'Tagged Image File Format (TIFF) (.tif, .tiff)' },
  { id: 'text/plain', name: 'Text, (generally ASCII or ISO 8859-n) (.txt)' },
  { id: 'application/vnd.ms-excel', name: 'Microsoft Excel (.xls)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'Microsoft Excel (OpenXML) (.xlsx)',
  },
  { id: 'application/xml, text/xml', name: 'XML document (.xml)' },
  { id: 'application/zip', name: 'ZIP archive (.zip)' },
];

const FileField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <AutocompleteArrayInput
                id={`${props.inputName}-accept`}
                label="vasara.form.mimeTypes"
                source={`${props.inputName}.accept`}
                choices={acceptChoices}
                fullWidth={true}
                helperText={false}
                defaultValue={[]}
              />
              <NumberInput
                id={`${props.inputName}-max`}
                label="vasara.form.maxFileSize"
                helperText="vasara.form.maxFileSizeHelp"
                source={`${props.inputName}.max`}
                format={(v: number) => round(v, 0)}
                parse={(v: string) => round(parseFloat(v), 0)}
                min={1}
                defaultValue={10}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteArrayInput
        id={`${props.inputName}-sources`}
        label="vasara.form.sources"
        source={`${props.inputName}.sources`}
        choices={props.sourceChoices}
        validate={validateRequired}
        fullWidth={true}
        helperText={false}
      />

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        fullWidth={true}
        helperText={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                fullWidth={true}
                helperText={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const maxFileSize = (max: number = 0) => (value: any) => {
  return max && value?.rawFile?.size && value.rawFile.size > max * 1024 * 1024
    ? { message: 'vasara.validation.maxFileSize', args: { smart_count: max } }
    : undefined;
};

export const FileInputImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];
  const accept = Array.isArray(schema?.accept) && schema.accept.length ? schema.accept.join(',') : undefined;
  const validator = maxFileSize(schema?.max || 0);

  const context: Record<string, any> = Object.fromEntries(
    variables.map((variable: any) => {
      return form.watch(variable.source) !== undefined
        ? [variable.id, form.watch(variable.source)]
        : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
    })
  );
  const record: any = {
    metadata: {},
  };
  record[schema.id] = form.getValues(schema.id);
  record.metadata[schema.id] = form.getValues(`${schema.id}.metadata`) ?? {};

  const dependencyActive =
    !dependencyName ||
    dependencyValue === undefined ||
    (!condition && dependencyValue) ||
    (condition && unary(condition, dependencyValue, context));
  if (!dependencyActive) {
    return null;
  }

  if (schema.readonly) {
    return (
      <RecordContext.Provider value={record}>
        <Labeled className={classes.fullWidth} label={label}>
          <ByteaFileField source={schema.id} />
        </Labeled>
      </RecordContext.Provider>
    );
  }

  return (
    <RecordContext.Provider value={record}>
      <ByteaFileInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        source={schema.id}
        validate={schema.required ? [required(), validator] : [validator]}
        fullWidth={true}
        accept={accept}
      />
    </RecordContext.Provider>
  );
};

export const FileInput = React.memo(FileInputImpl);
export default React.memo(FileField);
