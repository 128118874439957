import React from 'react';
import { Notification, NotificationPayload, useNotificationContext, useTranslate } from 'react-admin';

const CustomNotification: React.FC<{}> = () => {
  const translate = useTranslate();
  const { notifications, takeNotification, addNotification } = useNotificationContext();

  // terrible hacky way to modify the message and display time of the notification:
  // pop the notification out of the context,
  // change the message, and push it back into the context,
  // inserting a flag to avoid immediately modifying the same one again
  // and making an infinite loop.
  // as far as I can tell there's no nicer way
  // without making a fully custom Notification component
  // because RA's Notification insists on taking its content from the context

  if (
    notifications.length === 0 ||
    // @ts-ignore (intentionally using a property that doesn't exist on the type)
    notifications[0].messageModified
  ) {
    return <Notification />;
  }

  setTimeout(() => {
    const notification = takeNotification() as NotificationPayload;
    let message = notification.message;
    // Item is "lost" every time we complete a task and we want to hide it
    if (notification.message === 'ra.notification.item_doesnt_exist') {
      return <></>;
    }

    if (!!(notification?.message as any)?.message) {
      message = (notification.message as any).message;
      console.error(notification.message as any);
    } else if (`${notification.message}`.startsWith('vasara.')) {
      message = translate(`${notification.message}`);
    }

    const isImportant = notification.type === 'warning' || notification.type === 'error';
    const autoHideDuration = isImportant ? 5000 : 1000;
    addNotification({
      ...notification,
      message,
      notificationOptions: {
        ...notification.notificationOptions,
        autoHideDuration,
      },
      // @ts-ignore (see comments above)
      messageModified: true,
    });
  });

  // calling `addNotification` causes an immediate re-render
  // with new notification information
  // that hits one of the earlier cases returning <Notification />.
  // If we returned <Notification /> here it would pick up the wrong information
  return <></>;
};

export default CustomNotification;
