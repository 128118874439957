import { makeStyles } from '@mui/styles';
import { RichTextInput } from 'ra-input-rich-text';
import React from 'react';
import {
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  TopToolbar,
  required,
  useEditController,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import BackButton from '../../Components/BackButton';
import CancelButton from '../../Components/CancelButton';

const redirectAddress = (resource: string, record: any) =>
  `/${resource.replace('_comment', '')}/${record.entity_id}/show/comments`;

const useStyles = makeStyles({
  delete: {
    marginLeft: 'auto',
  },
  mr: {
    marginRight: '1em',
  },
});

const CustomToolbar: React.FC<ToolbarProps> = props => {
  const classes = useStyles();
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <Toolbar {...props}>
      <SaveButton className={classes.mr} />
      <CancelButton className={classes.mr} />
      <DeleteButton redirect={redirectAddress(resource, record)} className={classes.delete} />
    </Toolbar>
  );
};

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const EntityCommentEdit: React.FC<{}> = () => {
  const { resource, record } = useEditController();
  if (!record) {
    return null;
  }
  return (
    <Edit redirect={redirectAddress(resource, record)} actions={<CustomActions />} title="vasara.resource.comment">
      <SimpleForm toolbar={<CustomToolbar />}>
        {/*<TextInput source="subject" label="vasara.column.subject" validate={[required()]} />*/}
        <RichTextInput source="body" label="vasara.column.body" validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};

export default EntityCommentEdit;
