import { DataProvider } from 'ra-core';

import { TRACER } from '../tracing';

export interface MainDataProviderProps {
  camundaDataProvider: DataProvider;
  hasuraDataProvider: DataProvider;
  customDataProvider: DataProvider;
  actionsDataProvider: DataProvider;
}

export const buildMainDataProvider = (props: MainDataProviderProps): DataProvider => {
  // select the data provider to forward the request to based on the resource
  const dispatch = <ParamsType, ResultType>(
    resource: string,
    params: ParamsType,
    op: (dp: DataProvider) => (resource: string, params: ParamsType) => Promise<ResultType>
  ): Promise<ResultType> => {
    return TRACER.local(`resource: ${resource}`, () => {
      try {
        switch (resource) {
          case 'UserTask':
          case 'ProcessInstance':
          case 'ProcessDefinition':
          case 'DecisionDefinition':
          case 'camunda_HistoricActivityInstance':
          case 'camunda_HistoricProcessInstance':
          case 'camunda_ProcessDefinition_ListItem':
          case 'camunda_ProcessDefinition_UserTask':
          case 'camunda_ProcessInstance_UserTask':
          case 'camunda_UserTask_ListItem':
          case 'camunda_UserTask_Comment':
          case 'camunda_User':
          case 'camunda_Group':
            return op(props.camundaDataProvider)(resource, params);
          case 'xlsx_import':
          case 'xlsx_export':
          case 'vault_transit_encrypt':
          case 'vault_transit_decrypt':
          case 'camunda_guest_ProcessDefinition':
          case 'insert_camunda_guest_ProcessInstance':
            return op(props.actionsDataProvider)(resource, params);
          case 'AvailableResources':
            return op(props.customDataProvider)(resource, params);
          default:
            return op(props.hasuraDataProvider)(resource, params);
        }
      } catch (e) {
        console.log('FETCH FAILED');
        console.log(e);
        throw e;
      }
    });
  };

  return {
    getList: (resource, params) => dispatch(resource, params, dp => dp.getList),
    getOne: (resource, params) => dispatch(resource, params, dp => dp.getOne),
    getMany: (resource, params) => dispatch(resource, params, dp => dp.getMany),
    getManyReference: (resource, params) => dispatch(resource, params, dp => dp.getManyReference),
    update: (resource, params) => dispatch(resource, params, dp => dp.update),
    updateMany: (resource, params) => dispatch(resource, params, dp => dp.updateMany),
    create: (resource, params) => dispatch(resource, params, dp => dp.create),
    delete: (resource, params) => dispatch(resource, params, dp => dp.delete),
    deleteMany: (resource, params) => dispatch(resource, params, dp => dp.deleteMany),
  };
};
