import { useRedirect } from 'react-admin';

const UserTaskCreate = () => {
  const redirect = useRedirect();
  setTimeout(() => {
    redirect('/ProcessDefinition');
  });
  return null;
};

export default UserTaskCreate;
