import React, { useEffect } from 'react';
import { Error, ErrorProps } from 'react-admin';
import { BatchRecorder, ExplicitContext, Instrumentation, Tracer, createNoopTracer, jsonEncoder } from 'zipkin';
import { HttpLogger } from 'zipkin-transport-http';

import { getEnv } from './util/env';

//
// setup
//

const localServiceName = 'Vasara';

const setupTracer = (endpoint: string): Tracer => {
  const ctxImpl = new ExplicitContext();
  const tracer = new Tracer({
    ctxImpl,
    // replace this with `new ConsoleRecorder()` to debug tracing in the browser console.
    // alternatively, run zipkin locally and set the environment variable
    // REACT_APP_ZIPKIN_COLLECT_URL='http://localhost:9411/api/v2/spans'
    recorder: new BatchRecorder({
      logger: new HttpLogger({
        endpoint: endpoint,
        jsonEncoder: jsonEncoder.JSON_V2,
      }),
    }),
    localServiceName,
  });

  window.addEventListener('error', function (err) {
    const id = tracer.id;
    try {
      tracer.setId(tracer.createChildId());
      traceError(err.message, window.location.href, err.filename, err.lineno, err.colno, err.error);
    } finally {
      tracer.setId(id);
    }
  });

  return tracer;
};

const COLLECT_URL = getEnv('REACT_APP_ZIPKIN_COLLECT_URL');
/** zipkin-js tracer that reports performance data to Zipkin.
 *  does nothing if no Zipkin URL is configured. */
export const TRACER: Tracer = COLLECT_URL
  ? setupTracer(COLLECT_URL)
  : // createNoopTracer actually returns a Tracer, the TS types are wrong.
    // this has been fixed on git but not in the npm published version
    ((createNoopTracer() as unknown) as Tracer);

/** Create a fetch function that traces fetches
 *  and tags them with the given remote service name. */
export function createTracedFetch(remoteServiceName: string): typeof fetch {
  // adapted from the zipkin-instrumentation-fetch source code,
  // slightly modified to simplify the code and include more information
  const instrumentation = new Instrumentation.HttpClient({
    tracer: TRACER,
    serviceName: localServiceName,
    remoteServiceName,
  });
  return (input: Parameters<typeof fetch>[0], opts: Parameters<typeof fetch>[1] = {}) => {
    const url = input instanceof Request ? input.url : input.toString();
    const method = opts.method || 'GET';
    // for some reason these fetches don't seem to ever show up
    // as subspans of other spans defined in DataProvider and elsewhere.
    // This makes it hard to follow which request comes from which operation.
    // Regardless, traced fetches are needed to include the headers for tracing on the server side.
    return TRACER.local(`${method} ${url}`, () => {
      return new Promise((resolve, reject) => {
        const zipkinOpts = instrumentation.recordRequest(opts, url, method);
        const traceId = TRACER.id;

        fetch(input, zipkinOpts)
          .then(res => {
            TRACER.scoped(() => {
              instrumentation.recordResponse(traceId, res.status.toString());
            });
            resolve(res);
          })
          .catch(err => {
            TRACER.scoped(() => {
              instrumentation.recordError(traceId, err);
            });
            reject(err);
          });
      });
    });
  };
}

//
// errors
//

const traceError = (message: any, url: any, fileName: any, lineNo: any, columnNo: any, error: any) => {
  if (TRACER === undefined) return;

  TRACER.local('error', () => {
    TRACER.recordBinary('error.kind', 'RuntimeError');
    if (url !== null) {
      TRACER.recordBinary('http.url', url);
    }
    if (message !== null) {
      TRACER.recordBinary('message', message);
    }
    if (error?.stack) {
      TRACER.recordBinary('stack', error.stack || '');
    } else if (fileName !== null && lineNo !== null && columnNo !== null) {
      TRACER.recordBinary('stack', `${fileName}:${lineNo}:${columnNo}`);
    }
  });
};

// the type for the react-admin Error component is very strange.
export const TracedError: React.FC<{ error: ErrorProps['error'] }> = props => {
  const { error } = props;
  useEffect(() => {
    traceError(error.message, window.location.href, null, null, null, error);
  }, [error]);
  // TODO: what is resetErrorBoundary and why is it required here?
  return <Error resetErrorBoundary={() => {}} {...props} />;
};
