import { GetListParams, useDataProvider, useGetOne } from 'ra-core';
import { useCallback, useEffect, useState } from 'react';
import { Edge, Elements } from 'react-flow-renderer';

import { HasuraSchema } from '../DataProviders/types';
import { RE_AGGREGATE } from '../util/constants';
import { isAllowedInMainMenu } from '../util/helpers';
import { getTypeGraph } from './graph';
import { getSchema, typeNameToId } from './introspection';
import { SimplifiedTypeWithIDs } from './introspection/types';

const getRelationsGraph = (introspection: HasuraSchema, rootType: string, currentRecordId: string) => {
  const schema = getSchema(introspection);

  if (schema !== null) {
    return getTypeGraph(schema, rootType, false);
  }
};

export const useElements = (introspection: HasuraSchema, root: string, recordId: string) => {
  const dataProvider = useDataProvider();
  const [elements, setElements] = useState<Elements>([]);
  const rootType = typeNameToId(root || introspection.schema.queryType.name);
  const { data: record }: any = useGetOne(root, { id: recordId });

  const genElements = useCallback(async () => {
    const relationsGraph = getRelationsGraph(introspection, rootType, recordId);
    const rootNode = relationsGraph?.nodes[rootType];
    const relatedEntities: Array<string> = [rootType];
    const elementsArray: Elements = [];
    const m2mNodes: Map<string, string> = new Map();

    // Find all entities related to root entity.
    for (const fieldName in rootNode?.fields!) {
      const field = rootNode?.fields![fieldName];

      // Skip existing one
      if (relatedEntities.includes(field?.type?.id!)) {
        continue;
      } // M2M
      else if (
        field?.type.kind === 'OBJECT' &&
        field.typeWrappers.includes('LIST') &&
        field.type.name.startsWith('_')
      ) {
        relatedEntities.push(field.type.id);
        const intermediateEntityFieldNames = Object.keys(field.type.fields!);
        for (const intermediateFieldName of intermediateEntityFieldNames) {
          const intermediateField = field.type.fields![intermediateFieldName];
          if (
            intermediateField.type.kind === 'OBJECT' &&
            intermediateField.type.name !== root &&
            !intermediateField.name.match(RE_AGGREGATE)
          ) {
            relatedEntities.push(intermediateField.type.id);
            m2mNodes.set(intermediateField.type.id, field.type.id);
          }
        }
      } // O2M
      else if (
        field?.type.kind === 'OBJECT' &&
        field.typeWrappers.includes('LIST') &&
        !field.type.name.startsWith('_')
      ) {
        relatedEntities.push(field.type.id);
      } // M2O
      else if (field?.type.kind === 'OBJECT' && record?.[`${fieldName}_id`] && !field.type.name.startsWith('_')) {
        relatedEntities.push(field.type.id);
      }
    }

    // Filter unnecessary nodes
    const filteredEntities = relatedEntities.filter(nodeKey => {
      return (
        isAllowedInMainMenu(nodeKey.substring(nodeKey.lastIndexOf(':') + 1)) &&
        !nodeKey.substring(nodeKey.lastIndexOf(':') + 1).startsWith('_') &&
        !nodeKey.includes('_aggregate') &&
        !nodeKey.includes('_min_') &&
        !nodeKey.includes('_max_') &&
        !nodeKey.includes('_avg_') &&
        !nodeKey.includes('_sum_') &&
        !nodeKey.includes('_stddev_') &&
        !nodeKey.includes('_variance_') &&
        !nodeKey.includes('_var_')
      );
    });

    // Build elements
    for (const nodeKey of filteredEntities) {
      const node = relationsGraph?.nodes[nodeKey];
      let associativeType = null;

      if (m2mNodes.size && m2mNodes.has(node?.id!)) {
        associativeType = m2mNodes.get(node?.id as string);
      }

      if (nodeKey !== rootType) {
        const idField: string = root + '_id';
        const idValue: string = recordId;

        // M2O
        if (!associativeType && !!record?.[`${node?.name}_id`]) {
          const nodeName: string = node?.name!;
          const params = { filter: { id: record[`${node?.name}_id`] } } as GetListParams;

          const { data: nodeRecords } = await dataProvider.getList(nodeName, params);

          if (nodeRecords.length) {
            nodeRecords.forEach((record, idx) => {
              // node
              elementsArray.push({
                id: `${node?.id}_${idx}`,
                type: 'entityNode',
                data: {
                  parent: false,
                  child: false,
                  record: record,
                  nodeTitle: node?.name,
                  root: root,
                  rootRecordId: recordId,
                  node: node,
                },
                position: { x: 0, y: 0 },
              });

              // edge
              elementsArray.push({
                id: `${rootType}::${node?.name}-${node?.name}_${idx}`,
                type: 'floatingEdge',
                style: { strokeWidth: '0.1em' },
                arrowHeadType: 'arrow',
                source: rootType,
                target: node?.id + `_${idx}`,
                animated: false,
                label: node?.name,
                labelStyle: { fontSize: '0.4rem' },
                labelShowBg: true,
              } as Edge);
            });
          }
        }
        // O2M
        else if (!associativeType) {
          const nodeName: string = node?.name!;
          const params = { filter: { [idField]: idValue } } as GetListParams;

          const { data: nodeRecords } = await dataProvider.getList(nodeName, params);

          if (nodeRecords.length) {
            nodeRecords.forEach((record, idx) => {
              // node
              elementsArray.push({
                id: `${node?.id}_${idx}`,
                type: 'entityNode',
                data: {
                  parent: false,
                  child: false,
                  record: record,
                  nodeTitle: node?.name,
                  root: root,
                  rootRecordId: recordId,
                  node: node,
                },
                position: { x: 0, y: 0 },
              });

              // edge
              elementsArray.push({
                id: `${rootType}::${node?.name}-${node?.name}_${idx}`,
                type: 'floatingEdge',
                style: { strokeWidth: '0.1em' },
                arrowHeadType: 'arrow',
                source: rootType,
                target: node?.id + `_${idx}`,
                animated: false,
                label: node?.name,
                labelStyle: { fontSize: '0.4rem' },
                labelShowBg: true,
              } as Edge);
            });
          }
        } else {
          const intermediateNode: SimplifiedTypeWithIDs = relationsGraph?.nodes[associativeType]!;
          const params = { filter: { [idField]: idValue } } as GetListParams;

          // Get intermediate records
          const { data: intermediateRecords } = await dataProvider.getList(intermediateNode?.name!, params);
          const keyField = node?.name! + '_id';

          if (intermediateRecords.length && Object.keys(intermediateRecords[0]).includes(keyField)) {
            // Get m2m record for each intermediate field
            for (let key = 0; key < intermediateRecords.length; key++) {
              const { data: m2mRecord } = await dataProvider.getOne(node?.name!, {
                id: intermediateRecords[key][keyField],
              });
              elementsArray.push({
                id: `${node?.id}_${key}`,
                type: 'entityNode',
                data: {
                  parent: false,
                  child: false,
                  nodeTitle: node?.name,
                  record: m2mRecord,
                  root: root,
                  rootRecordId: recordId,
                  node: node,
                },
                position: { x: 0, y: 0 },
              });

              // edge
              elementsArray.push({
                id: `${rootType}::${node?.name}-${node?.name}_${key}`,
                type: 'floatingEdge',
                style: { strokeWidth: '0.1em' },
                arrowHeadType: 'arrow',
                source: rootType,
                target: `${node?.id}_${key}`,
                animated: false,
                label: node?.name,
                labelStyle: { fontSize: '0.4rem' },
                labelShowBg: true,
              } as Edge);
            }
          }
        }
      } else {
        const { data: rootRecord } = await dataProvider.getOne(root, { id: recordId });

        // Add current root node
        elementsArray.push({
          id: rootNode?.id!,
          type: 'entityNode',
          data: {
            parent: false,
            child: false,
            nodeTitle: root,
            record: rootRecord,
            root: root,
            rootRecordId: recordId,
            node: node,
          },
          position: { x: 0, y: 0 },
        });

        // Check for parent node
        const parentColumn = 'parent_' + root + '_id';
        const columns = Object.keys(rootRecord);

        if (columns.includes(parentColumn)) {
          if (rootRecord[parentColumn]) {
            const { data: parentRecord } = await dataProvider.getOne(root, { id: rootRecord[parentColumn] });
            // inherited entity
            if (parentRecord) {
              // Parent node
              elementsArray.push({
                id: `parent_${rootNode?.id}`,
                type: 'entityNode',
                data: {
                  parent: true,
                  child: false,
                  nodeTitle: `${root} (parent)`,
                  record: parentRecord,
                  root: root,
                  rootRecordId: recordId,
                  node: node,
                },
                position: { x: 0, y: 0 },
              });

              // edge
              elementsArray.push({
                id: `${rootType}::parent_${root}_id-${root}`,
                type: 'floatingEdge',
                style: { strokeWidth: '0.1em' },
                arrowHeadType: 'arrow',
                source: `parent_${rootNode?.id}`,
                target: rootNode?.id,
                animated: false,
                label: root,
                labelStyle: { fontSize: '0.4rem' },
                labelShowBg: true,
              } as Edge);
            }
          }

          // Check for inherited nodes.
          const params = { filter: { [`parent_${root}_id`]: recordId } } as GetListParams;
          const { data: inheritedNodes } = await dataProvider.getList(root, params);

          inheritedNodes.forEach((record, idx) => {
            // node
            elementsArray.push({
              id: `child_${rootNode?.name}_${idx}`,
              type: 'entityNode',
              data: {
                parent: false,
                child: true,
                record: record,
                nodeTitle: `${root} (child)`,
                root: root,
                rootRecordId: recordId,
                node: node,
              },
              position: { x: 0, y: 0 },
            });

            // edge
            elementsArray.push({
              id: `${rootType}::${root}-${root}_${idx}`,
              type: 'floatingEdge',
              style: { strokeWidth: '0.1em' },
              arrowHeadType: 'arrow',
              source: rootType,
              target: `child_${root}_${idx}`,
              animated: false,
              label: node?.name,
              labelStyle: { fontSize: '0.4rem' },
              labelShowBg: true,
            } as Edge);
          });
        }
      }
    }

    return elementsArray;
  }, [introspection, record, recordId, dataProvider, root, rootType]);

  useEffect(() => {
    (async () => {
      const elementsArray = await genElements();
      setElements(elementsArray);
    })();
  }, [recordId, genElements]);

  return elements;
};
