import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { Button, ButtonProps, RedirectionSideEffect, useRedirect } from 'react-admin';
import { useNavigate } from 'react-router';

export interface BackButtonProps extends ButtonProps {
  // specific path to redirect to.
  // only applies if `onClick` is not given.
  // if undefined, back button navigates back in browser history
  redirect?: RedirectionSideEffect;
}

const BackButton: React.FC<BackButtonProps> = props => {
  const navigate = useNavigate();
  const redirect = useRedirect();
  const { redirect: redirectTo, onClick, ...rest } = props;
  return (
    <Button
      {...rest}
      size="small"
      label="ra.action.back"
      onClick={evt => {
        if (onClick) {
          return onClick(evt);
        }
        if (redirectTo) {
          redirect(redirectTo);
          return;
        }
        navigate(-1);
      }}
    >
      <ArrowBackIcon />
    </Button>
  );
};

export default BackButton;
