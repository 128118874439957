// Related: https://docs.camunda.org/manual/latest/user-guide/process-engine/variables/#supported-variable-values

export const InputFieldTypes = {
  ARRAY: 'array',
  TABLE: 'table',
  BOOLEAN: 'boolean',
  DATE: 'date',
  FILE: 'file',
  HELP: 'help',
  NUMBER: 'integer', // TODO: migrate to 'number'
  SELECT: 'select',
  STRING: 'string',
  // GeoJSON map coordinates
  GEO: 'geo',
};

export const ActionFieldTypes = {
  BUTTON: 'button',
};

export const DecorativeFieldTypes = {
  HELP: 'help',
};

export const FieldTypes = {
  ...InputFieldTypes,
  ...DecorativeFieldTypes,
  ...ActionFieldTypes,
};
