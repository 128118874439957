import { makeStyles } from '@mui/styles';
import { evaluate } from 'feelin';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  UrlField,
  maxLength,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { getUserEmail, getUserFullName } from '../../Auth/authProvider';
import VaultTextField from '../../Components/VaultTextField';
import VaultTextInput from '../../Components/VaultTextInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const StringField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const { sourceChoices, readonlySourceChoices } = props;
  const combinedChoices = props.fieldChoices.concat(readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  // TODO: Filtering choice options is a good idea, but requires care, because camunda
  // and postgres report slightly different type names
  // const sourceChoices = props.sourceChoices.filter(
  //   choice => choice.name.endsWith('string)') || choice.name.endsWith('jsonb)') || choice.name.endsWith('text)')
  // );
  // const readonlySourceChoices = props.readonlySourceChoices.filter(
  //   choice => choice.name.endsWith('string)') || choice.name.endsWith('jsonb)') || choice.name.endsWith('text)')
  // );

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={[required()]}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  {/* This is technically a FEEL expression and this could be a free text field with an evaluation preview. */}
                  {readonly ? null : (
                    <SelectInput
                      id={`${props.inputName}-initialValue`}
                      label="vasara.form.initialValue"
                      helperText={false}
                      source={`${props.inputName}.initialValue`}
                      defaultValue=""
                      choices={[
                        {
                          id: 'profile.name',
                          name: 'vasara.form.initialValue.name',
                        },
                        {
                          id: 'profile.email',
                          name: 'vasara.form.initialValue.email',
                        },
                      ]}
                      validate={[]}
                      resettable={true}
                      fullWidth={true}
                    />
                  )}
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? readonlySourceChoices : sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                    helperText={false}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <>
              {/* This is technically a FEEL expression and this could be a free text field with an evaluation preview. */}
              {readonly ? null : (
                <SelectInput
                  id={`${props.inputName}-initialValue`}
                  label="vasara.form.initialValue.label"
                  helperText={false}
                  source={`${props.inputName}.initialValue`}
                  defaultValue=""
                  choices={[
                    {
                      id: 'profile.name',
                      name: 'vasara.form.initialValue.name',
                    },
                    {
                      id: 'profile.email',
                      name: 'vasara.form.initialValue.email',
                    },
                  ]}
                  validate={[]}
                  resettable={true}
                  fullWidth={true}
                />
              )}
              <AutocompleteArrayInput
                id={`${props.inputName}-sources`}
                label="vasara.form.sources"
                source={`${props.inputName}.sources`}
                choices={readonly ? readonlySourceChoices : sourceChoices}
                validate={validateRequired}
                fullWidth={true}
                helperText={false}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? (
            <SelectInput
              id={`${props.inputName}-behavior`}
              label="vasara.form.behavior"
              helperText={false}
              source={`${props.inputName}.behavior`}
              defaultValue="default"
              choices={[
                {
                  id: 'default',
                  name: 'vasara.form.stringBehavior.default',
                },
                {
                  id: 'link',
                  name: 'vasara.form.stringBehavior.link',
                },
                {
                  id: 'error',
                  name: 'vasara.form.stringBehavior.error',
                },
              ]}
              validate={validateRequired}
              fullWidth={true}
            />
          ) : (
            <>
              <BooleanInput
                id={`${props.inputName}-multiline`}
                label="vasara.form.multiline"
                source={`${props.inputName}.multiline`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />

              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />

              <BooleanInput
                id={`${props.inputName}-confidential`}
                label="vasara.form.confidential"
                source={`${props.inputName}.confidential`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
                helperText={false}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  multiline: {
    whiteSpace: 'pre-line',
  },
  error: {
    color: 'red',
    whiteSpace: 'pre-line',
  },
  readonlyVault: {
    display: 'block',
    marginTop: '0.5rem',
  },
});

const StringInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];
  const fullWidth = schema?.fullWidth ?? true;
  let initialValue = schema?.initialValue || '';
  if (initialValue) {
    const profile = {
      name: getUserFullName(),
      email: getUserEmail(),
    };
    try {
      initialValue = evaluate(initialValue, { profile });
    } catch (e) {
      console?.warn(e);
      initialValue = '';
    }
  } else {
    initialValue = '';
  }

  // TODO: Update this to use the new show field on the base of dependency helper
  // const showFieldContext = useDependencyContext(schema, form);
  // const showField = shouldShowField(schema.condition, showFieldContext);

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );

    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.readonly) {
    const fieldValue = form.getValues(schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    if (schema.confidential) {
      return (
        <Labeled label={label} className={classes.readonlyVault} style={style}>
          <VaultTextField source={schema.id} fullWidth={fullWidth} className={classes.multiline} />
        </Labeled>
      );
    }

    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        {schema.behavior === 'link' ? (
          <UrlField
            label={label}
            source={schema.id}
            fullWidth={fullWidth}
            target="_blank"
            className={classes.multiline}
          />
        ) : schema.behavior === 'error' ? (
          <TextField label={label} source={schema.id} fullWidth={fullWidth} className={classes.error} />
        ) : (
          <TextField label={label} source={schema.id} fullWidth={fullWidth} className={classes.multiline} />
        )}
      </Labeled>
    );
  }

  if (schema.confidential) {
    return (
      <VaultTextInput
        label={label}
        helperText={(schema.helperText?.[locale] ?? false) || false}
        source={schema.id}
        validate={schema.required ? [required(), maxLength(3980)] : [maxLength(3980)]}
        multiline={schema.multiline}
        parse={(v: string) => v}
        initialValue={initialValue}
        fullWidth={fullWidth}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }
  return (
    <TextInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? false) || false}
      source={schema.id}
      validate={schema.required ? [required(), maxLength(3980)] : [maxLength(3980)]}
      multiline={schema.multiline}
      parse={(v: string) => v}
      defaultValue={initialValue}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

export const StringInput = React.memo(StringInputImpl);
export default React.memo(StringField);
