import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import { RichTextInput } from 'ra-input-rich-text';
import React from 'react';
import {
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  TopToolbar,
  required,
  useResourceContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import BackButton from '../../Components/BackButton';
import CancelButton from '../../Components/CancelButton';

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
});

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};

const CustomToolbar: React.FC<ToolbarProps> = props => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton className={classes.mr} />
      <CancelButton />
    </Toolbar>
  );
};

const EntityCommentCreate: React.FC<{}> = () => {
  const location = useLocation();
  const { entity_id, business_key } = parse(location.search);
  const resource = useResourceContext();
  const entity_resource = resource.split('_comment')[0];
  const redirect = `/${entity_resource}/${entity_id}/show/comments`;
  let metadata: any = {};

  return (
    <Create redirect={redirect} title="vasara.resource.comment" actions={<CustomActions />}>
      <SimpleForm defaultValues={{ entity_id, business_key, metadata }} toolbar={<CustomToolbar />}>
        {/*<TextInput source="subject" validate={[required()]} />*/}
        <RichTextInput source="body" label="vasara.column.body" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default EntityCommentCreate;
