import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import React from 'react';
import { Button, ButtonProps, useCreate, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { useDataProvider, useTranslate } from 'react-admin';

export interface StartButtonProps {
  record: { key: string; startableInTasklist: boolean };
  named?: boolean;
  label?: string;
}

/** Start button with record given explicitly; can be used outside of record context. */
export const StartButtonManualContext: React.FC<StartButtonProps & ButtonProps> = ({
  record,
  named,
  label,
  ...props
}) => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const [start, { isLoading }] = useCreate(
    'ProcessInstance',
    { data: { processDefinitionKey: record.key } },
    {
      onSuccess: data => {
        if (!data.businessKey) {
          // Process has ended immediately.
          // TODO: Add notification
          return;
        }
        (async () => {
          const retries = 10;
          for (let i = 0; i < retries; i++) {
            await sleep(250 * i); // wait for task to change or disappear
            const results = await dataProvider.getList('camunda_UserTask_ListItem', {
              pagination: {
                page: 1,
                perPage: 1,
              },
              sort: {
                field: 'created',
                order: 'desc',
              },
              filter: { businessKey: data.businessKey },
            });
            if (results.total) {
              redirect('edit', '/UserTask', results.data[0].id);
              return;
            }
          }
          redirect('/UserTask');
        })();
      },
      onError: error => {
        notify(`${error}`, { type: 'error' });
      },
    }
  );

  return record.startableInTasklist ? (
    <Button
      label={label ? label : named ? translate('vasara.action.startNamed', record) : 'vasara.action.start'}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        start();
      }}
      id={`btn-start-${record?.key}`}
      disabled={isLoading}
      {...props}
    >
      <PlayCircleFilledWhiteIcon />
    </Button>
  ) : null;
};

/** Start button that takes its record from context. */
export const StartButton: React.FC<Omit<StartButtonProps, 'record'> & ButtonProps> = props => {
  const record = useRecordContext<{ key: string; startableInTasklist: boolean; [K: string]: any }>();
  return <StartButtonManualContext record={record} {...props} />;
};
