import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import round from 'lodash/round';
import set from 'lodash/set';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  NumberFieldProps,
  NumberField as RANumberField,
  NumberInput as RANumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { unary } from '../../util/feel';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const NumberField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          if (readonly) {
            return (
              <SelectInput
                id={`${props.inputName}-behavior`}
                label="vasara.form.behavior"
                helperText={false}
                source={`${props.inputName}.behavior`}
                defaultValue="default"
                choices={[
                  {
                    id: 'default',
                    name: 'vasara.form.integerBehavior.default',
                  },
                  {
                    id: 'percentage',
                    name: 'vasara.form.integerBehavior.percentage',
                  },
                  {
                    id: 'decimalPercentage',
                    name: 'vasara.form.integerBehavior.decimalPercentage',
                  },
                  {
                    id: 'euro',
                    name: 'vasara.form.integerBehavior.euro',
                  },
                ]}
                validate={validateRequired}
                fullWidth={true}
              />
            );
          }
        }}
      </FormDataConsumer>

      <RANumberInput
        id={`${props.inputName}-decimals`}
        label="vasara.form.decimals"
        helperText={false}
        source={`${props.inputName}.decimals`}
        format={(v: number) => round(v, 0)}
        parse={(v: string) => round(parseFloat(v), 0)}
        min={0}
        // maximum number of digits in Intl.NumberFormat
        max={20}
        defaultValue={0}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                    helperText={false}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <AutocompleteArrayInput
              id={`${props.inputName}-sources`}
              label="vasara.form.sources"
              source={`${props.inputName}.sources`}
              choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
              validate={validateRequired}
              fullWidth={true}
              helperText={false}
            />
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
              />

              {/*<BooleanInput*/}
              {/*  id={`${props.inputName}-confidential`}*/}
              {/*  label="vasara.form.confidential"*/}
              {/*  source={`${props.inputName}.confidential`}*/}
              {/*  defaultValue={false}*/}
              {/*  className={classes.floatLeft}*/}
              {/*/>*/}

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  clearLeft: {
    clear: 'left',
  },
});

const NumberInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const [hasFocus, setHasFocus] = useState(false);

  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const formData = form.watch();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  // formatting behaviors only apply to readonly fields
  const decimals = Math.min(parseInt(schema.decimals, 10), 20);
  const behaviors: Record<string, NumberFieldProps['options']> = {
    euro: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: !isNaN(decimals) ? decimals : 2,
    },
    percentage: {
      style: 'percent',
    },
    decimalPercentage: {
      style: 'percent',
    },
  };
  let fieldOptions: NumberFieldProps['options'] = {};
  if (schema.behavior && schema.behavior in behaviors) {
    fieldOptions = { ...fieldOptions, ...behaviors[schema.behavior] };
  }

  let format: any = undefined; // legacy behavior when no decimals set
  let parse = (v: string): number => parseInt(v, 10);
  if (!isNaN(decimals) && decimals > 0) {
    setTimeout(() => {
      // work around some weird react-admin bug
      format = (v: number) => (isNaN(v) ? v : hasFocus ? round(v, decimals) : round(v, decimals).toFixed(decimals));
    });
    parse = (v: string) => (isNaN(parseFloat(v)) ? 0 : round(parseFloat(v), decimals));
    // display only configured number of digits in readonly fields
    // even if the actual value has more
    fieldOptions = {
      ...fieldOptions,
      maximumFractionDigits: decimals,
    };
  }

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return get(formData, variable.source) !== undefined
          ? [variable.id, get(formData, variable.source)]
          : [variable.id, get(formData, `${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.readonly) {
    const fieldValue = get(formData, schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    // take the value from formData (which is possibly a string)
    // and make sure it's a float so NumberField's built-in formatting works
    const parseField = (formData: any) => {
      let parsedValue = parseFloat(get(formData, schema.id));
      if (isNaN(parsedValue)) {
        parsedValue = 0.0;
      }
      const formDataParsed = { ...formData };
      if (schema.behavior === 'percentage') {
        // Legacy percentage behavior renders integer 0-100 with percentage sign.
        // For usual percentage behavior, use 'decimalPercentage' behavior.
        // XXX: We don't remember the customer requirement for max 100 %.
        set(formDataParsed, schema.id, Math.min(parsedValue * 0.01, 1.0));
      } else {
        set(formDataParsed, schema.id, parsedValue);
      }
      return formDataParsed;
    };

    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <RANumberField
          record={parseField(formData)}
          label={label}
          source={schema.id}
          fullWidth={fullWidth}
          locales={['fi']}
          options={fieldOptions}
        />
      </Labeled>
    );
  }

  return (
    <RANumberInput
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      validate={schema.required ? [required()] : []}
      min={0}
      format={format}
      parse={parse}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      inputProps={hasFocus ? { lang: 'fi' } : { lang: 'en' }}
      step={!isNaN(decimals) && decimals > 0 ? 1.0 / 10 ** decimals : 'any'}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

export const NumberInput = React.memo(NumberInputImpl);
export default React.memo(NumberField);
