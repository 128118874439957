import { GetListParams } from 'ra-core';
import buildDataProvider, { BuildQueryFactory } from 'ra-data-graphql';

import client from '../HasuraClient';
import { RaFetchType } from '../types';
import AvailableResourcesQuery from './AvailableResourcesQuery';

const buildQuery: BuildQueryFactory = (introspectionResults: any) => (
  // string for type compatibility, converted later to RaFetchType enum
  raFetchType_: string,
  resource: string,
  params: GetListParams
) => {
  const raFetchType = raFetchType_ as RaFetchType;
  switch (resource) {
    case 'AvailableResources':
      return AvailableResourcesQuery(introspectionResults, raFetchType, resource, params);
    default:
      throw new Error(`Unknown resource ${resource}.`);
  }
};

const provider = async () => {
  return await buildDataProvider({
    buildQuery,
    // @ts-ignore TS somehow gets confused by ApolloClient<T> where T != unknown
    client,
  });
};

export default provider;
