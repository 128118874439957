import gql from 'graphql-tag';
import { GetListParams, GetListResult } from 'ra-core';

import { RaFetchType } from '../types';
import { QueryBuilder } from './types';

const AvailableResourcesQuery: QueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_LIST:
      const getListParams = params as GetListParams;
      return {
        query: gql(
          `
          query {` +
            (getListParams.filter?.resources || []).map(
              (resource: string) => `
            ${resource}(limit: 1) {
              id
            }
          `
            ) +
            `
          }
        `
        ),
        variables: {},
        parseResponse: (response: any): GetListResult => {
          const resources = Object.keys(response.data)
            .map((key: string) => [key, response.data[key].length])
            .filter(item => item[1]);
          return {
            data: resources.map(item => {
              return {
                id: item[0],
              };
            }),
            total: resources.length,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default AvailableResourcesQuery;
