import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { v4 as uuid } from 'uuid';

import { EnabledFieldTypes, FieldDefaultProps } from '../fields';
import { Choice } from '../types';

type Props = {
  appendField: (field: { id: string; [K: string]: any }) => void;
  sourceChoices: Choice[];
  readonlySourceChoices: Choice[];
  fieldChoices: Choice[];
  expand: () => void;
};

const AddFieldInput: React.FC<Props> = props => {
  const htmlId = uuid();
  const translate = useTranslate();
  const [selectedWidgetType, setSelectedWidgetType] = useState<string>('string');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedWidgetType(event.target.value);
  };

  const addNewWidget = (): boolean => {
    if (selectedWidgetType !== '') {
      props.appendField({ id: uuid(), ...FieldDefaultProps[selectedWidgetType as string] });
      return true;
    }
    return false;
  };

  const addNewFromClipboard = (text: string) => {
    const sources = props.readonlySourceChoices.map(choice => choice.id);
    const ids = props.fieldChoices.map(choice => choice.id).concat(sources);
    const push = (field: any, fieldIds: string[]) => {
      const allIds = ids.concat(fieldIds);
      field.sources = (field.sources ?? []).filter((source: string) => sources.indexOf(source) > -1);
      field.variables = (field.variables ?? []).filter((variable: any) => [allIds.indexOf(variable.source) > -1]);
      if (field.dependency) {
        if (allIds.indexOf(field.dependency) < 0) {
          field.dependency = null;
        }
      }
      props.appendField(field);
    };
    try {
      const data: any = JSON.parse(text);
      if (typeof data === 'object' && data.id && data.length === undefined) {
        data.id = uuid();
        push(data, [data.id]);
      }
      if (typeof data === 'object' && data.length && data.length > 0) {
        const ids = [];
        for (let field of data) {
          const id = uuid();
          text = text.replaceAll(field.id, id);
          ids.push(id);
        }
        for (let field of JSON.parse(text)) {
          if (typeof field === 'object' && field.id && field.length === undefined) {
            push(field, ids);
          }
        }
      }
    } catch (e) {}
  };

  return (
    <Grid container spacing={3} justifyContent="flex-start" alignContent="center" alignItems="center">
      <Grid item xs={7} md={3}>
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor={htmlId}>{translate('vasara.form.helperText.field')}</InputLabel>
          <Select
            inputProps={{ id: htmlId }}
            native
            value={selectedWidgetType}
            onChange={handleChange}
            fullWidth={true}
          >
            {EnabledFieldTypes.map(type => (
              <option key={type} value={type}>
                {translate(`vasara.form.${type}`)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => addNewWidget() && props.expand()}
        >
          {translate('vasara.action.add')}
        </Button>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label={translate('vasara.action.paste')}
          title={translate('vasara.action.paste')}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            (async () => {
              const data: string = await navigator.clipboard.readText();
              addNewFromClipboard(data);
            })();
            e.stopPropagation();
          }}
        >
          <InsertDriveFile />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AddFieldInput;
