import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  UpdateManyParams,
  UpdateParams,
} from 'ra-core';
import buildDataProvider, { BuildQueryFactory } from 'ra-data-graphql';

import { CamundaHasuraClient } from '../HasuraClient';
import { RaFetchType } from '../types';
import CommentQuery from './CommentQuery';
import DecisionDefinitionQuery from './DecisionDefinitionQuery';
import GroupQuery from './GroupQuery';
import HistoricActivityInstanceQuery from './HistoricActivityInstanceQuery';
import HistoricProcessInstanceQuery from './HistoricProcessInstanceQuery';
import ProcessDefinitionListItemQuery from './ProcessDefinitionListItemQuery';
import ProcessDefinitionQuery from './ProcessDefinitionQuery';
import ProcessDefinitionUserTaskQuery from './ProcessDefinitionUserTaskQuery';
import ProcessInstanceQuery from './ProcessInstanceQuery';
import ProcessInstanceUserTaskQuery from './ProcessInstanceUserTaskQuery';
import UserQuery from './UserQuery';
import UserTaskListItemQuery from './UserTaskListItemQuery';
import UserTaskQuery from './UserTaskQuery';

const buildQuery: BuildQueryFactory = (introspectionResults: any) => (
  // string for type compatibility, converted later to RaFetchType enum
  raFetchType_: string,
  resource: string,
  params:
    | GetListParams
    | GetManyParams
    | GetManyReferenceParams
    | UpdateParams
    | UpdateManyParams
    | CreateParams
    | DeleteParams
    | DeleteManyParams
) => {
  const raFetchType = raFetchType_ as RaFetchType;
  switch (resource) {
    case 'ProcessDefinition':
      return ProcessDefinitionQuery(introspectionResults, raFetchType, resource, params);
    case 'DecisionDefinition':
      return DecisionDefinitionQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_ProcessDefinition_ListItem':
      return ProcessDefinitionListItemQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_ProcessDefinition_UserTask':
      return ProcessDefinitionUserTaskQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_ProcessInstance_UserTask':
      return ProcessInstanceUserTaskQuery(introspectionResults, raFetchType, resource, params);
    case 'ProcessInstance':
      return ProcessInstanceQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_HistoricProcessInstance':
      return HistoricProcessInstanceQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_HistoricActivityInstance':
      return HistoricActivityInstanceQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_UserTask_ListItem':
      return UserTaskListItemQuery(introspectionResults, raFetchType, resource, params);
    case 'UserTask':
      return UserTaskQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_UserTask_Comment':
      return CommentQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_User':
      return UserQuery(introspectionResults, raFetchType, resource, params);
    case 'camunda_Group':
      return GroupQuery(introspectionResults, raFetchType, resource, params);
    default:
      throw new Error(`Unknown resource ${resource}.`);
  }
};

const provider = async (): Promise<DataProvider> => {
  return await buildDataProvider({
    buildQuery,
    // @ts-ignore TS somehow gets confused by ApolloClient<T> where T != unknown
    client: CamundaHasuraClient,
  });
};

export default provider;
