import React from 'react';
import { EditButton, Show, TopToolbar, useTranslate } from 'react-admin';

const UserTaskActions = ({ data, resource }: any) => {
  const translate = useTranslate();
  return (
    <TopToolbar>
      <EditButton record={data} label={translate('vasara.action.claim')} />
    </TopToolbar>
  );
};

const UserTaskTitle = ({ record }: any) => {
  return <span>{record.name}</span>;
};

const UserTaskShow = (props: any) => {
  return (
    <Show {...props} title={<UserTaskTitle />} actions={<UserTaskActions />}>
      {/*</SimpleShowLayout>*/}
    </Show>
  );
};

export default UserTaskShow;
