import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  Labeled,
  BooleanField as RABooleanField,
  BooleanInput as RABooleanInput,
  RadioButtonGroupInput,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  ValidationErrorMessage,
  choices,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { unary } from '../../util/feel';
import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const BooleanField: React.FC<CommonFieldProps> = props => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <RadioButtonGroupInput
        source={`${props.inputName}.inputType`}
        label="vasara.form.inputType"
        choices={[
          { id: 'toggle', name: 'vasara.form.toggle' },
          { id: 'radio', name: 'vasara.form.radio' },
        ]}
        defaultValue="radio"
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                    helperText={false}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <AutocompleteArrayInput
              id={`${props.inputName}-sources`}
              label="vasara.form.sources"
              source={`${props.inputName}.sources`}
              choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
              validate={validateRequired}
              fullWidth={true}
              helperText={false}
            />
          );
        }}
      </FormDataConsumer>

      <RABooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        className={classes.floatLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <RABooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                className={classes.floatLeft}
              />

              {/*<RABooleanInput*/}
              {/*  id={`${props.inputName}-confidential`}*/}
              {/*  label="vasara.form.confidential"*/}
              {/*  source={`${props.inputName}.confidential`}*/}
              {/*  defaultValue={false}*/}
              {/*  className={classes.floatLeft}*/}
              {/*/>*/}

              <RABooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                className={classes.floatLeft}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        className={classes.clearLeft}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  autoWidth: {
    width: 'auto',
  },
  clearLeft: {
    clear: 'left',
  },
});

interface MessageFuncParams {
  args: any;
  value: any;
  values: any;
}

type MessageFunc = (params: MessageFuncParams) => ValidationErrorMessage;

const mustBeSelected: MessageFunc = ({ args }) => ({
  message: 'ra.validation.true',
  args,
});

export const BooleanInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.readonly) {
    const fieldValue = form.watch(schema.id);
    if (typeof fieldValue !== 'boolean') {
      return null;
    }

    if (schema.inputType === 'toggle') {
      return (
        <Labeled label={label} className={fullWidth ? classes.fullWidth : fullWidth} style={style}>
          <RABooleanField label={label} source={schema.id} fullWidth={fullWidth} />
        </Labeled>
      );
    }
    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : ''} style={style}>
        <SelectField
          label={label}
          source={schema.id}
          fullWidth={fullWidth}
          choices={[
            { id: true, name: 'vasara.form.yes' },
            { id: false, name: 'vasara.form.no' },
          ]}
        />
      </Labeled>
    );
  }

  if (schema.inputType === 'toggle') {
    return (
      <RABooleanInput
        source={schema.id}
        label={label}
        className="VasaraMuiSwitch"
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        validate={schema.required ? [required('ra.validation.required'), choices([true], mustBeSelected)] : []}
        defaultValue={false}
        fullWidth={fullWidth}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }

  return (
    <RadioButtonGroupInput
      className={fullWidth ? classes.fullWidth : classes.autoWidth}
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      validate={schema.required ? [required('ra.validation.required')] : []}
      choices={[
        { id: true, name: 'vasara.form.yes' },
        { id: false, name: 'vasara.form.no' },
      ]}
      // RadioButtonGroupInput casts its value to string before saving,
      // so we need to convert it back to boolean to preserve the type we want
      parse={(value: string) => (value === 'true' ? true : false)}
      style={{ ...style, flexBasis: undefined }}
      disabled={!!schema.disabled}
    />
  );
};

export const BooleanInput = React.memo(BooleanInputImpl);
export default React.memo(BooleanField);
