import { Context, createContext } from 'react';

import { Settings as SettingsInterface } from './types';

const defaultSettings: SettingsInterface = {
  isLite: false,
  isEmbedded: false,
};

const Settings: Context<SettingsInterface> = createContext(defaultSettings);

export default Settings;
