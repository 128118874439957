import gql from 'graphql-tag';
import { GetListResult } from 'ra-core';
import { BuildQueryResult } from 'ra-data-graphql';

import { RaFetchType } from '../types';
import { ActionsQueryBuilder } from './types';

const CamundaGuestProcessDefinitionQuery: ActionsQueryBuilder = (
  introspectionResults,
  raFetchType,
  resource,
  params
): BuildQueryResult => {
  switch (raFetchType) {
    case RaFetchType.GET_LIST:
      return {
        query: gql`
          query {
            camunda_guest_ProcessDefinition {
              id
              key
              name
              description
              startableInTasklist
            }
          }
        `,
        variables: {},
        parseResponse: (response: any): GetListResult => {
          return {
            data: response.data.camunda_guest_ProcessDefinition,
            total: response.data.camunda_guest_ProcessDefinition.length,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default CamundaGuestProcessDefinitionQuery;
