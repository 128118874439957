import { parse } from 'query-string';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { useLocation } from 'react-router-dom';

const ProcessInstanceCreate: React.FC<{}> = () => {
  const location = useLocation();
  const { processDefinitionId } = parse(location.search);
  return (
    <Create>
      <SimpleForm defaultValues={{ key: processDefinitionId }}>
        <TextInput source="key" label="Process definition" />
      </SimpleForm>
    </Create>
  );
};

export default ProcessInstanceCreate;
