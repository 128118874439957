import gql from 'graphql-tag';
import { GetManyParams, GetManyReferenceParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

const HistoricProcessInstanceQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_MANY:
      const getManyParams = params as GetManyParams;
      return {
        query: gql`
          query MyQuery($ids: [String]) {
            camunda_HistoricProcessInstances(ids: $ids) {
              id
              businessKey
              startTime
              endTime
              processDefinition {
                name
                diagram
              }
              activityInstances {
                activityId
                startTime
                endTime
                assignee {
                  name
                }
                calledProcessInstanceId
              }
              incidents {
                activityId
                incidentType
                incidentMessage
                incidentTimestamp
              }
            }
          }
        `,
        variables: { ids: getManyParams.ids },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_HistoricProcessInstances,
            total: response.data.camunda_HistoricProcessInstances.length,
          };
        },
      };
    case RaFetchType.GET_MANY_REFERENCE:
      const getManyReferenceParams = params as GetManyReferenceParams;
      return {
        query: gql`
          query MyQuery($businessKey: String) {
            camunda_HistoricProcessInstances(businessKey: $businessKey) {
              id
              businessKey
              startTime
              endTime
              processDefinition {
                name
                diagram
              }
              activityInstances {
                activityId
                startTime
                endTime
                assignee {
                  name
                }
                calledProcessInstanceId
              }
              incidents {
                activityId
                incidentType
                incidentMessage
                incidentTimestamp
              }
            }
          }
        `,
        variables: { businessKey: getManyReferenceParams.id },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_HistoricProcessInstances,
            total: response.data.camunda_HistoricProcessInstances.length,
          };
        },
      };
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_HistoricProcessInstance(id: $id) {
              id
              businessKey
              startTime
              endTime
              processDefinition {
                name
                diagram
              }
              startTime
              activityInstances {
                activityId
                startTime
                endTime
                assignee {
                  name
                }
                calledProcessInstanceId
              }
              incidents {
                activityId
                incidentType
                incidentMessage
                incidentTimestamp
              }
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_HistoricProcessInstance,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default HistoricProcessInstanceQuery;
