import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DeleteButton,
  EditContextProvider,
  EditControllerProps,
  ErrorProps,
  Loading,
  RedirectionSideEffect,
  SaveButton,
  SimpleForm,
  Title,
  Toolbar,
  ToolbarProps,
  useDataProvider,
  useEditController,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import CancelButton from '../../../Components/CancelButton';
import UserTaskEditContext, { UserTask } from '../../../DataProviders/Camunda/UserTaskEditContext';
import FormBuilder from '../../../FormBuilder/FormBuilder';
import { TracedError } from '../../../tracing';
import { normalizeAndMigrateForm } from '../../../util/helpers';

const CustomEdit: React.FC<EditControllerProps> = props => {
  const location = useLocation();
  const { processDefinitionId } = parse(location.search);
  const editControllerProps = useEditController(props);
  const {
    // properties from https://marmelab.com/react-admin/useEditController.html
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
  } = editControllerProps;

  const [update] = useUpdate();

  const dataProvider = useDataProvider();
  const [userTask, setUserTask] = useState<UserTask | undefined>();
  const [loading_, setLoading] = useState(!saving);
  const [error, setError] = useState<ErrorProps['error'] | undefined>();

  useEffect(() => {
    if (record && !saving) {
      dataProvider
        .getOne(
          'camunda_ProcessDefinition_UserTask',
          processDefinitionId
            ? {
                id: processDefinitionId,
                meta: {
                  filter: {
                    user_task_id: record.user_task_id,
                  },
                },
              }
            : {
                id: undefined,
                meta: {
                  key: record.process_definition_key,
                  version: record.process_definition_version,
                  filter: {
                    user_task_id: record.user_task_id,
                  },
                },
              }
        )
        .then(({ data }: any) => {
          setUserTask(data);
          setLoading(false);
        })
        .catch((error: any) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [dataProvider, processDefinitionId, record, saving]);

  const customSave: typeof save = useCallback(
    async (data, callbacks) => {
      // Prevent array input artifacts from being persisted
      delete data.schemaIds;
      for (const fieldset of data.schema || {}) {
        delete fieldset.fieldsIds;
      }
      // if we use the `save` function returned from `useEditController` here,
      // it returns without doing anything (?????).
      // thus we use the `update` function directly.
      // this way we don't have access to all features of the edit controller
      // (namely mutation middlewares), but since we don't use those here, this is fine
      return await update(resource, { id: data.id, data }, callbacks);
    },
    [resource, update]
  );

  if (isLoading || !record || loading_ || !userTask) {
    return <Loading />;
  }

  if (error) {
    return <TracedError error={error} />;
  }

  const redirect_ = processDefinitionId
    ? `/ProcessDefinition/${processDefinitionId}/show`
    : userTask
    ? `/ProcessDefinition/${userTask.processDefinition.id}/show`
    : 'list';

  // XXX: Remove once all forms have been migrated
  normalizeAndMigrateForm(record);

  return (
    <UserTaskEditContext.Provider
      value={{
        userTask,
        redirect: redirect_,
        resource,
      }}
    >
      <EditContextProvider value={{ ...editControllerProps, save: customSave }}>
        <Title title={userTask.name || defaultTitle} />
        {props.children}
      </EditContextProvider>
    </UserTaskEditContext.Provider>
  );
};

const useStyles = makeStyles({
  mr: {
    marginRight: '1em',
  },
  delete: {
    marginLeft: 'auto',
  },
});

const CustomToolbar: React.FC<ToolbarProps & { redirect: RedirectionSideEffect }> = props => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Toolbar {...props}>
      <SaveButton
        label="ra.action.save"
        mutationOptions={{
          onSuccess: () => {
            notify('ra.notification.updated', {
              type: 'info',
              messageArgs: { smart_count: 1 },
              undoable: true,
            });
          },
        }}
        type="button"
        color="primary"
        className={classes.mr}
      />
      <SaveButton
        label="vasara.action.saveAndClose"
        mutationOptions={{
          onSuccess: () => redirect(props.redirect),
        }}
        type="button"
        className={classes.mr}
      />
      <CancelButton className={classes.mr} redirect={props.redirect} />
      <DeleteButton
        // ask for confirmation before deleting, then delete immediately.
        // default is "undoable", which immediately navigates away,
        // then shows a notification with an "undo" button
        // and deletes when that goes away,
        // however undoable mode causes errors and desyncing of the UI
        // that I can't be bothered to figure out how to fix.
        mutationMode="pessimistic"
        label="ra.action.delete"
        redirect={props.redirect}
        type="button"
        className={classes.delete}
      />
    </Toolbar>
  );
};

const UserTaskFormEdit: React.FC<{}> = () => {
  const location = useLocation();
  const { processDefinitionId } = parse(location.search);
  const redirect = processDefinitionId ? `/ProcessDefinition/${processDefinitionId}/show` : 'list';
  return (
    <CustomEdit redirect={redirect} undoable={false}>
      <SimpleForm toolbar={<CustomToolbar redirect={redirect} />} reValidateMode="onBlur">
        <FormBuilder title={''} backButtonProps={{ redirect }} />
      </SimpleForm>
    </CustomEdit>
  );
};

export default UserTaskFormEdit;
