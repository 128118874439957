import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import { BPMNViewer as BPMN } from '../DataProviders/Camunda/helpers';

interface Props {
  diagramXML: string;
  history?: [string, string, string][];
  active: string;
}

const useStyles = makeStyles({
  activeTask: {
    '&:not(.djs-connection) .djs-visual > :nth-child(1)': {
      fill: 'gold !important',
    },
  },
  currentToken: {
    color: 'black',
    width: '20px',
    height: '20px',
    border: '2px solid black',
    background: '#e8ebf0',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50%',
  },
  historyToken: {
    color: 'white',
    width: '20px',
    height: '20px',
    border: '2px solid black',
    background: '#44aa44',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '100%',
  },
  errorToken: {
    color: 'white',
    width: '20px',
    height: '20px',
    border: '2px solid black',
    background: '#f1563f',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '100%',
  },
  bpmn: {
    width: '100%',
    maxHeight: '90vh',
  },
  zoomIn: {
    padding: 0,
    border: 0,
    background: 'none',
    right: '24px',
    top: 0,
    height: '24px',
    width: '24px',
    position: 'absolute',
    cursor: 'pointer',
  },
  zoomOut: {
    padding: 0,
    border: 0,
    background: 'none',
    top: 0,
    right: 0,
    height: '24px',
    width: '24px',
    position: 'absolute',
    cursor: 'pointer',
  },
});

const ReactBpmn: React.FC<Props> = props => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [viewer, setViewer] = useState(null);
  const [height, setHeight] = useState(400);
  const translate = useTranslate();

  useEffect(() => {
    (async () => {
      const viewer: any = await BPMN(props.diagramXML);
      if (ref.current !== null) {
        ref.current.innerHTML = '';
        viewer.attachTo(ref.current);
        const canvas = viewer.get('canvas');
        canvas.zoom('fit-viewport');
        const overlays = viewer.get('overlays');
        // const tooltips = viewer.get('tooltips');
        const shift: Map<string, number> = new Map();
        for (const pair of props.history || []) {
          try {
            const overlayHtml = document.createElement('a');
            overlayHtml.className = pair[1] === '!' ? classes.errorToken : classes.historyToken;
            overlayHtml.title = pair[2];
            overlayHtml.innerText = pair[1];
            overlays.add(pair[0], {
              position: {
                bottom: 10,
                left: -10 + Math.min(shift.get(pair[0]) || 0, 50),
              },
              html: overlayHtml,
            });
            shift.set(pair[0], (shift.get(pair[0]) || 0) + 10);
          } catch (e) {
            // Nothing can be done
          }
        }
        if (props.active) {
          try {
            canvas.addMarker(props.active, classes.activeTask);
            const overlayHtml = document.createElement('div');
            overlayHtml.className = classes.currentToken;
            overlayHtml.innerText = '▐ ▌';
            overlays.add(props.active, {
              position: {
                bottom: 10,
                left: -10 + Math.min(shift.get(props.active) || 0, 50),
              },
              html: overlayHtml,
            });
          } catch (e) {
            // Nothing can be done
          }
        }
        const minY = canvas.getRootElement().children.reduce((a: number, b: any) => {
          return b.y === undefined ? a : a === -1 ? b.y : Math.min(a, b.y);
        }, -1);
        const maxY = canvas.getRootElement().children.reduce((a: number, b: any) => {
          return b.y === undefined ? a : a === -1 ? b.y : Math.max(a, b.y);
        }, -1);
        if (minY !== null && maxY !== null && maxY > minY) {
          setHeight(maxY - minY + 100);
        }
        setViewer(viewer);
      }
    })();
  }, [
    props.diagramXML,
    ref,
    props.active,
    props.history,
    classes.activeTask,
    classes.historyToken,
    classes.currentToken,
    classes.errorToken,
  ]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div className={classes.bpmn} style={{ height: height }} ref={ref} />
      <button
        className={classes.zoomIn}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          (viewer as any)?.get('zoomScroll').stepZoom(1);
        }}
        aria-label={translate('vasara.action.zoomIn')}
      >
        <ZoomIn />
      </button>
      <button
        className={classes.zoomOut}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          (viewer as any)?.get('zoomScroll').stepZoom(-1);
        }}
        aria-label={translate('vasara.action.zoomOut')}
      >
        <ZoomOut />
      </button>
    </div>
  );
};

const BpmnField: React.FC<{ source: string }> = ({ source }) => {
  const record = useRecordContext();
  const value = get(record, source);
  let active = record?.taskDefinitionKey ?? null;
  const history: [string, string, string][] = [];
  for (const activity of record?.processInstance?.historicActivityInstances ?? []) {
    if (activity?.endTime !== null) {
      history.push([activity.activityId, '✓', activity?.assignee?.name ?? '']);
    }
  }
  for (const activity of record?.activityInstances ?? []) {
    if (activity?.endTime !== null) {
      history.push([activity.activityId, '✓', activity?.assignee?.name ?? '']);
    } else if (!active) {
      active = activity.activityId;
    }
  }
  for (const incident of record?.incidents ?? []) {
    history.push([incident.activityId, '!', incident?.incidentMessage ?? '']);
  }
  return <ReactBpmn diagramXML={value} active={active} history={history} />;
};

export default BpmnField;
