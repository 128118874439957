import gql from 'graphql-tag';
import { GetListParams, GetManyParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

interface GetOneGroupParams extends GetOneParams {
  taskId: string;
}

const GroupQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_MANY:
      const getManyParams = params as GetManyParams;
      return {
        query: gql`
          query MyQuery($ids: [String]) {
            camunda_Groups(ids: $ids) {
              id
              name
            }
          }
        `,
        variables: {
          ids: getManyParams.ids,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Groups,
            total: response.data.camunda_Groups.length,
          };
        },
      };
    case RaFetchType.GET_LIST:
      const getListParams = params as GetListParams;
      const { filter } = getListParams;
      return {
        query: gql`
          query MyQuery($nameLike: String) {
            camunda_Groups(nameLike: $nameLike) {
              id
              name
            }
          }
        `,
        variables: {
          nameLike: `%${filter?.q ?? ''}%`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Groups.slice(
              (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
              getListParams.pagination.page * getListParams.pagination.perPage
            ),
            total: response.data.camunda_Groups.length,
          };
        },
      };
    case RaFetchType.GET_ONE:
      const getOneParams = (params as unknown) as GetOneGroupParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Group(id: $id) {
              id
              name
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Group,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default GroupQuery;
