import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { EdgeProps, Position, Rect, getEdgeCenter, getMarkerEnd, useStoreState } from 'react-flow-renderer';

import { getBezierPath } from './BezierEdge';
import { getEdgeParams } from './utils';

const FloatingEdge: FC<EdgeProps> = ({
  id,
  source,
  target,
  arrowHeadType,
  markerEndId,
  style,
  label,
  labelStyle = {},
  labelShowBg = true,
  labelBgStyle = {},
  labelBgPadding = [2, 4],
  labelBgBorderRadius = 2,
}) => {
  const nodes = useStoreState(state => state.nodes);
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const edgeRef = useRef<SVGTextElement>(null);
  const [edgeTextBbox, setEdgeTextBbox] = useState<Rect>({ x: 0, y: 0, width: 0, height: 0 });

  const sourceNode = useMemo(() => nodes.find(n => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find(n => n.id === target), [target, nodes]);

  useEffect(() => {
    if (edgeRef.current) {
      const textBbox = edgeRef.current.getBBox();

      setEdgeTextBbox({
        x: textBbox.x,
        y: textBbox.y,
        width: textBbox.width,
        height: textBbox.height,
      });
    }
  }, [label]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  // Change target position to fix text label alignment in case of Bezier edges.
  let fixedTargetPos = targetPos;
  switch (sourcePos) {
    case Position.Bottom: {
      if (targetPos === Position.Right) {
        fixedTargetPos = Position.Top;
      } else if (targetPos === Position.Left) {
        fixedTargetPos = Position.Top;
      }
      break;
    }
    case Position.Top: {
      if (targetPos === Position.Right) {
        fixedTargetPos = Position.Bottom;
      } else if (targetPos === Position.Left) {
        fixedTargetPos = Position.Bottom;
      }
      break;
    }
    case Position.Right: {
      if (targetPos === Position.Top) {
        fixedTargetPos = Position.Left;
      } else if (targetPos === Position.Bottom) {
        fixedTargetPos = Position.Left;
      }
      break;
    }
    case Position.Left: {
      if (targetPos === Position.Top) {
        fixedTargetPos = Position.Right;
      } else if (targetPos === Position.Bottom) {
        fixedTargetPos = Position.Right;
      }
      break;
    }
  }

  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: fixedTargetPos,
    targetX: tx,
    targetY: ty,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX: source === target ? sx - 430 : sx,
    sourceY: source === target ? sy - 15 : sy,
    targetX: tx,
    targetY: ty,
    sourcePosition: sourcePos,
    targetPosition: fixedTargetPos,
  });

  return !d.includes('NaN') ? (
    <g className="react-flow__connection">
      <path id={id} className="react-flow__edge-path" d={d} markerEnd={markerEnd} style={style as CSSProperties} />
      <g
        className="react-flow__edge-textwrapper"
        transform={`translate(${edgeCenterX - edgeTextBbox.width / 2} ${edgeCenterY - edgeTextBbox.height / 2})`}
      >
        {labelShowBg && (
          <rect
            width={edgeTextBbox.width + 2 * labelBgPadding![0]}
            x={-labelBgPadding![0]}
            y={-labelBgPadding![1]}
            height={edgeTextBbox.height + 2 * labelBgPadding![1]}
            className="react-flow__edge-textbg"
            style={labelBgStyle}
            rx={labelBgBorderRadius}
            ry={labelBgBorderRadius}
          />
        )}
        <text
          className="react-flow__edge-text"
          y={edgeTextBbox.height / 2}
          dy="0.3em"
          ref={edgeRef}
          style={labelStyle as CSSProperties}
        >
          {label}
        </text>
      </g>
    </g>
  ) : (
    <g className="react-flow__connection"></g>
  );
};

export default FloatingEdge;
