import { withStyles } from '@mui/styles';
import React from 'react';
import { CreateButton, useRecordContext, useResourceContext } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
  },
};

const AddEntityCommentButton: React.FC<{ classes: Record<string, any> }> = ({ classes }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  return (
    <CreateButton
      className={classes.button}
      to={`/${resource}_comment/create?entity_id=${record.id}&business_key=${record.business_key}`}
    />
  );
};

export default withStyles(styles)(AddEntityCommentButton);
