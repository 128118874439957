import get from 'lodash/get';
import React, { ChangeEvent, Suspense, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
  useTranslate,
} from 'react-admin';

import { CommonFieldProps, EnabledFieldTypesChoices, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';

const MapCoordinateField: React.FC<CommonFieldProps> = props => {
  const [locale] = useLocaleState();

  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<any>();

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired([required()]);
    } else {
      setValidateRequired(undefined);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <TextInput
        id={`${props.inputName}-label`}
        label="vasara.form.label"
        source={`${props.inputName}.label.${locale}`}
        validate={validateRequired}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <TextInput
        id={`${props.inputName}-helperText`}
        label="vasara.form.help"
        onChange={(e: ChangeEvent) => e.stopPropagation()}
        source={`${props.inputName}.helperText.${locale}`}
        defaultValue=""
        fullWidth={true}
        helperText={false}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const sources = get(formData, `${props.inputName}.sources`) || [];
          const label = get(formData, `${props.inputName}.label.${locale}`) || '';
          const readonly = get(formData, `${props.inputName}.readonly`);
          for (const source of sources) {
            if ((source || '').endsWith('.{}')) {
              return (
                <>
                  <AutocompleteArrayInput
                    id={`${props.inputName}-sources`}
                    label="vasara.form.sources"
                    source={`${props.inputName}.sources`}
                    choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
                    validate={validateRequired}
                    fullWidth={true}
                    helperText={false}
                  />
                  <TextInput
                    id={`${props.inputName}-key`}
                    label="vasara.form.key"
                    helperText="vasara.form.helperText.key"
                    source={`${props.inputName}.key`}
                    validate={validateRequired}
                    defaultValue={label}
                    fullWidth={true}
                  />
                </>
              );
            }
          }
          return (
            <AutocompleteArrayInput
              id={`${props.inputName}-sources`}
              label="vasara.form.sources"
              source={`${props.inputName}.sources`}
              choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
              validate={validateRequired}
              fullWidth={true}
              helperText={false}
            />
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        id={`${props.inputName}-readonly`}
        label="vasara.form.readonly"
        source={`${props.inputName}.readonly`}
        defaultValue={false}
        sx={{ float: 'left' }}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const readonly = get(formData, `${props.inputName}.readonly`);
          return readonly ? null : (
            <>
              <BooleanInput
                id={`${props.inputName}-PII`}
                label="vasara.form.PII"
                source={`${props.inputName}.PII`}
                defaultValue={false}
                sx={{ float: 'left' }}
              />

              <BooleanInput
                id={`${props.inputName}-required`}
                label="ra.validation.required"
                source={`${props.inputName}.required`}
                defaultValue={false}
                sx={{ float: 'left' }}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <AutocompleteInput
        id={`${props.inputName}-dependency`}
        label="vasara.form.dependency"
        source={`${props.inputName}.dependency`}
        choices={combinedChoices}
        fullWidth={true}
        helperText={false}
        sx={{ clear: 'left' }}
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dependency = get(formData, `${props.inputName}.dependency`);
          return dependency ? (
            <>
              <TextInput
                id={`${props.inputName}-condition`}
                label="vasara.form.dependencyExpression"
                source={`${props.inputName}.condition`}
                defaultValue=""
                fullWidth={true}
                helperText={false}
              />
              <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.variables">
                <SimpleFormIterator className="VasaraVariablesIterator">
                  <TextInput
                    source={`id`}
                    label="vasara.form.variable"
                    helperText={false}
                    validate={validateRequired}
                  />
                  <AutocompleteInput
                    label="vasara.form.source"
                    source={`source`}
                    choices={combinedChoices}
                    validate={validateRequired}
                    helperText={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          ) : null;
        }}
      </FormDataConsumer>

      <SelectInput
        id={`${props.inputName}-type`}
        label="vasara.form.type"
        helperText="vasara.form.helperText.type"
        source={`${props.inputName}.type`}
        choices={EnabledFieldTypesChoices}
        validate={validateRequired}
        fullWidth={true}
      />
    </FieldsetField>
  );
};

// input component separated into a different file so that it can be loaded lazily

const LazyMapCoordinateInput = React.lazy(() => import('./MapCoordinateInput'));
const MapCoordinateInputImpl: React.FC<FieldComponentProps> = props => {
  const translate = useTranslate();
  // we can't import the MAP_SIZE constant from MapCoordinateInput
  // to use for the fallback's height
  // because that would break lazy loading.
  // make sure the value here matches
  return (
    <Suspense fallback={<div style={{ height: 600 }}>{translate('vasara.message.mapLoading')}</div>}>
      <LazyMapCoordinateInput {...props} />
    </Suspense>
  );
};

export const MapCoordinateInput = React.memo(MapCoordinateInputImpl);
export default React.memo(MapCoordinateField);
