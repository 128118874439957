import { Box, Container, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Loading,
  RadioButtonGroupInput,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Title,
  Toolbar,
  choices,
  email,
  required,
  useCreate,
  useGetList,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router';

interface Choice {
  id: string;
  name: string;
  description: string;
}

const Content = (props: any) => props.children;

export const GuestForm = () => {
  const { data, isLoading } = useGetList('camunda_guest_ProcessDefinition');
  const params: any = useParams();
  const [doCreate] = useCreate();
  const [processChoices, setChoices] = useState<Choice[]>([]);
  const [record, setRecord] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [cooldown, setCooldown] = useState<number>(0);
  const notify = useNotify();
  const translate = useTranslate();

  const GuestFormToolbar: React.FC<{}> = () => (
    <Toolbar>
      <SaveButton saving={saving || !!cooldown} label={translate('vasara.guest.save', { _: 'Request login link' })} />
    </Toolbar>
  );

  useEffect(() => {
    const needle = decodeURIComponent(params.needle || '')
      .replaceAll('+', ' ')
      .toLowerCase();
    const filtered = (data || [])
      .filter((item: any) =>
        needle
          ? !!((item.key || '').toLowerCase().match(needle) || (item.name || '').toLowerCase().match(needle))
          : true
      )
      .map((item: any) => {
        return {
          id: item.key,
          name: item.name,
          description: item.description,
        };
      });
    if (filtered.length === 1) {
      setRecord({ processDefinitionKey: filtered[0].id });
    }
    setChoices(filtered);
  }, [data, params.needle]);

  useEffect(() => {
    if (cooldown > 0) {
      setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000);
    } else {
      setSaving(false);
      setRecord((r: any) => {
        return { ...r, processDefinitionKey: null };
      });
    }
  }, [cooldown]);

  const handleSave = useCallback(
    async (values: any) => {
      setSaving(true);
      const create = () =>
        new Promise<any>((resolve, reject) => {
          doCreate(
            'insert_camunda_guest_ProcessInstance',
            { data: values },
            {
              onSuccess: (data: any) => {
                resolve(data);
              },
              onError: (error: any) => {
                reject(error);
              },
            }
          );
        });
      try {
        await create();
        setCooldown(2);
        setRecord(values);
        notify('vasara.guest.success', { type: 'success', _: 'Request queued. Please, check your email.' });
      } catch (e) {
        notify('vasara.guest.error', {
          type: 'warning',
          _: 'Unable to create request. Please, check your inputs or try again later.',
        });
      } finally {
        setSaving(false);
      }
    },
    [doCreate, notify]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <Container maxWidth="sm">
      <Title title={translate('vasara.guest.title', { _: 'Sign in as a guest' })} />
      <SaveContextProvider value={saveContext}>
        <SimpleForm onSubmit={handleSave} record={record} toolbar={<GuestFormToolbar />}>
          <Content>
            <h1>{translate('vasara.guest.welcome', { _: 'Welcome' })}</h1>
            <Box mb={2}>
              <Typography>
                {(processChoices.length === 1 && processChoices[0].description) ||
                  translate('vasara.guest.instructions', {
                    _:
                      'To receive your personal login link, please, enter the required information and submit the form below.',
                  })}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>
                <strong>{translate('vasara.guest.tos', { _: 'Terms of service' })}</strong>
                <br />
                {translate('vasara.guest.privacyNoticeIntro', {
                  _: 'All submitted information are subject to our',
                })}{' '}
                <a href={translate('vasara.ui.privacyNotice')} target="_blank" rel="noreferrer">
                  {translate('vasara.ui.privacyNoticeLabel', { _: 'privacy notice' }).toLowerCase()}
                </a>
                .
              </Typography>
            </Box>
          </Content>
          {processChoices.length < 6 ? (
            <RadioButtonGroupInput
              label={translate('vasara.guest.processDefinitionKey', { _: 'Reason for login' })}
              source="processDefinitionKey"
              helperText={false}
              choices={processChoices}
              row={false}
              validate={required()}
            />
          ) : (
            <AutocompleteInput
              label={translate('vasara.guest.processDefinitionKey', { _: 'Reason for login' })}
              helperText={false}
              source="processDefinitionKey"
              choices={processChoices}
              validate={required()}
              fullWidth={true}
            />
          )}
          <TextInput
            source="firstName"
            label={translate('vasara.guest.firstName', { _: 'First name' })}
            helperText={false}
            validate={required()}
            fullWidth={true}
          />
          <TextInput
            source="lastName"
            label={translate('vasara.guest.lastName', { _: 'Last name' })}
            helperText={false}
            validate={required()}
            fullWidth={true}
          />
          <TextInput
            source="email"
            label={translate('vasara.guest.email', { _: 'Email address' })}
            helperText={translate('vasara.guest.emailHelper', {
              _: 'Your personal login link will be submitted to this email address.',
            })}
            validate={[required(), email()]}
            fullWidth={true}
          />
          <BooleanInput
            source="consent"
            label={translate('vasara.guest.consent', { _: 'I agree to the terms of service.' })}
            validate={[required(), choices([true], 'ra.validation.required')]}
            fullWidth={true}
          />
        </SimpleForm>
      </SaveContextProvider>
    </Container>
  );
};
