import gql from 'graphql-tag';
import { CreateParams, CreateResult } from 'ra-core';
import { BuildQueryResult } from 'ra-data-graphql';
import { v4 as uuid } from 'uuid';

import { RaFetchType } from '../types';
import { ActionsQueryBuilder } from './types';

const VaultTransitEncryptQuery: ActionsQueryBuilder = (
  introspectionResults,
  raFetchType,
  resource,
  params
): BuildQueryResult => {
  switch (raFetchType) {
    case RaFetchType.CREATE:
      const createParams = params as CreateParams;
      return {
        query: gql`
          query($batch: [vault_transit_encrypt_request!]!) {
            vault_transit_encrypt(batch: $batch) {
              ciphertext
            }
          }
        `,
        variables: {
          batch: createParams.data.batch,
        },
        parseResponse: (response: any): CreateResult => {
          return {
            data: {
              id: uuid(),
              batch: response.data.vault_transit_encrypt,
            },
            // @ts-ignore: react-admin types are incomplete, validUntil is used for caching
            validUntil: new Date(new Date().getTime() + 60000), // a minute
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default VaultTransitEncryptQuery;
