import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import React, { FC, memo, useState } from 'react';
import { Button, TextFieldProps, sanitizeFieldRestProps, useCreate, useNotify, useRecordContext } from 'react-admin';

import { vault_transit_decrypt_request_batch } from '../DataProviders/Actions/types';

const useStyles = makeStyles(theme => ({
  confidential: {
    marginTop: '1rem',
  },
}));

const VaultTextField: FC<TextFieldProps> = memo<TextFieldProps>(({ className, source, emptyText, ...rest }) => {
  const record = useRecordContext();
  const [value, setValue] = useState(get(record, source as string));
  const notify = useNotify();
  const classes = useStyles();

  const [doCreate] = useCreate();
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            reject(error);
          },
        }
      );
    });

  if (!value || !value.startsWith || !value.startsWith('vault:')) {
    return (
      <Typography component="span" variant="body2" {...sanitizeFieldRestProps(rest)} className={classes.confidential}>
        {value != null && typeof value !== 'string' ? JSON.stringify(value) : value || emptyText}
      </Typography>
    );
  }
  return (
    <Button
      label="vasara.action.showConfidential"
      variant="outlined"
      onClick={async e => {
        const request: vault_transit_decrypt_request_batch = {
          batch: [{ ciphertext: value }],
        };
        try {
          const result = await create('vault_transit_decrypt', request);
          if (result.batch[0].plaintext) {
            setValue(result.batch[0].plaintext);
          } else {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
          }
        } catch (e) {}
        e.preventDefault();
      }}
    >
      <LockIcon />
    </Button>
  );
});

export default VaultTextField;
