import gql from 'graphql-tag';
import { GetListParams, GetManyParams, GetOneParams } from 'ra-core';

import { RaFetchType } from '../types';
import { CamundaQueryBuilder } from './types';

interface GetOneUserParams extends GetOneParams {
  taskId: string;
}

const UserQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_MANY:
      const getManyParams = params as GetManyParams;
      return {
        query: gql`
          query MyQuery($ids: [String]) {
            camunda_Users(ids: $ids) {
              id
              name
            }
          }
        `,
        variables: {
          ids: getManyParams.ids,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Users,
            total: response.data.camunda_Users.length,
          };
        },
      };
    case RaFetchType.GET_LIST:
      const getListParams = params as GetListParams;
      const { filter } = getListParams;
      const parts = ((filter?.q ?? '') as string)
        .split(' ')
        .map(part => part.replace(/^\s*|\s*$/g, ''))
        .map(part => part.replace(/,*$/g, ''))
        .filter(part => part.length);
      return {
        query: gql`
          query MyQuery($ids: [String], $firstNameLike: String, $lastNameLike: String) {
            camunda_Users(ids: $ids, firstNameLike: $firstNameLike, lastNameLike: $lastNameLike) {
              id
              name
              firstName
              lastName
              email
            }
          }
        `,
        variables:
          parts.length === 0
            ? {
                ids: [], // TODO: better way to not query at all
              }
            : {
                firstNameLike: parts.length > 1 ? `%${parts[1]}%` : '',
                lastNameLike: parts.length > 0 ? `%${parts[0]}%` : '',
              },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_Users.slice(
              (getListParams.pagination.page - 1) * getListParams.pagination.perPage,
              getListParams.pagination.page * getListParams.pagination.perPage
            ),
            total: response.data.camunda_Users.length,
          };
        },
      };
    case RaFetchType.GET_ONE:
      const getOneParams = (params as unknown) as GetOneUserParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_User(id: $id) {
              id
              name
              firstName
              lastName
              email
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) => {
          return {
            data: response.data.camunda_User,
          };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default UserQuery;
